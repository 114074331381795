import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "fw-semibold mb-15" }
const _hoisted_3 = { class: "list ps-0 mb-0 mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        lg: "12",
        md: "12"
      }, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                class: "pingo-card"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (n) => {
                    return _createElementVNode("div", { key: n }, [
                      _createVNode(_component_v_skeleton_loader, { type: "list-item-avatar" }),
                      _createVNode(_component_v_skeleton_loader, { type: "text" })
                    ])
                  }), 64))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 1,
                class: "pingo-card"
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
                    _createElementVNode("h5", { class: "mb-0" }, "Informações de Entrega")
                  ], -1)),
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.user.name), 1),
                    _createElementVNode("ul", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.defaultAddresses, (address, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "gray-color position-relative",
                          key: index
                        }, [
                          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fw-medium d-inline-block" }, "Endereço :", -1)),
                          _createTextVNode(" " + _toDisplayString(address.street) + ", " + _toDisplayString(address.number) + ", " + _toDisplayString(address.neighborhood) + ", " + _toDisplayString(address.city) + ", " + _toDisplayString(address.state) + ", " + _toDisplayString(address.zipCode) + ", " + _toDisplayString(address.country), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}