<template>
  <v-card v-if="isLoading" class="pingo-card">
    <div v-for="n in 6" :key="n">
      <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </div>
  </v-card>

  <v-card v-if="!isLoading" class="tacking-box pingo-card">
    <div
      class="v-card-header d-flex flex-column align-items-start border-bottom"
    >
      <h5 class="mb-0">Rastrear Pedido</h5>

      <span class="gray-color">
        Última atualização em
        {{
          new Intl.DateTimeFormat("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(order.updatedAt))
        }}
      </span>
    </div>

    <div class="top align-items-center">
      <div class="d-flex ga-1">
        <span class="fw-semibold d-block mb-8">ID do Pedido:</span>
        <span class="d-block gray-color fw-semibold">
          #{{ order?.orderId }}
        </span>
      </div>

      <div class="d-flex ga-1" v-if="order?.user">
        <span class="fw-semibold d-block mb-8">Colaborador:</span>
        <span class="d-block gray-color fw-semibold">
          {{ order.user.name }}
        </span>
      </div>
    </div>

    <ul class="track-list ps-0 mb-0">
      <li class="position-relative">
        <h5 class="mb-10 fw-semibold">
          {{ formatStatus("pending") }}
          <span v-if="order.status === 'pending'">(Atual)</span>
        </h5>
        <span class="d-block gray-color mb-8">
          {{ getStatusDate("pending") }}
        </span>
        <p>Status atualizado para {{ formatStatus("pending") }}.</p>
      </li>

      <li
        v-for="status in remainingStatuses"
        :key="status"
        class="position-relative"
      >
        <h5 class="mb-10 fw-semibold">
          {{ formatStatus(status) }}
          <span v-if="order.status === status">(Atual)</span>
        </h5>
        <span class="d-block gray-color mb-8">
          {{ getStatusDate(status) }}
        </span>

        <p>
          {{ orderHistoryStatus(status) }}
        </p>
      </li>
    </ul>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { OrderDetailsResponse } from "@/types/response/orderDetails";

export default defineComponent({
  name: "TrackOrder",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
      required: true,
    },
    order: {
      type: Object as PropType<OrderDetailsResponse>,
      required: true,
    },
  },
  setup(props) {
    const allStatuses: OrderDetailsResponse["status"][] = [
      "confirmed",
      "shipped",
      "delivered",
      "cancelled",
    ];

    const formatDate = (dateString: string): string => {
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return new Date(dateString).toLocaleDateString("pt-BR", options);
    };

    const formatStatus = (status: OrderDetailsResponse["status"]): string => {
      const statusMap: Record<OrderDetailsResponse["status"], string> = {
        pending: "Pedido Realizado",
        confirmed: "Confirmado",
        shipped: "Enviado",
        delivered: "Entregue",
        cancelled: "Cancelado",
      };
      return statusMap[status] || status;
    };

    const remainingStatuses = allStatuses.filter(
      (status) =>
        status !== "pending" &&
        (status !== "cancelled" ||
          props.order?.history.some((e) => e.status === "cancelled"))
    );

    const getStatusDate = (status: OrderDetailsResponse["status"]): string => {
      const event = props.order?.history.find((e) => e.status === status);
      return event ? formatDate(event.createdAt) : "Em breve";
    };

    const orderHistoryStatus = (
      status: OrderDetailsResponse["status"]
    ): string => {
      const event = props.order?.history.find((e) => e.status === status);
      if (event) {
        return `Status atualizado para ${formatStatus(status)}.`;
      } else {
        if (status === "cancelled") {
          return "Status Cancelado ainda não foi registrado no histórico.";
        }
        return `Status ${formatStatus(status)} ainda não foi atualizado.`;
      }
    };

    return {
      remainingStatuses,
      formatDate,
      formatStatus,
      getStatusDate,
      orderHistoryStatus,
    };
  },
});
</script>

<style lang="scss" scoped>
.tacking-box {
  .top {
    div {
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .track-list {
    list-style-type: none;
    margin-top: 30px;
    li {
      border-bottom: 1px solid #f7faff;
      margin-bottom: 20px;
      padding: {
        bottom: 20px;
        left: 22px;
      }
      h5 {
        font-size: 16px;
      }
      &::before {
        left: 0;
        top: 4px;
        z-index: 1;
        width: 10px;
        content: "";
        height: 10px;
        border-radius: 50%;
        position: absolute;
        background: linear-gradient(149.1deg, #99b8f3 14.61%, #177fcb 130.18%);
        box-shadow: 0px 2.98686px 13.4409px rgba(126, 172, 235, 0.25);
      }
      &::after {
        background: #f7faff;
        border-radius: 5px;
        position: absolute;
        height: 77%;
        content: "";
        width: 3px;
        left: 3px;
        top: 4px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
        &::after {
          height: 90%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tacking-box {
    .track-list {
      li {
        margin-bottom: 16px;
        padding: {
          bottom: 16px;
          left: 20px;
        }
        h5 {
          font-size: 15px;
        }
        span {
          font-size: 14px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
