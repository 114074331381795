import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = { class: "pingo-table-content" }
const _hoisted_3 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_4 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_5 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_6 = { class: "d-flex align-items-center ga-6" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Colaboradores",
      mainTitle: "Colaboradores"
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("h5", { class: "mb-2" }, "Lista de Colaboradores", -1)),
          _createVNode(_component_router_link, {
            class: "default-btn pingo small",
            to: { name: 'NewUserPage', query: { type: 'COLLABORATOR' } }
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
              _createTextVNode(" Criar novo colaborador ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.users,
              loading: _ctx.isLoadingUserCollaborators,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.isActive`]: _withCtx(({ value }) => [
                value
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Ativo "))
                  : _createCommentVNode("", true),
                (!value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Inativo "))
                  : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.userData.type == 'ADMIN' || _ctx.userData.type == 'BUSINESS')
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: {
                  name: 'EditUserPage',
                  params: { id: item.userId },
                  query: { type: 'COLLABORATOR' },
                }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            class: "text-capitalize",
                            color: "primary-darken-3",
                            variant: "tonal"
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode(" Editar ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_dialog, {
                    width: "auto",
                    scrollable: ""
                  }, {
                    activator: _withCtx(({ props: activatorProps }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        class: "text-capitalize",
                        color: "red-darken-3",
                        variant: "tonal"
                      }, activatorProps), {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Remover ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(({ isActive }) => [
                      _createVNode(_component_v_card, {
                        class: "py-0",
                        title: "Remover colaborador"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" Tem certeza que deseja remover este colaborador? ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_divider),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                onClick: ($event: any) => (isActive.value = false)
                              }, {
                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                  _createTextVNode(" Fechar ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                class: "text-capitalize",
                                color: "red-darken-3",
                                variant: "tonal",
                                onClick: ($event: any) => (_ctx.handleRemoveCollaborator(item.userId))
                              }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode(" Remover ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.userData.type == 'ADMIN')
                    ? (_openBlock(), _createBlock(_component_v_menu, { key: 1 }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[12] || (_cache[12] = [
                            _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                          ]), 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, {
                            class: "dotMenu monthMenu",
                            style: {"width":"250px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.isUpdatePasswordModalOpen,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isUpdatePasswordModalOpen) = $event)),
                                "max-width": "420",
                                scrollable: ""
                              }, {
                                activator: _withCtx(({ props: activatorProps }) => [
                                  _createElementVNode("button", _mergeProps(activatorProps, {
                                    type: "button",
                                    class: "bg-transparent w-100 border-none",
                                    onClick: ($event: any) => (_ctx.mapUserFormToupdateUserPasswordForm(item))
                                  }), " Alterar senha ", 16, _hoisted_7)
                                ]),
                                default: _withCtx(({ isActive }) => [
                                  _createVNode(_component_v_form, {
                                    onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleUpdateCollaborator()), ["prevent"]))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card, {
                                        class: "py-0",
                                        title: "Alterar senha"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_card_text, { class: "px-4" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, { cols: "12" }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_8, [
                                                        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Senha ", -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite a senha do usuário aqui',
                                                          type: 'password',
                                                          modelValue: _ctx.updateUserPasswordForm.password,
                                                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updateUserPasswordForm.password) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.password.$errors
                                        ? _ctx.v$.password.$errors[0]?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, { cols: "12" }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_9, [
                                                        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Confirme a sua senha ", -1)),
                                                        _createVNode(_component_TextInput, {
                                                          label: 'Digite a senha novamente',
                                                          type: 'password',
                                                          modelValue: 
                                      _ctx.updateUserPasswordForm.confirmationPassword
                                    ,
                                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((
                                      _ctx.updateUserPasswordForm.confirmationPassword
                                    ) = $event)),
                                                          "error-messages": 
                                      _ctx.v$.confirmationPassword.$errors
                                        ? _ctx.v$.confirmationPassword.$errors[0]
                                            ?.$message
                                        : undefined
                                    
                                                        }, null, 8, ["modelValue", "error-messages"])
                                                      ])
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_divider),
                                          _createVNode(_component_v_card_actions, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                class: "text-capitalize",
                                                onClick: ($event: any) => (isActive.value = false)
                                              }, {
                                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                  _createTextVNode(" Fechar ")
                                                ])),
                                                _: 2
                                              }, 1032, ["onClick"]),
                                              _createVNode(_component_v_spacer),
                                              _createVNode(_component_v_btn, {
                                                class: "text-capitalize",
                                                color: "green-darken-3",
                                                variant: "tonal",
                                                type: "submit"
                                              }, {
                                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                  _createTextVNode(" Confirmar ")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ]),
              "no-data": _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Não há nenhum colaborador ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[4] || (_cache[4] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}