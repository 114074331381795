<template>
  <v-text-field
    :label="label"
    :type="type"
    v-maskito="cpfMask"
    :hide-details="!required"
    @update:model-value="(value) => $emit('update:value', value)"
  >
    <i class="ri-user-3-line" v-if="icon"></i>
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { cpfMask } from "@/shared/mask/mask";

export default defineComponent({
  name: "CpfInput",
  props: {
    label: {
      type: String,
      default: "Escreva",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  emits: ["update:value"],
  setup() {
    return {
      cpfMask,
    };
  },
});
</script>
