<template>
  <div class="product-tabs">
    <v-tabs v-model="tab">
      <v-tab value="description">Description</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="description">
          <p>
            Lorem ipsum dolor sit amet, consectetur
            <strong class="fw-bold">adipiscing</strong> elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim adlino
            minim veniam, quis nostrud <u>exercitation</u> ullamco laboris nisi
            ut aliquip ex ea <i>commodo</i> consequat. Lorem ipsum dolor sit
            amet, consectetur
            <router-link to="/" class="fw-medium">adipiscing</router-link> elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim adlino minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor ut labore et dolore magna aliqua. Ut enim adlino
            minim veniam, quis nostrud
            <u>exercitation</u> ullamco laboris nisi incididunt ut aliquip ex ea
            <i>commodo</i> consequat. Lorem ipsum dolor sit amet, consectetur
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua
            <router-link to="/" class="fw-medium">adipiscing</router-link>.
          </p>
        </v-window-item>
      </v-window>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "TabsContent",
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-tabs {
  margin-top: 25px;
  .v-slide-group {
    margin-bottom: 20px;
    .v-btn {
      height: auto;
      display: block;
      min-width: auto;
      margin-right: 15px;
      padding: 15px 30px;
      border-radius: 5px;
      background-color: #f5f7fa;
      transition: var(--transition);
      text-transform: capitalize;
      line-height: 0;
      font: {
        size: 18px !important;
        weight: 600;
        family: var(--headingFontFamily);
      }
      .v-btn__content {
        &:last-child {
          margin-right: 0;
        }
        .v-tab__slider {
          display: none;
        }
      }

      &.v-slide-group-item--active {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
  }
  .v-card-text {
    padding: 0;
  }
  .reviews {
    margin-bottom: -25px;

    .review-card {
      .content {
        padding: 25px;
        position: relative;
        margin-bottom: 25px;
        background: #f7faff;

        .rating {
          margin-bottom: 10px;

          i {
            line-height: 1;
            color: #ffbc2b;
            margin-right: 4px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
        &::before {
          left: 28px;
          content: "";
          width: 25px;
          height: 25px;
          bottom: -10px;
          position: absolute;
          background: #f7faff;
          transform: rotate(45deg);
        }
      }
      h5 {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .product-tabs {
    .reviews {
      .review-card {
        .content {
          padding: 15px;
          margin-bottom: 25px;

          .rating {
            margin-bottom: 5px;
          }
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
