<template>
  <PageTitle pageTitle="Atualizar Endereço" mainTitle="Configurações" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações Básicas</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  CEP
                  <span>*</span>
                </label>

                <PostalCodeInput
                  :label="'Digite o seu CEP aqui'"
                  v-model="updateAddressForm.zipCode"
                  @update:value="(value: string) => updatePostalCode(value)"
                  :error-messages="
                    v$.zipCode.$errors ? v$.zipCode.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Rua
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Nome da rua'"
                  v-model="updateAddressForm.street"
                  :error-messages="
                    v$.street.$errors ? v$.street.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Bairro
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o bairro aqui'"
                  v-model="updateAddressForm.neighborhood"
                  :error-messages="
                    v$.neighborhood.$errors
                      ? v$.neighborhood.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Cidade
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite a cidade aqui'"
                  v-model="updateAddressForm.city"
                  :error-messages="
                    v$.city.$errors ? v$.city.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Estado
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o estado aqui'"
                  v-model="updateAddressForm.state"
                  :error-messages="
                    v$.state.$errors ? v$.state.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Número
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o número aqui'"
                  v-model="updateAddressForm.number"
                  :error-messages="
                    v$.number.$errors ? v$.number.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color"> Complemento </label>
                <TextInput
                  :label="'Digite o complemento aqui (opcional)'"
                  v-model="updateAddressForm.complement"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Endereço Principal?
                  <span>*</span>
                </label>
                <v-select
                  v-model="updateAddressForm.isDefault"
                  :items="[
                    { title: 'Sim', value: true },
                    { title: 'Não', value: false },
                  ]"
                  :error-messages="
                    v$.isDefault.$errors ? v$.isDefault.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>

          <div class="text-end">
            <button type="submit" class="default-btn">
              <v-progress-circular
                v-if="isLoadingAddressRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>

              <span v-if="isLoadingAddressRequest"> Atualizando Endereço... </span>
              <span v-if="!isLoadingAddressRequest"> Atualizar Endereço </span>
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAddress } from "@/composables/useAddress";
import { useViaCEP } from "@/composables/useViaCEP";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import PostalCodeInput from "@/components/Forms/AdvancedElements/Input/PostalCodeInput.vue";

export default defineComponent({
  name: "SettingsEditAddressPage",
  components: {
    PageTitle,
    TextInput,
    PostalCodeInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { address: viaCEPAddress, getAddress } = useViaCEP();

    const {
      address,
      updateAddressForm,
      updateAddressFormRules,
      getAddressById,
      updateAddress,
    } = useAddress();

    const isLoadingAddressData = ref(false);
    const isLoadingAddressRequest = ref(false);

    const fetchAddressData = async (id: number) => {
      try {
        isLoadingAddressData.value = true;

        getAddressById(id).then(() => {
          isLoadingAddressData.value = false;

          updateAddressForm.isDefault = address.value?.isDefault == 1;
          updateAddressForm.complement = address.value?.complement ?? "";
          updateAddressForm.number = address.value?.number ?? "";
          updateAddressForm.neighborhood = address.value?.neighborhood ?? "";
          updateAddressForm.country = address.value?.country ?? "";
          updateAddressForm.state = address.value?.state ?? "";
          updateAddressForm.city = address.value?.city ?? "";
          updateAddressForm.street = address.value?.street ?? "";
          updateAddressForm.zipCode = address.value?.zipCode ?? "";
        });
      } catch (error) {
        console.error("Erro ao buscar detalhes de endereço:", error);
      }
    };

    const updatePostalCode = (value: string) => {
      if (value.length == 9)
        getAddress(value).then(() => {
          if (viaCEPAddress.value) {
            updateAddressForm.street = viaCEPAddress.value.logradouro;
            updateAddressForm.complement = viaCEPAddress.value.complemento;
            updateAddressForm.neighborhood = viaCEPAddress.value.bairro;
            updateAddressForm.city = viaCEPAddress.value.localidade;
            updateAddressForm.state = viaCEPAddress.value.uf;
          }
        });
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      const addressId = parseInt(route.params.id.toString());

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { userId, ...updateAddressFormPayload } = updateAddressForm;

      const response = await updateAddress(addressId, updateAddressFormPayload);

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      if (response) router.push({ name: "SettingsAddressPage" });
    };

    onMounted(() => {
      const addressId = parseInt(route.params.id.toString());
      fetchAddressData(addressId);
    });

    const v$ = useVuelidate(updateAddressFormRules, updateAddressForm);

    return {
      v$,
      route,
      isLoadingAddressData,
      isLoadingAddressRequest,
      updateAddressForm,
      updatePostalCode,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
