<template>
  <v-select
    label="Selecione o status"
    :items="statusOptions"
    v-model="selectedStatus"
    @update:model-value="handleStatusChange"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  PropType,
  watch,
  onMounted,
} from "vue";
import { InvoiceStatus } from "@/types/enum/invoiceStatus.enum";

export default defineComponent({
  name: "SelectInvoiceStatus",
  props: {
    currentStatus: {
      type: String as PropType<InvoiceStatus>,
      required: true,
    },
    allStatuses: {
      type: Array as PropType<InvoiceStatus[]>,
      required: true,
    },
  },
  emits: ["update-status"],
  setup(props, { emit }) {
    const selectedStatus = ref<InvoiceStatus>(props.currentStatus);

    const statusOptions = computed(() =>
      props.allStatuses.map((status) => ({
        title: mapStatusTitle(status),
        value: status,
      }))
    );

    function mapStatusTitle(status: InvoiceStatus) {
      switch (status) {
        case InvoiceStatus.Pending:
          return "Pendente";
        case InvoiceStatus.Paid:
          return "Pago";
        case InvoiceStatus.Overdue:
          return "Vencido";
        case InvoiceStatus.PartiallyPaid:
          return "Parcialmente Pago";
        case InvoiceStatus.Cancelled:
          return "Cancelado";
        case InvoiceStatus.Refunded:
          return "Reembolsado";
        default:
          return status;
      }
    }

    function handleStatusChange(value: InvoiceStatus) {
      selectedStatus.value = value;
      emit("update-status", value);
    }

    watch(
      () => props.currentStatus,
      (newValue) => {
        selectedStatus.value = newValue;
      },
      { immediate: true }
    );

    onMounted(() => {
      handleStatusChange(props.currentStatus as InvoiceStatus);
    });

    return {
      selectedStatus,
      statusOptions,
      handleStatusChange,
    };
  },
});
</script>
