import BaseService from "@/shared/base.service";

import { useAlertStore } from "@/stores/alert.store";

import type { PaginationResponse } from "@/types/response/pagination";

import type { ProductResponse } from "@/types/response/product";
import type { CreateProductRequest } from "@/types/request/create/createProduct";
import type { UpdateProductRequest } from "@/types/request/update/updateProduct";

export class ProductService {
  static async GetAllProducts(
    currentPage: number,
    totalPerPage: number
  ): Promise<PaginationResponse<ProductResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<
        PaginationResponse<ProductResponse>
      >("item", queryParams);

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllProducts:", error);
      return null;
    }
  }

  static async GetProductById(id: number): Promise<ProductResponse | null> {
    try {
      const response = await BaseService.get<ProductResponse>(`item/${id}`);

      return response ?? null;
    } catch (error) {
      console.error("Error in GetProductById:", error);
      return null;
    }
  }

  static async CreateProduct(
    payload: CreateProductRequest
  ): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("item", {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Produto criado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in CreateProduct:", error);
      return null;
    }
  }

  static async UpdateProduct(
    id: number,
    payload: UpdateProductRequest
  ): Promise<object | null> {
    try {
      const response = await BaseService.patch<object>(`item/${id}`, {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Produto atualizado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateProduct:", error);
      return null;
    }
  }

  static async DeleteProductById(id: number): Promise<boolean | null> {
    try {
      const response = await BaseService.delete<boolean>(`item/${id}`);

      if (response) {
        useAlertStore().show("warning", "Produto removido com sucesso.");
      }

      return response ?? false;
    } catch (error) {
      console.error("Error in DeleteProductById:", error);
      return false;
    }
  }
}
