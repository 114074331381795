import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isDialogOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogOpen) = $event)),
    width: "auto",
    scrollable: ""
  }, {
    activator: _withCtx(({ props: activatorProps }) => [
      _createVNode(_component_v_btn, _mergeProps({
        variant: "flat",
        icon: "mdi-delete-outline"
      }, activatorProps), null, 16)
    ]),
    default: _withCtx(({ isActive }) => [
      _createVNode(_component_v_card, {
        class: "py-0",
        title: "Remover endereço"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_text, { class: "px-4" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Tem certeza que deseja remover esse endereço? ")
            ])),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "text-capitalize",
                onClick: ($event: any) => (isActive.value = false)
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Fechar ")
                ])),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "text-capitalize",
                color: "red-darken-3",
                variant: "tonal",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRemoveAddress(_ctx.props.addressId)))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Remover ")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}