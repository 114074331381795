import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.svg'


const _hoisted_1 = { class: "h-100vh pt-50 pb-50" }
const _hoisted_2 = { class: "d-table" }
const _hoisted_3 = { class: "d-table-cell" }
const _hoisted_4 = { class: "pingo-form ms-auto me-auto" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "d-flex justify-center align-items-center mb-10" }
const _hoisted_7 = { class: "bg-white border-radius p-20" }
const _hoisted_8 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_10 = { class: "d-flex info align-center justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_img, {
                src: _imports_0,
                alt: "logo",
                width: "100"
              })
            ])
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                  _createTextVNode(" Email "),
                  _createElementVNode("span", null, "*")
                ], -1)),
                _createVNode(_component_TextInput, {
                  label: 'Digite o seu email',
                  type: 'email',
                  modelValue: _ctx.signInForm.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.signInForm.email) = $event)),
                  "error-messages": 
                    _ctx.v$.email.$errors ? _ctx.v$.email.$errors[0]?.$message : undefined
                  
                }, null, 8, ["modelValue", "error-messages"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                  _createTextVNode(" Senha "),
                  _createElementVNode("span", null, "*")
                ], -1)),
                _createVNode(_component_TextInput, {
                  label: 'Digite a sua senha',
                  type: 'password',
                  modelValue: _ctx.signInForm.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signInForm.password) = $event)),
                  "error-messages": 
                    _ctx.v$.password.$errors
                      ? _ctx.v$.password.$errors[0]?.$message
                      : undefined
                  
                }, null, 8, ["modelValue", "error-messages"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_v_checkbox, {
                class: "gray-color",
                "hide-details": "",
                color: "info",
                modelValue: _ctx.signInForm.remember,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.signInForm.remember) = $event)),
                label: "Lembrar deste acesso"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_router_link, {
                to: "/autenticar/esqueci-minha-senha",
                class: "d-inline-block main-color w-auto fw-medium"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Esqueceu a sua senha? ")
                ])),
                _: 1
              })
            ]),
            _createVNode(_component_PrimaryButton, {
              class: "mt-0",
              "is-loading": _ctx.isLoading,
              label: "Entrar"
            }, null, 8, ["is-loading"])
          ], 32)
        ])
      ])
    ])
  ]))
}