<template>
  <v-text-field
    :label="label"
    type="text"
    v-money="money"
    :hide-details="!required"
    @update:model-value="(value) => $emit('update:value', value)"
  >
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";

export default defineComponent({
  name: "MonetaryInput",
  data() {
    return {
      price: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  props: {
    label: {
      type: String,
      default: "Escreva",
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  setup() {
    return {};
  },
});
</script>
