<template>
  <button
    class="default-btn"
    :style="buttonStyle"
    type="submit"
    :disabled="isLoading"
  >
    <v-progress-circular
      v-if="isLoading"
      class="mr-2"
      indeterminate
      size="18"
      width="2"
      :color="secondaryColor"
    >
    </v-progress-circular>

    <span>{{ label }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "PrimaryButton",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "submit",
    },
    label: {
      type: String,
      default: "Entrar",
      required: true,
    },
  },
  setup() {
    const primaryColor = computed(() => "#757fef");
    const secondaryColor = computed(() => "#ffffff");

    const buttonStyle = computed(() => ({
      backgroundColor: primaryColor.value,
    }));

    return {
      buttonStyle,
      secondaryColor,
    };
  },
});
</script>
