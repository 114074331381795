import { defineStore } from "pinia";

interface AuthState {
  token: string;
  login: string;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): {
    token: string;
    user: object | string;
    auth: AuthState | null;
    expiresAt: number | null;
  } => ({
    token: "",
    user: {},
    auth: null,
    expiresAt: null,
  }),

  getters: {
    getAutoSign(state): AuthState | null {
      const auth = localStorage.getItem("auth");
      if (!auth) return null;
      return JSON.parse(auth) ?? state.auth;
    },

    getToken(state): string {
      return localStorage.getItem("token") ?? state.token;
    },

    getUser(state): object | string {
      const user = localStorage.getItem("user");
      if (!user) return "";

      return JSON.parse(localStorage.getItem("user") ?? "") ?? state.user;
    },

    getExpiresAt(): number | null {
      return (
        this.expiresAt || parseInt(localStorage.getItem("expiresAt") ?? "0")
      );
    },
  },

  actions: {
    setAutoSign(data: AuthState) {
      localStorage.setItem("auth", JSON.stringify(data));
      this.auth = data;
    },

    clearAutoSign() {
      localStorage.removeItem("auth");
      this.auth = null;
      this.token = "";
    },

    setToken(token: string) {
      localStorage.setItem("token", token);
      this.token = token;
    },

    setUser(user: object) {
      localStorage.setItem("user", JSON.stringify(user));
      this.user = user;
    },

    setExpiresAt(expiresAt: number) {
      localStorage.setItem("expiresAt", expiresAt.toString());
      this.expiresAt = expiresAt;
    },

    setLogout() {
      localStorage.removeItem("auth");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("expiresAt");

      this.auth = null;
      this.token = "";
      this.user = {};
      this.expiresAt = null;
    },
  },
});
