import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "ps-0 mt-0" }
const _hoisted_2 = { role: "button" }
const _hoisted_3 = { role: "button" }
const _hoisted_4 = { class: "v-card-header border-bottom d-flex justify-space-between" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "pingo-form-group without-icon" }
const _hoisted_7 = { class: "pingo-form-group without-icon" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "v-card-header d-flex align-items-center justify-space-between border-bottom" }
const _hoisted_10 = { class: "v-card-header mb-0" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "column mt-20" }
const _hoisted_14 = {
  key: 0,
  class: "d-flex flex-column justify-space-center align-items-center text-center w-100 py-15"
}
const _hoisted_15 = { class: "v-card-header border-bottom" }
const _hoisted_16 = { class: "d-flex justify-space-between align-items-center w-100" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { class: "box" }
const _hoisted_19 = { class: "fw-semibold mb-15" }
const _hoisted_20 = { class: "list ps-0 mb-0 mt-0" }
const _hoisted_21 = { class: "gray-color position-relative" }
const _hoisted_22 = { class: "gray-color position-relative" }
const _hoisted_23 = { class: "gray-color position-relative" }
const _hoisted_24 = {
  key: 2,
  class: "text-end"
}
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_DeleteAddressModal = _resolveComponent("DeleteAddressModal")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Editar Usuário",
      mainTitle: "Editar Usuário"
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _createElementVNode("a", {
              class: _normalizeClass({ 'router-link-active active': _ctx.tab === 'personal' }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tab = 'personal'))
            }, " Informações Pessoais ", 2)
          ]),
          _createElementVNode("li", _hoisted_3, [
            _createElementVNode("a", {
              class: _normalizeClass({ 'router-link-active active': _ctx.tab === 'address' }),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tab = 'address'))
            }, " Informações de Endereço ", 2)
          ])
        ]),
        (_ctx.tab == 'personal')
          ? (_openBlock(), _createBlock(_component_v_form, {
              key: 0,
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"])),
              disabled: _ctx.userData.type == _ctx.UserType.BUSINESS
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      lg: "12",
                      md: "12"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "mb-0" }, "Informações Básicas", -1)),
                          _createVNode(_component_v_switch, {
                            label: _ctx.updateUserForm.isActive ? 'Ativo' : 'Inativo',
                            modelValue: _ctx.updateUserForm.isActive,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.updateUserForm.isActive) = $event)),
                            "hide-details": true
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              lg: "6",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_5, [
                                  _cache[10] || (_cache[10] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                    _createTextVNode(" Nome "),
                                    _createElementVNode("span", null, "*")
                                  ], -1)),
                                  _createVNode(_component_TextInput, {
                                    label: 'Digite o nome do usuário aqui',
                                    type: 'email',
                                    modelValue: _ctx.updateUserForm.name,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.updateUserForm.name) = $event)),
                                    "error-messages": 
                    _ctx.v$.name.$errors ? _ctx.v$.name.$errors[0]?.$message : undefined
                  
                                  }, null, 8, ["modelValue", "error-messages"])
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              lg: "6",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _cache[11] || (_cache[11] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                    _createTextVNode(" Email "),
                                    _createElementVNode("span", null, "*")
                                  ], -1)),
                                  _createVNode(_component_TextInput, {
                                    label: 'Digite o email do usuário aqui',
                                    type: 'email',
                                    modelValue: _ctx.updateUserForm.email,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.updateUserForm.email) = $event)),
                                    "error-messages": 
                    _ctx.v$.email.$errors ? _ctx.v$.email.$errors[0]?.$message : undefined
                  
                                  }, null, 8, ["modelValue", "error-messages"])
                                ])
                              ]),
                              _: 1
                            }),
                            (_ctx.userData.type == 'ADMIN')
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  cols: "12",
                                  lg: "6",
                                  md: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_7, [
                                      _cache[12] || (_cache[12] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                        _createTextVNode(" Dia de Cobrança "),
                                        _createElementVNode("span", null, "*")
                                      ], -1)),
                                      _createVNode(_component_TextInput, {
                                        label: 'Digite o dia de cobrança aqui',
                                        type: 'text',
                                        modelValue: _ctx.updateUserForm.billingDay,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.updateUserForm.billingDay) = $event)),
                                        "onUpdate:value": _cache[6] || (_cache[6] = (value) => _ctx.updateUserForm.billingDay = value),
                                        "error-messages": 
                    _ctx.v$.billingDay.$errors
                      ? _ctx.v$.billingDay.$errors[0]?.$message
                      : undefined
                  
                                      }, null, 8, ["modelValue", "error-messages"])
                                    ])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true),
        (_ctx.tab == 'address')
          ? (_openBlock(), _createElementBlock("form", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[13] || (_cache[13] = _createElementVNode("h5", { class: "mb-0" }, "Informações de Endereço", -1)),
                  _createElementVNode("p", null, [
                    (_ctx.addresses.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Parece que essa empresa não possui nenhum endereço. "))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("p", null, [
                    (_ctx.addresses.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Gerencie os endereços da empresa aqui. "))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.userData.type == 'ADMIN')
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: {
            path:
              '/configuracoes/endereco/novo/userId=' + _ctx.updateUserForm.userId,
          }
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("button", {
                          type: "button",
                          class: "default-btn px-8",
                          style: {"padding":"14px 25px 14px 25px !important"}
                        }, " Novo endereço ", -1)
                      ])),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_13, [
                (_ctx.isLoadingAdresses)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_v_progress_circular, {
                        class: "mb-8",
                        color: "primary",
                        indeterminate: ""
                      }),
                      _cache[15] || (_cache[15] = _createElementVNode("span", { class: "gray-color fw-medium d-inline-block" }, "Carregando...", -1))
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isLoadingAdresses)
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addresses, (address, index) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            key: index,
                            cols: "12",
                            lg: "4",
                            md: "4",
                            sm: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, { class: "pingo-card address-card" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("div", _hoisted_16, [
                                      _createElementVNode("h5", _hoisted_17, "Endereço #" + _toDisplayString(index + 1), 1),
                                      _createVNode(_component_DeleteAddressModal, {
                                        "address-id": address.addressId
                                      }, null, 8, ["address-id"])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_18, [
                                    _createElementVNode("h5", _hoisted_19, _toDisplayString(_ctx.updateUserForm.name), 1),
                                    _createElementVNode("ul", _hoisted_20, [
                                      _createElementVNode("li", _hoisted_21, [
                                        _cache[16] || (_cache[16] = _createElementVNode("span", { class: "fw-medium d-inline-block" }, "Endereço:", -1)),
                                        _createTextVNode(" " + _toDisplayString(address.street) + " " + _toDisplayString(address.number) + ", " + _toDisplayString(address.neighborhood) + " - " + _toDisplayString(address.city) + ", " + _toDisplayString(address.state) + " " + _toDisplayString(address.zipCode) + ", " + _toDisplayString(address.country), 1)
                                      ]),
                                      _createElementVNode("li", _hoisted_22, [
                                        _cache[17] || (_cache[17] = _createElementVNode("span", { class: "fw-medium d-inline-block" }, "Complemento:", -1)),
                                        _createTextVNode(" " + _toDisplayString(address.complement || "N/A"), 1)
                                      ]),
                                      _createElementVNode("li", _hoisted_23, [
                                        _cache[18] || (_cache[18] = _createElementVNode("span", { class: "fw-medium d-inline-block" }, " Endereço padrão: ", -1)),
                                        _createTextVNode(" " + _toDisplayString(address.isDefault ? "Sim" : "Não"), 1)
                                      ])
                                    ]),
                                    _createVNode(_component_v_divider, { class: "my-4" }),
                                    _createVNode(_component_router_link, {
                                      to: `/configuracoes/endereco/editar/${address.addressId}`
                                    }, {
                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                        _createElementVNode("button", {
                                          type: "button",
                                          class: "default-btn w-100 px-8"
                                        }, " Alterar endereço ", -1)
                                      ])),
                                      _: 2
                                    }, 1032, ["to"])
                                  ])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.tab == 'personal')
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              (_ctx.userData.type == _ctx.UserType.ADMIN)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "default-btn",
                    disabled: _ctx.isLoadingUserRequest,
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleSubmitGeneral()))
                  }, [
                    (_ctx.isLoadingUserRequest)
                      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                          key: 0,
                          indeterminate: "",
                          size: "18",
                          width: "2"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isLoadingUserRequest)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, " Atualizando Usuário... "))
                      : _createCommentVNode("", true),
                    (!_ctx.isLoadingUserRequest)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, " Atualizar Usuário "))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_25))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}