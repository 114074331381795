import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/admin.jpg'


const _hoisted_1 = { class: "fw-semibold" }
const _hoisted_2 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, null, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("button", _mergeProps({ class: "profile-btn main-color border-none bg-transparent position-relative" }, props), [
        _createVNode(_component_v_img, {
          src: _imports_0,
          class: "rounded-circle",
          alt: "admin"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.userTypeMessage), 1),
        _createElementVNode("span", _hoisted_1, "Olá, " + _toDisplayString(_ctx.user?.name ?? "Usuário"), 1)
      ], 16)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, { class: "profile-menu-dropdown" }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_2, [
            _createElementVNode("li", null, [
              (_ctx.user.type == 'ADMIN')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: "configuracoes/politica-de-privacidade"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("i", { class: "ri-settings-3-line" }, null, -1),
                      _createTextVNode(" Configurações ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.user.type == 'BUSINESS')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: "/configuracoes/enderecos"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("i", { class: "ri-settings-3-line" }, null, -1),
                      _createTextVNode(" Configurações ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("li", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLogout()))
            }, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "ri-shut-down-line" }, null, -1),
                  _createTextVNode(" Sair da conta ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}