<template>
  <v-card class="mb-25 pingo-card">
    <div class="v-card-header border-bottom">
      <h5 class="mb-0">Configurações</h5>
    </div>

    <!-- Navigate -->
    <ul class="ps-0 mt-0">
      <!-- <li>
        <router-link to="/configuracoes/conta"> Conta </router-link>
      </li> -->
      <li v-if="user.type == 'BUSINESS'">
        <router-link to="/configuracoes/enderecos"> Endereços </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/politica-de-privacidade">
          Política de Privacidade
        </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/termos-e-condicoes">
          Termos e Condições
        </router-link>
      </li>
    </ul>

    <!-- Form -->
    <form>
      <div class="d-flex align-items-center justify-space-between">
        <div>
          <h5 class="fw-semibold mb-8">Endereços</h5>
          <p>
            <span v-if="user.type == 'ADMIN'">
              Gerencie os endereços da empresa aqui.
            </span>
            <span v-if="user.type == 'BUSINESS'">
              Visualize o endereço da sua empresa aqui.
            </span>
          </p>
        </div>

        <router-link
          v-if="user.type == 'ADMIN'"
          to="/configuracoes/endereco/novo"
        >
          <button type="button" class="default-btn px-8">Novo endereço</button>
        </router-link>
      </div>

      <div class="column mt-20">
        <div
          v-if="isLoadingAdresses"
          class="d-flex flex-column justify-space-center align-items-center text-center w-100 py-15"
        >
          <v-progress-circular
            class="mb-8"
            color="primary"
            indeterminate
          ></v-progress-circular>

          <span class="gray-color fw-medium d-inline-block">Carregando...</span>
        </div>

        <v-form v-if="!isLoadingAdresses">
          <v-row>
            <v-col lg="12" md="12">
              <v-row>
                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      CEP
                      <span>*</span>
                    </label>

                    <PostalCodeInput
                      :label="'Digite o seu CEP aqui'"
                      v-model="newAddressForm.zipCode"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Rua
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Nome da rua'"
                      v-model="newAddressForm.street"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Bairro
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite o bairro aqui'"
                      v-model="newAddressForm.neighborhood"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Cidade
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite a cidade aqui'"
                      v-model="newAddressForm.city"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Estado
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite o estado aqui'"
                      v-model="newAddressForm.state"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Número
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite o número aqui'"
                      v-model="newAddressForm.number"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Complemento
                    </label>

                    <TextInput
                      :label="'Digite o complemento aqui (opcional)'"
                      v-model="newAddressForm.complement"
                      disabled
                      :required="false"
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Endereço Principal?
                      <span>*</span>
                    </label>

                    <v-select
                      v-model="newAddressForm.isDefault"
                      :required="false"
                      disabled
                      :items="[
                        { title: 'Sim', value: true },
                        { title: 'Não', value: false },
                      ]"
                    />
                  </div>
                </v-col>
              </v-row>

              <!-- <div class="text-end">
                  <button type="submit" class="default-btn">
                    <v-progress-circular
                      v-if="isLoadingAddressRequest"
                      indeterminate
                      size="18"
                      width="2"
                    ></v-progress-circular>

                    <span v-if="isLoadingAddressRequest">
                      Criando Endereço...
                    </span>
                    <span v-if="!isLoadingAddressRequest">
                      Criar Endereço
                    </span>
                  </button>
                </div> -->
            </v-col>
          </v-row>
        </v-form>

        <!-- <v-row v-if="!isLoadingAdresses">
          <v-col
            v-for="(address, index) in addresses"
            v-bind:key="index"
            cols="12"
            lg="4"
            md="4"
            sm="6"
          >
            <v-card class="pingo-card address-card">
              <div class="v-card-header border-bottom">
                <div
                  class="d-flex justify-space-between align-items-center w-100"
                >
                  <h5 class="mb-0">Endereço #{{ index + 1 }}</h5>

                  <DeleteAddressModal :address-id="address.addressId" />
                </div>
              </div>

              <div class="box">
                <h5 class="fw-semibold mb-15">{{ user.name }}</h5>

                <ul class="list ps-0 mb-0 mt-0">
                  <li class="gray-color position-relative">
                    <span class="fw-medium d-inline-block">Endereço:</span>
                    {{ address.street }} {{ address.number }},
                    {{ address.neighborhood }} - {{ address.city }},
                    {{ address.state }} {{ address.zipCode }},
                    {{ address.country }}
                  </li>

                  <li class="gray-color position-relative">
                    <span class="fw-medium d-inline-block">Complemento:</span>
                    {{ address.complement || "N/A" }}
                  </li>

                  <li class="gray-color position-relative">
                    <span class="fw-medium d-inline-block">
                      Endereço padrão:
                    </span>
                    {{ address.isDefault ? "Sim" : "Não" }}
                  </li>
                </ul>

                <v-divider class="my-4"></v-divider>

                <router-link
                  :to="`/configuracoes/endereco/editar/${address.addressId}`"
                >
                  <button type="button" class="default-btn w-100 px-8">
                    Alterar endereço
                  </button>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row> -->
      </div>
    </form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import EventBus from "@/utils/eventBus";

import { useAuthStore } from "@/stores/auth.store";

import { useAddress } from "@/composables/useAddress";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import PostalCodeInput from "@/components/Forms/AdvancedElements/Input/PostalCodeInput.vue";

export default defineComponent({
  name: "SettingsAddressPage",
  components: {
    TextInput,
    PostalCodeInput,
  },
  setup() {
    const authStore = useAuthStore();

    const { newAddressForm, addresses, getAllAddresses } = useAddress();

    const user: Ref = ref(authStore.getUser);

    const isLoadingAdresses = ref(false);
    const isLoadingAddressRequest = ref(false);

    const fetchAddressesData = async () => {
      try {
        isLoadingAdresses.value = true;

        getAllAddresses(1, 25).then(() => {
          isLoadingAdresses.value = false;

          const defaultAddress = addresses.value.find(
            (address) => address.isDefault
          );

          if (!defaultAddress) return;

          newAddressForm.city = defaultAddress.city;
          newAddressForm.state = defaultAddress.state;
          newAddressForm.country = defaultAddress.country;
          newAddressForm.zipCode = defaultAddress.zipCode;
          newAddressForm.street = defaultAddress.street;
          newAddressForm.complement = defaultAddress.complement;
          newAddressForm.neighborhood = defaultAddress.neighborhood;
          newAddressForm.number = defaultAddress.number;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de endereços:", error);
      }
    };

    onMounted(async () => {
      await fetchAddressesData();

      // Listen for the address-update event
      EventBus.on("address-update", async () => {
        await fetchAddressesData();
      });
    });

    onUnmounted(() => {
      // Remove the event listener when the component is destroyed
      EventBus.off("address-update", fetchAddressesData);
    });

    return {
      isLoadingAddressRequest,
      isLoadingAdresses,
      user,
      addresses,
      newAddressForm,
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.address-card {
  border: 1px solid #ccc !important;
}

.v-input__details {
  display: none !important;
}
button {
  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}
ul {
  list-style-type: none;
  margin-bottom: 15px;

  li {
    display: inline-block;
    margin: {
      right: 15px;
      bottom: 15px;
    }
    a {
      color: var(--paragraphColor);
      background-color: #f4f4f4;
      display: inline-block;
      border-radius: 5px;
      padding: 10px 25px;
      font: {
        size: var(--fontSize);
        weight: 600;
      }
      &:hover,
      &.active {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  ul {
    li {
      a {
        font-size: 14px;
      }
    }
  }

  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
