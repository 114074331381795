import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "breadcrumb-card mb-25 d-md-flex align-items-center justify-space-between" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "breadcrumb mb-0 ps-0" }
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = {
  key: 0,
  class: "breadcrumb-item fw-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.pageTitle), 1),
    _createElementVNode("ol", _hoisted_3, [
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "ri-home-8-line" }, null, -1),
            _createTextVNode(" Início ")
          ])),
          _: 1
        })
      ]),
      (_ctx.route.path != '/')
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString(_ctx.mainTitle), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}