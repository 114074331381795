import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SuccessSnackbar = _resolveComponent("SuccessSnackbar")!
  const _component_LoadingDialog = _resolveComponent("LoadingDialog")!
  const _component_AlertComponent = _resolveComponent("AlertComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.shouldShowSidebar)
      ? (_openBlock(), _createBlock(_component_MainSidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'main-content d-flex flex-column',
        { active: _ctx.stateStoreInstance.open },
        { 'padding-left-0': !_ctx.shouldShowPaddingLeftZero },
      ])
    }, [
      (_ctx.shouldShowHeader)
        ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1))
    ], 2),
    _createVNode(_component_SuccessSnackbar),
    _createVNode(_component_LoadingDialog),
    (_ctx.alertStoreInstance.isVisible)
      ? (_openBlock(), _createBlock(_component_AlertComponent, {
          key: 1,
          style: {"position":"fixed","right":"15px","bottom":"0px"},
          message: _ctx.alertStoreInstance.message,
          type: _ctx.alertStoreInstance.type,
          onCloseAlert: _cache[0] || (_cache[0] = 
        () => (_ctx.alertStoreInstance.isVisible = !_ctx.alertStoreInstance.isVisible)
      )
        }, null, 8, ["message", "type"]))
      : _createCommentVNode("", true)
  ]))
}