<template>
  <PageTitle pageTitle="Novo Endereço" mainTitle="Configurações" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações Básicas</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  CEP
                  <span>*</span>
                </label>

                <PostalCodeInput
                  :label="'Digite o seu CEP aqui'"
                  v-model="newAddressForm.zipCode"
                  @update:value="(value: string) => updatePostalCode(value)"
                  :error-messages="
                    v$.zipCode.$errors
                      ? v$.zipCode.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Rua
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Nome da rua'"
                  v-model="newAddressForm.street"
                  :error-messages="
                    v$.street.$errors
                      ? v$.street.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Bairro
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o bairro aqui'"
                  v-model="newAddressForm.neighborhood"
                  :error-messages="
                    v$.neighborhood.$errors
                      ? v$.neighborhood.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Cidade
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite a cidade aqui'"
                  v-model="newAddressForm.city"
                  :error-messages="
                    v$.city.$errors ? v$.city.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Estado
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o estado aqui'"
                  v-model="newAddressForm.state"
                  :error-messages="
                    v$.state.$errors ? v$.state.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Número
                  <span>*</span>
                </label>
                <TextInput
                  :label="'Digite o número aqui'"
                  v-model="newAddressForm.number"
                  :error-messages="
                    v$.number.$errors
                      ? v$.number.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Complemento
                </label>
                <TextInput
                  :label="'Digite o complemento aqui (opcional)'"
                  v-model="newAddressForm.complement"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Endereço Principal?
                  <span>*</span>
                </label>
                <v-select
                  v-model="newAddressForm.isDefault"
                  :items="[
                    { title: 'Sim', value: true },
                    { title: 'Não', value: false },
                  ]"
                  :error-messages="
                    v$.isDefault.$errors
                      ? v$.isDefault.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>

          <div class="text-end">
            <button type="submit" class="default-btn">
              <v-progress-circular
                v-if="isLoadingAddressRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>

              <span v-if="isLoadingAddressRequest"> Criando Endereço... </span>
              <span v-if="!isLoadingAddressRequest"> Criar Endereço </span>
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAddress } from "@/composables/useAddress";
import { useViaCEP } from "@/composables/useViaCEP";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import PostalCodeInput from "@/components/Forms/AdvancedElements/Input/PostalCodeInput.vue";

export default defineComponent({
  name: "SettingsNewAddressPage",
  components: {
    PageTitle,
    TextInput,
    PostalCodeInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { address, getAddress } = useViaCEP();

    const { newAddressForm, newAddressFormRules, createAddress } = useAddress();

    const isLoadingAddressInfo = ref(false);
    const isLoadingAddressRequest = ref(false);

    const updatePostalCode = (value: string) => {
      if (value.length == 9)
        getAddress(value).then(() => {
          if (address.value) {
            newAddressForm.street = address.value.logradouro;
            newAddressForm.complement = address.value.complemento;
            newAddressForm.neighborhood = address.value.bairro;
            newAddressForm.city = address.value.localidade;
            newAddressForm.state = address.value.uf;
          }
        });
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...newAddressFormPayload } = newAddressForm;

      delete newAddressFormPayload.userId;

      if (route.params.userId)
        newAddressFormPayload.userId = parseInt(route.params.userId.toString());

      const response = await createAddress(newAddressFormPayload);

      isLoadingAddressRequest.value = !isLoadingAddressRequest.value;

      if (response) router.push({ name: "SettingsAddressPage" });
    };

    const v$ = useVuelidate(newAddressFormRules, newAddressForm);

    return {
      v$,
      route,
      isLoadingAddressInfo,
      isLoadingAddressRequest,
      newAddressForm,
      updatePostalCode,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
