/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import VueApexCharts from "vue3-apexcharts";
// import { QuillEditor } from "@vueup/vue-quill";

import { maskito } from "@maskito/vue";

import "vue3-carousel/dist/carousel.css";

import "remixicon/fonts/remixicon.css";
import "./assets/custom.scss";
import "vuetify/dist/vuetify.min.css";

import { createPinia } from "pinia";

import money from "v-money";
import i18n from "./i18n";

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(createPinia())
  .use(VueApexCharts)
  .use(money, { precision: 4 })
  .directive("maskito", maskito);
// .component("QuillEditor", QuillEditor);

app.mount("#app");
