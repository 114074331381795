import { ViaCepAddressResponse } from "@/types/response/viaCepAddress";
import axios from "axios";

export class ViaCepService {
  static async GetAddress(
    postal_code: string
  ): Promise<ViaCepAddressResponse | null> {
    try {
      const response = await axios.get<ViaCepAddressResponse>(
        `https://viacep.com.br/ws/${postal_code}/json`
      );
      return response.data ?? null;
    } catch (error) {
      console.error("Error in GetAddress:", error);
      return null;
    }
  }
}
