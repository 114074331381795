export function startTokenExpirationWatcher(): void {
  // console.log("Iniciando o watcher de expiração do token");

  const checkInterval = 1000 * 10; // 1 minuto
  const warningTime = 1000 * 60 * 15; // 15 minutos

  const interval = setInterval(() => {
    const expiresAt = parseInt(localStorage.getItem("expiresAt") || "0");
    const currentTime = Date.now();
    const timeLeft = expiresAt - currentTime;

    // console.info(`Tempo restante: ${timeLeft} ms`);

    if (timeLeft <= warningTime && timeLeft > 0) {
      const event = new CustomEvent<number>("token-expiration-warning", {
        detail: timeLeft,
      });
      window.dispatchEvent(event);
      // console.warn("Evento de aviso de expiração do token disparado");
    }

    if (timeLeft <= 0) {
      console.error("Token expirado");
      clearInterval(interval);
    }
  }, checkInterval);
}
