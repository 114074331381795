import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/not-found.gif'


const _hoisted_1 = { class: "not-found text-center" }
const _hoisted_2 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_img, {
      src: _imports_0,
      class: "d-block mb-25",
      alt: "não encontrado"
    }),
    _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "mb-15" }, "Ops!! A página que você está procurando não existe!", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "ms-auto me-auto" }, " A página que você está procurando pode ter sido removida, teve seu nome alterado ou está temporariamente indisponível. ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "success",
        to: "/"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Voltar para o Dashboard")
        ])),
        _: 1
      })
    ])
  ]))
}