<template>
  <div>
    <v-btn
      class="text-capitalize"
      color="green-darken-3"
      variant="tonal"
      @click="generatePDF"
    >
      Gerar Nota
    </v-btn>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { jsPDF } from "jspdf"; // Importando jsPDF

export default defineComponent({
  name: "PrinterComponent",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Função para abreviar o nome do item se for maior que 20 caracteres
    const abbreviateItemName = (name) => {
      if (name.length > 20) {
        return name.substring(0, 17) + "...             ";
      } else {
        return name + "                  "; // Adiciona 18 espaços à direita
      }
    };

    // Função para formatar o número do pedido no formato #0001
    const formatOrderId = (orderId) => {
      return `#${String(orderId).padStart(4, "0")}`;
    };

    // Função para gerar o PDF com margens laterais menores
    const generatePDF = () => {
      const doc = new jsPDF({
        unit: "mm", // Unidades em milímetros
        format: [80, 297], // Formato de página em milímetros (80mm x 297mm)
      });

      // Definindo margens
      const leftMargin = 5; // Margem esquerda reduzida para 5mm
      const topMargin = 10; // Margem superior
      let y = topMargin; // Posição inicial para o conteúdo

      // Adicionar título
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Comprovante de Pedido", leftMargin, y); // Adicionando título
      y += 10; // Avançando a posição para o próximo elemento

      // Informações do pedido
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text("MÃOS QUE NUTREM LTDA", leftMargin, y);
      y += 5;
      doc.text("CNPJ: 55.263.890/0001-16", leftMargin, y);
      y += 5;
      doc.text("AV. CENTENARIO 116 BOX IT 33,", leftMargin, y);
      y += 5;
      doc.text("Zona 8 - Maringá - PR", leftMargin, y);
      y += 5;
      doc.text(
        "-----------------------------------------------------------",
        leftMargin,
        y
      );
      y += 10;

      // Informações do pedido
      doc.setFont("helvetica", "bold");
      doc.text(`Pedido: ${formatOrderId(props.order.orderId)}`, leftMargin, y);
      y += 5;

      // Informações do usuário
      doc.setFont("helvetica", "bold");
      doc.text(`Nome: ${props.order.user.name}`, leftMargin, y);
      y += 5;
      doc.setFont("helvetica", "normal");
      doc.text(
        `Horário: ${new Date(props.order.createdAt).toLocaleString()}`,
        leftMargin,
        y
      );
      y += 10;

      // Endereço do usuário
      const defaultAddress = props.order.user.addresses.find(
        (address) => address.isDefault === 1
      );
      if (defaultAddress) {
        doc.setFont("helvetica", "bold");
        doc.text("Endereço:", leftMargin, y);
        y += 5;

        // Quebra o endereço em múltiplas linhas, se necessário
        const addressText = `${defaultAddress.street}, ${defaultAddress.number}, ${defaultAddress.neighborhood}, ${defaultAddress.city}, ${defaultAddress.state}, ${defaultAddress.zipCode}, ${defaultAddress.country}`;
        const splitAddress = doc.splitTextToSize(addressText, 70); // Ajuste o valor 70 conforme a largura desejada

        doc.setFont("helvetica", "normal");
        // Imprime cada linha do endereço quebrado
        splitAddress.forEach((line) => {
          doc.text(line, leftMargin, y);
          y += 6; // Ajuste a altura entre as linhas
        });
        y += 10;
      }

      doc.setFont("helvetica", "bold");
      doc.text("Produtos solicitados", leftMargin, y); // Adicionando título
      y += 5;

      // Dados da compra com cabeçalhos abreviados
      doc.setFont("helvetica", "normal");
      doc.text(
        "Qtd.  Desc.                                      Preço",
        leftMargin,
        y
      );
      y += 5;
      doc.text(
        "-----------------------------------------------------------",
        leftMargin,
        y
      );
      y += 5;

      // Adicionar itens da compra
      props.order.items.forEach((item) => {
        const itemName = abbreviateItemName(item.item.name);
        const formattedText = `${
          item.quantity
        }x  -  ${itemName} ${new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(item.unitPrice)}`;

        doc.text(formattedText, leftMargin, y);
        y += 6; // Ajuste para o próximo item ou observação

        // Adiciona o texto 'OBSERVAÇÃO:' antes da observação
        const observation = item.observations
          ? `OBSERVAÇÃO: ${item.observations.toUpperCase()}`
          : "OBSERVAÇÃO: N/A";
        const splitObservation = doc.splitTextToSize(observation, 70); // Ajuste o valor conforme a largura desejada

        // Imprime cada linha da observação quebrada
        splitObservation.forEach((line) => {
          doc.text(line, leftMargin, y);
          y += 6; // Ajuste a altura entre as linhas
        });

        y += 4; // Espaço adicional após a observação
      });

      // Largura máxima disponível para os valores
      const pageWidth = 80; // Largura da página em mm
      const rightMargin = 5; // Margem direita em mm

      // Função auxiliar para obter a largura do texto
      const getTextWidth = (text, fontSize = 10) => {
        doc.setFontSize(fontSize);
        return doc.getTextWidth(text);
      };

      // Função auxiliar para posicionar o valor à direita
      const drawRightAlignedText = (text, y) => {
        const textWidth = getTextWidth(text);
        const x = pageWidth - textWidth - rightMargin;
        doc.text(text, x, y);
      };

      doc.setFont("helvetica", "bold");
      doc.text("Sumário", leftMargin, y); // Adicionando título

      doc.setFont("helvetica", "normal");

      // Subtotal
      y += 5;
      doc.text("Subtotal:", leftMargin, y);
      drawRightAlignedText(
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(props.order.totalAmount),
        y
      );

      // Desconto
      y += 7;
      doc.text("Desconto:", leftMargin, y);
      drawRightAlignedText(
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(0),
        y
      );

      // Taxa de Entrega
      y += 7;
      doc.text("Taxa de Entrega:", leftMargin, y);
      drawRightAlignedText(
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(0),
        y
      );

      // Total
      y += 7;
      doc.text("Total a pagar:", leftMargin, y);
      drawRightAlignedText(
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(props.order.totalAmount),
        y
      );

      // Salvar PDF com nome incluindo o ID do pedido
      doc.save(`comprovante-de-pedido-${props.order.orderId}.pdf`);
    };

    return {
      generatePDF, // Expondo a função de gerar PDF
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
