import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.svg'


const _hoisted_1 = { class: "h-100vh pt-50 pb-50" }
const _hoisted_2 = { class: "d-table" }
const _hoisted_3 = { class: "d-table-cell" }
const _hoisted_4 = { class: "pingo-form ms-auto me-auto" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "d-flex mb-10 align-items-center" }
const _hoisted_7 = { class: "bg-white border-radius p-20" }
const _hoisted_8 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_10 = { class: "text-center back-to-login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "mb-0 me-30" }, "Esqueci minha senha", -1)),
              _createVNode(_component_v_img, {
                src: _imports_0,
                alt: "logo",
                width: "100"
              })
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, " Insira seu email e nós enviaremos as instruções para você recuperar sua senha ", -1))
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                  _createTextVNode(" Nova senha "),
                  _createElementVNode("span", null, "*")
                ], -1)),
                _createVNode(_component_TextInput, {
                  label: 'Digite a sua nova senha',
                  type: 'password',
                  modelValue: _ctx.passwordResetForm.password,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passwordResetForm.password) = $event)),
                  "error-messages": 
                    _ctx.v$.password.$errors
                      ? _ctx.v$.password.$errors[0]?.$message
                      : undefined
                  
                }, null, 8, ["modelValue", "error-messages"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                  _createTextVNode(" Confirme a sua nova senha "),
                  _createElementVNode("span", null, "*")
                ], -1)),
                _createVNode(_component_TextInput, {
                  label: 'Digite a sua nova senha novamente',
                  type: 'password',
                  modelValue: _ctx.passwordResetForm.confirmationPassword,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordResetForm.confirmationPassword) = $event)),
                  "error-messages": 
                    _ctx.v$.confirmationPassword.$errors
                      ? _ctx.v$.confirmationPassword.$errors[0]?.$message
                      : undefined
                  
                }, null, 8, ["modelValue", "error-messages"])
              ])
            ]),
            _createVNode(_component_PrimaryButton, {
              "is-loading": _ctx.isLoading,
              label: "Resetar senha"
            }, null, 8, ["is-loading"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_router_link, {
                to: "/autenticar/acessar",
                class: "d-inline-block main-color fw-medium position-relative"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1),
                  _createTextVNode(" Voltar para login ")
                ])),
                _: 1
              })
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}