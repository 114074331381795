<template>
  <PageTitle pageTitle="Colaboradores" mainTitle="Colaboradores" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Lista de Colaboradores</h5>

      <router-link
        class="default-btn pingo small"
        :to="{ name: 'NewUserPage', query: { type: 'COLLABORATOR' } }"
      >
        <i class="ri-add-fill"></i>
        Criar novo colaborador
      </router-link>

      <!-- <p>Atualizado por último em {{ new Date().toLocaleString() }}</p> -->
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="users"
          :loading="isLoadingUserCollaborators"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.isActive`]="{ value }">
            <span
              v-if="value"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Ativo
            </span>

            <span
              v-if="!value"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Inativo
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-items-center ga-6">
              <router-link
                v-if="userData.type == 'ADMIN' || userData.type == 'BUSINESS'"
                :to="{
                  name: 'EditUserPage',
                  params: { id: item.userId },
                  query: { type: 'COLLABORATOR' },
                }"
              >
                <v-btn
                  class="text-capitalize"
                  color="primary-darken-3"
                  variant="tonal"
                >
                  Editar
                </v-btn>
              </router-link>

              <v-dialog width="auto" scrollable>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="text-capitalize"
                    color="red-darken-3"
                    variant="tonal"
                    v-bind="activatorProps"
                  >
                    Remover
                  </v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card class="py-0" title="Remover colaborador">
                    <v-divider></v-divider>

                    <v-card-text class="px-4">
                      Tem certeza que deseja remover este colaborador?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        class="text-capitalize"
                        @click="isActive.value = false"
                      >
                        Fechar
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="text-capitalize"
                        color="red-darken-3"
                        variant="tonal"
                        @click="handleRemoveCollaborator(item.userId)"
                      >
                        Remover
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <v-menu v-if="userData.type == 'ADMIN'">
                <template v-slot:activator="{ props }">
                  <button
                    type="button"
                    v-bind="props"
                    class="position-relative dot-btn p-0 bg-transparent border-none"
                  >
                    <i class="ri-more-fill"></i>
                  </button>
                </template>

                <v-list class="dotMenu monthMenu" style="width: 250px">
                  <v-dialog
                    v-model="isUpdatePasswordModalOpen"
                    max-width="420"
                    scrollable
                  >
                    <template v-slot:activator="{ props: activatorProps }">
                      <button
                        v-bind="activatorProps"
                        type="button"
                        class="bg-transparent w-100 border-none"
                        @click="mapUserFormToupdateUserPasswordForm(item)"
                      >
                        Alterar senha
                      </button>
                    </template>

                    <template v-slot:default="{ isActive }">
                      <v-form @submit.prevent="handleUpdateCollaborator()">
                        <v-card class="py-0" title="Alterar senha">
                          <v-divider></v-divider>

                          <v-card-text class="px-4">
                            <v-row>
                              <v-col cols="12">
                                <div class="pingo-form-group without-icon mb-0">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Senha
                                  </label>

                                  <TextInput
                                    :label="'Digite a senha do usuário aqui'"
                                    :type="'password'"
                                    v-model="updateUserPasswordForm.password"
                                    :error-messages="
                                      v$.password.$errors
                                        ? v$.password.$errors[0]?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12">
                                <div class="pingo-form-group without-icon mb-0">
                                  <label
                                    class="d-block mb-12 fw-semibold gray-color"
                                  >
                                    Confirme a sua senha
                                  </label>

                                  <TextInput
                                    :label="'Digite a senha novamente'"
                                    :type="'password'"
                                    v-model="
                                      updateUserPasswordForm.confirmationPassword
                                    "
                                    :error-messages="
                                      v$.confirmationPassword.$errors
                                        ? v$.confirmationPassword.$errors[0]
                                            ?.$message
                                        : undefined
                                    "
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              class="text-capitalize"
                              @click="isActive.value = false"
                            >
                              Fechar
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                              class="text-capitalize"
                              color="green-darken-3"
                              variant="tonal"
                              type="submit"
                            >
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </template>
                  </v-dialog>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum colaborador </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useLoadingDialog } from "@/stores/loading.store";

import { useVuelidate } from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";

import { useUser } from "@/composables/useUser";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";
import type { UserResponse } from "@/types/response/user";
import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "CollaboratorsPage",
  components: {
    PageTitle,
    TextInput,
    ItemsPerPage,
  },
  setup() {
    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const {
      columns,
      pagination,
      users,
      getAllUsers,
      getAllCollaborators,
      updateUserPasswordForm,
      updateUserPasswordFormRules,
      updateUser,
      deleteUserById,
    } = useUser();

    const userData: Ref = ref(authStore.getUser);

    const isLoadingUserCollaborators = ref(false);
    const isUpdatePasswordModalOpen = ref(false);

    const fetchAllUsersData = async () => {
      try {
        isLoadingUserCollaborators.value = true;

        getAllUsers(
          pagination.value.currentPage ?? 1,
          pagination.value.totalPerPage ?? 10,
          UserType.COLLABORATOR
        ).then(() => {
          isLoadingUserCollaborators.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de colaboradores:", error);
      }
    };

    const fetchUserCollaboratorsData = async () => {
      try {
        isLoadingUserCollaborators.value = true;

        getAllCollaborators(
          pagination.value.currentPage,
          pagination.value.totalPerPage
          // user.value.userId
        ).then(() => {
          isLoadingUserCollaborators.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de colaboradores:", error);
      }
    };

    const mapUserFormToupdateUserPasswordForm = (user: UserResponse) => {
      updateUserPasswordForm.userId = user.userId;
      updateUserPasswordForm.name = user?.name ?? "";
      updateUserPasswordForm.billingDay = user?.billingDay ?? "";
      updateUserPasswordForm.document = user?.document ?? "";
      updateUserPasswordForm.type = "COLLABORATOR";
      updateUserPasswordForm.email = user?.email ?? "";
      updateUserPasswordForm.document = user?.document ?? "";
      updateUserPasswordForm.phone = user?.phone ?? "";
    };

    const handleUpdateCollaborator = async () => {
      if (!updateUserPasswordForm.userId) return;

      const result = await v$.value.$validate();

      if (!result) return;

      loadingStore.setLoading("Alterando senha...");

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmationPassword, ...updateUserPasswordFormPayload } =
        updateUserPasswordForm;

      updateUserPasswordFormPayload.document =
        updateUserPasswordFormPayload.document.replace(/\D/g, "");
      updateUserPasswordFormPayload.phone =
        updateUserPasswordFormPayload.phone.replace(/\D/g, "");

      if (userData.value.type != "ADMIN") {
        delete updateUserPasswordFormPayload.billingDay;
        delete updateUserPasswordFormPayload.dueDays;
      }

      const response = await updateUser(
        updateUserPasswordForm.userId,
        updateUserPasswordFormPayload
      );

      loadingStore.setLoadingOff();

      if (response) {
        isUpdatePasswordModalOpen.value = false;
      }
    };

    const handleRemoveCollaborator = async (user_id: number) => {
      loadingStore.setLoading("Removendo colaborador...");

      await deleteUserById(user_id);

      loadingStore.setLoadingOff();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      if (userData.value.type == UserType.ADMIN) await fetchAllUsersData();
      if (userData.value.type == UserType.BUSINESS)
        await fetchUserCollaboratorsData();
    };

    onMounted(async () => {
      if (userData.value.type == UserType.ADMIN) await fetchAllUsersData();
      if (userData.value.type == UserType.BUSINESS)
        await fetchUserCollaboratorsData();
    });

    const v$ = useVuelidate(
      updateUserPasswordFormRules,
      updateUserPasswordForm
    );

    return {
      v$,
      userData,
      columns,
      pagination,
      isLoadingUserCollaborators,
      isUpdatePasswordModalOpen,
      users,
      updateUserPasswordForm,
      updateUserPasswordFormRules,
      mapUserFormToupdateUserPasswordForm,
      handleUpdateCollaborator,
      handleUpdatePageProps,
      handleRemoveCollaborator,
    };
  },
});
</script>
