<template>
  <div>
    <MainSidebar v-if="shouldShowSidebar" />

    <div
      :class="[
        'main-content d-flex flex-column',
        { active: stateStoreInstance.open },
        { 'padding-left-0': !shouldShowPaddingLeftZero },
      ]"
    >
      <MainHeader v-if="shouldShowHeader" />

      <router-view />

      <div class="flex-grow-1"></div>
    </div>

    <SuccessSnackbar />

    <LoadingDialog />

    <AlertComponent
      v-if="alertStoreInstance.isVisible"
      style="position: fixed; right: 15px; bottom: 0px"
      :message="alertStoreInstance.message"
      :type="alertStoreInstance.type"
      @close-alert="
        () => (alertStoreInstance.isVisible = !alertStoreInstance.isVisible)
      "
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  watchEffect,
  onMounted,
  onBeforeUnmount,
} from "vue";

import { useRoute } from "vue-router";

import stateStore from "@/utils/store";
// import { startTokenExpirationWatcher } from "@/utils/tokenWatcher";

import { useAuthStore } from "@/stores/auth.store";
import { useAlertStore } from "@/stores/alert.store";

import { useAuth } from "./composables/useAuth";

import MainHeader from "@/components/Layouts/MainHeader.vue";
import MainSidebar from "@/components/Layouts/MainSidebar.vue";

import LoadingDialog from "@/components/Dialog/LoadingDialog.vue";
import SuccessSnackbar from "@/components/Snackbar/SuccessSnackbar.vue";

import AlertComponent from "@/components/Alerts/AlertComponent.vue";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    LoadingDialog,
    SuccessSnackbar,
    AlertComponent,
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const route = useRoute();

    const alertStoreInstance = useAlertStore();
    const authStore = useAuthStore();

    const stateStoreInstance = stateStore;

    const { refreshToken, logout } = useAuth();

    const hiddenRoutes = [
      // "/",
      "/error-500",
      "/coming-soon",
      "/autenticar/login",
      "/authentication/logout",
      "/authentication/register",
      "/authentication/signin-signup",
      "/authentication/forgot-password",
      "/authentication/reset-password",
      "/authentication/confirm-mail",
      "/authentication/lock-screen",
    ];

    const shouldShowSidebar = computed(
      () => !hiddenRoutes.includes(route.path) && authStore.getToken
    );
    const shouldShowHeader = computed(
      () => !hiddenRoutes.includes(route.path) && authStore.getToken
    );
    const shouldShowFooter = computed(
      () => !hiddenRoutes.includes(route.path) && authStore.getToken
    );
    const shouldShowPaddingLeftZero = computed(
      () => !hiddenRoutes.includes(route.path) && authStore.getToken
    );

    // Função para lidar com a expiração do token
    const handleTokenExpiration = async () => {
      try {
        console.info("refresh");
        const response = await refreshToken();

        if (response) {
          const newExpiresAt = authStore.getExpiresAt;
          if (newExpiresAt == null) return false;

          // startTokenExpirationWatcher();
        } else {
          const response = await logout();
          if (response) {
            // router.push({ name: "SignInPage" }).then(() => {
            //   router.go(0);
            // });
          }
        }
      } catch (error) {
        console.error("Erro ao renovar o token:", error);
      }
    };

    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });

    onMounted(() => {
      const isLoggedIn = authStore.getToken;

      if (isLoggedIn) {
        // const expiresAt = authStore.getExpiresAt;
        // if (expiresAt) {
        //   startTokenExpirationWatcher();
        // }
      }

      // Adiciona o listener do evento quando o componente é montado
      window.addEventListener("token-expiration-warning", () => {
        console.log("Evento de expiração do token recebido.");
        handleTokenExpiration();
      });
    });

    onBeforeUnmount(() => {
      // Remove o listener do evento quando o componente é desmontado
      window.removeEventListener("token-expiration-warning", () => {
        handleTokenExpiration();
      });
    });

    return {
      stateStoreInstance,
      alertStoreInstance,
      shouldShowSidebar,
      shouldShowHeader,
      shouldShowFooter,
      shouldShowPaddingLeftZero,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  overflow: hidden;
  min-height: 100vh;
  transition: var(--transition);

  padding: {
    left: 285px;
    right: 25px;
  }

  &.active {
    padding-left: 85px;
  }

  &.right-sidebar {
    padding: {
      left: 25px;
      right: 285px;
    }

    &.active {
      padding-right: 85px;
    }
  }

  &.hide-sidebar {
    padding-left: 85px;

    &.right-sidebar {
      padding: {
        left: 25px;
        right: 85px;
      }
    }
  }

  &.padding-left-0 {
    padding-left: 25px !important;
  }
}

.blank-page {
  app-sidebar,
  app-footer,
  app-header {
    display: none !important;
  }

  .main-content {
    padding: 0 !important;
  }
}

.rtl-enabled {
  .main-content {
    padding: {
      right: 285px;
      left: 25px;
    }

    &.active {
      padding: {
        left: 25px;
        right: 85px;
      }
    }

    &.right-sidebar {
      padding: {
        right: 25px;
        left: 285px;
      }

      &.active {
        padding: {
          right: 25px;
          left: 85px;
        }
      }
    }

    &.hide-sidebar {
      padding: {
        left: 25px;
        right: 85px;
      }

      &.right-sidebar {
        padding: {
          right: 25px;
          left: 85px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: {
      left: 15px;
      right: 15px;
    }

    &.active {
      padding-left: 15px;
    }

    &.right-sidebar {
      padding: {
        left: 15px;
        right: 15px;
      }

      &.active {
        padding-right: 15px;
      }
    }

    &.hide-sidebar {
      padding-left: 15px;

      &.right-sidebar {
        padding: {
          left: 15px;
          right: 15px;
        }
      }
    }

    &.padding-left-0 {
      padding-left: 15px !important;
    }
  }

  .rtl-enabled {
    .main-content {
      padding: {
        left: 15px;
        right: 15px;
      }

      &.active {
        padding-right: 15px;
      }

      &.right-sidebar {
        padding: {
          left: 15px;
          right: 15px;
        }

        &.active {
          padding-left: 15px;
        }
      }

      &.hide-sidebar {
        padding-right: 15px;

        &.right-sidebar {
          padding: {
            left: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-content {
    padding: {
      left: 25px;
      right: 25px;
    }

    &.active {
      padding-left: 25px;
    }

    &.right-sidebar {
      padding: {
        left: 25px;
        right: 25px;
      }

      &.active {
        padding-right: 25px;
      }
    }

    &.hide-sidebar {
      padding-left: 25px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }
  }

  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 25px;
      }

      &.active {
        padding-right: 25px;
      }

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }

        &.active {
          padding-left: 25px;
        }
      }

      &.hide-sidebar {
        padding-right: 25px;

        &.right-sidebar {
          padding: {
            left: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-content {
    padding: {
      left: 25px;
      right: 25px;
    }

    &.active {
      padding-left: 25px;
    }

    &.right-sidebar {
      padding: {
        left: 25px;
        right: 25px;
      }

      &.active {
        padding-right: 25px;
      }
    }

    &.hide-sidebar {
      padding-left: 25px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }
  }

  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 25px;
      }

      &.active {
        padding-right: 25px;
      }

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }

        &.active {
          padding-left: 25px;
        }
      }

      &.hide-sidebar {
        padding-right: 25px;

        &.right-sidebar {
          padding: {
            left: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-content {
    padding-left: 265px;

    &.active {
      padding-left: 85px;
    }

    &.right-sidebar {
      padding-right: 265px;

      &.active {
        padding-right: 85px;
      }
    }

    &.hide-sidebar {
      padding-left: 85px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
    }
  }

  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 265px;
      }

      &.active {
        padding: {
          left: 25px;
          right: 85px;
        }
      }

      &.right-sidebar {
        padding: {
          left: 265px;
          right: 25px;
        }

        &.active {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }

      &.hide-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }

        &.right-sidebar {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .main-content {
    padding: {
      left: 325px;
      right: 25px;
    }

    &.active {
      padding-left: 85px;
    }

    &.right-sidebar {
      padding: {
        left: 25px;
        right: 325px;
      }

      &.active {
        padding-right: 85px;
      }
    }

    &.hide-sidebar {
      padding-left: 85px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
    }
  }

  .rtl-enabled {
    .main-content {
      padding: {
        right: 325px;
        left: 25px;
      }

      &.active {
        padding: {
          left: 25px;
          right: 85px;
        }
      }

      &.right-sidebar {
        padding: {
          right: 25px;
          left: 325px;
        }

        &.active {
          padding: {
            left: 85px;
            right: 25px;
          }
        }
      }

      &.hide-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }

        &.right-sidebar {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
    }
  }
}
</style>
