import { reactive, ref } from "vue";

import EventBus from "@/utils/eventBus";

import { useBaseComposable } from "./useBaseComposable";

import { ProductService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { ProductResponse } from "@/types/response/product";
import type { CreateProductRequest } from "@/types/request/create/createProduct";
import type { UpdateProductRequest } from "@/types/request/update/updateProduct";

export function useProduct() {
  const { createValidationRules, required } = useBaseComposable();

  const product = ref<ProductResponse>();
  const products = ref<ProductResponse[]>([]);

  const columns = ref<object[]>([
    { title: "ID", align: "start", key: "itemId" },
    { title: "Nome", align: "center", key: "name" },
    { title: "Descrição", align: "center", key: "description" },
    { title: "Status", align: "center", key: "isActive" },
    { title: "Preço", align: "center", key: "price" },
    {
      title: "Ações",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: ProductResponse) => item,
    },
  ]);

  const pagination = ref({
    totalElements: 0,
    currentPage: 1,
    totalPerPage: 10,
    totalPages: 1,
  });

  const newProductFormRules = createValidationRules({
    name: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    description: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    price: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      // minValue: {
      //   validator: minValue(1),
      //   messageKey: "validation.minValue",
      //   params: { min: 1 },
      // },
    },
  });

  const newProductForm = reactive<CreateProductRequest>({
    name: "",
    description: "",
    price: "0",
  });

  const updateProductFormRules = createValidationRules({
    name: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    description: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    price: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
  });

  const updateProductForm = reactive<UpdateProductRequest>({
    name: "",
    description: "",
    price: "0",
    isActive: 1,
  });

  async function getAllProducts(currentPage: number, totalElements: number) {
    try {
      const response = await ProductService.GetAllProducts(
        currentPage,
        totalElements
      );

      if (response) {
        products.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getProductById(id: number) {
    try {
      const response = await ProductService.GetProductById(id);

      if (response) product.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createProduct(payload: CreateProductRequest) {
    try {
      const response = await ProductService.CreateProduct(payload);

      if (response) EventBus.emit("product-update");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateProduct(id: number, payload: UpdateProductRequest) {
    try {
      const response = await ProductService.UpdateProduct(id, payload);

      if (response) EventBus.emit("product-update");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteProductById(id: number) {
    try {
      const response = await ProductService.DeleteProductById(id);

      if (response) EventBus.emit("product-update");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    columns,
    pagination,
    product,
    products,
    newProductFormRules,
    newProductForm,
    updateProductFormRules,
    updateProductForm,
    getAllProducts,
    getProductById,
    createProduct,
    updateProduct,
    deleteProductById,
  };
}
