import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-card-header align-items-center border-bottom" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = { class: "fw-semibold gray-color d-block mb-8" }
const _hoisted_5 = { class: "gray-color d-block mb-8" }
const _hoisted_6 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_7 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_8 = {
  key: 2,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_9 = {
  key: 3,
  class: "text-capitalize badge rounded-pill text-soft-info"
}
const _hoisted_10 = {
  key: 4,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_11 = {
  key: 5,
  class: "text-capitalize badge rounded-pill text-soft-secondary"
}
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = { class: "gray-color d-block text-right" }
const _hoisted_14 = { class: "btn-box" }
const _hoisted_15 = {
  key: 0,
  class: "d-flex ga-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderSummary = _resolveComponent("OrderSummary")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_SelectInvoiceStatus = _resolveComponent("SelectInvoiceStatus")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-25 pingo-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "mb-0" }, "Detalhes de faturamento", -1)),
        _createElementVNode("h5", _hoisted_2, " Faturamento #" + _toDisplayString(_ctx.formatInvoiceId(_ctx.invoice.invoiceId)), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _cache[4] || (_cache[4] = _createElementVNode("h5", { class: "mb-15" }, "Faturado para:", -1)),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.invoice.user.name), 1),
          _cache[5] || (_cache[5] = _createElementVNode("h5", { class: "mb-15 mt-25" }, "Pagamento:", -1)),
          _createElementVNode("span", _hoisted_5, " Valor total da cobrança: " + _toDisplayString(_ctx.formatCurrency(_ctx.invoice.totalAmount)), 1),
          _cache[6] || (_cache[6] = _createElementVNode("h5", { class: "mb-15 mt-25" }, "Status:", -1)),
          (_ctx.invoice.status === _ctx.InvoiceStatus.Cancelled)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Cancelado "))
            : (_ctx.invoice.status === _ctx.InvoiceStatus.Pending)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Pendente "))
              : (_ctx.invoice.status === _ctx.InvoiceStatus.Paid)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Pago "))
                : (_ctx.invoice.status === _ctx.InvoiceStatus.PartiallyPaid)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Parcialmente Pago "))
                  : (_ctx.invoice.status === _ctx.InvoiceStatus.Overdue)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Vencido "))
                    : (_ctx.invoice.status === _ctx.InvoiceStatus.Refunded)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Reembolsado "))
                      : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "mb-15" }, "Faturado em:", -1)),
          _createElementVNode("span", _hoisted_13, _toDisplayString(new Date(_ctx.invoice.createdAt).toLocaleDateString()), 1)
        ])
      ]),
      _createVNode(_component_OrderSummary, {
        orders: _ctx.invoice.orders
      }, null, 8, ["orders"]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_router_link, {
          class: "mr-0 p-0",
          to: `/financeiro`,
          style: {"padding":"0px !important"}
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createElementVNode("button", { class: "gray" }, "Voltar", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_dialog, {
          width: "380",
          scrollable: "",
          modelValue: _ctx.isInvoiceStatusDialogOpen,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isInvoiceStatusDialogOpen) = $event))
        }, {
          activator: _withCtx(({ props: activatorProps }) => [
            (_ctx.user.type === 'ADMIN')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_ctx.updateInvoiceForm.status != _ctx.InvoiceStatus.Paid)
                    ? (_openBlock(), _createElementBlock("button", _mergeProps({
                        key: 0,
                        class: "success"
                      }, activatorProps, { type: "button" }), " Atualizar status ", 16))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "py-0",
              title: "Atualizar Status"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_divider),
                _createVNode(_component_v_card_text, { class: "px-4" }, {
                  default: _withCtx(() => [
                    _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mb-10" }, " Selecione o novo status para a fatura abaixo. Certifique-se de que a alteração seja apropriada para o estado atual da fatura. ", -1)),
                    _createVNode(_component_SelectInvoiceStatus, {
                      modelValue: _ctx.updateInvoiceForm.status,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updateInvoiceForm.status) = $event)),
                      "current-status": _ctx.updateInvoiceForm.status as InvoiceStatus,
                      allStatuses: _ctx.allStatuses,
                      "error-messages": 
                  _ctx.v$.status.$errors ? _ctx.v$.status.$errors[0]?.$message : undefined
                
                    }, null, 8, ["modelValue", "current-status", "allStatuses", "error-messages"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "text-capitalize",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isInvoiceStatusDialogOpen = false))
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Fechar ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      class: "text-capitalize",
                      color: "blue-darken-3",
                      variant: "tonal",
                      onClick: _ctx.updateInvoiceStatus
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" Atualizar ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }))
}