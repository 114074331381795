import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-space-between" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mr-2" }, "Itens por página:", -1)),
      _createVNode(_component_v_select, {
        density: "compact",
        modelValue: _ctx.pagination.size,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.size) = $event)),
          _ctx.updatePaginationSize
        ],
        "hide-details": true,
        "item-title": "state",
        variant: "outlined",
        items: [10, 25, 50, 100, 'Todos']
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, " Página " + _toDisplayString(_ctx.pagination.page) + " de " + _toDisplayString(_ctx.props.total_pages), 1),
    _createVNode(_component_v_pagination, {
      density: "compact",
      length: _ctx.props.total_pages,
      "total-visible": 3,
      modelValue: _ctx.pagination.page,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination.page) = $event)),
      onChange: _ctx.updateCurrentPage
    }, null, 8, ["length", "modelValue", "onChange"])
  ]))
}