import { computed, reactive, Ref, ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import { useDate } from "vuetify";

import { UserService } from "@/services";

import { isLoading } from "@/shared/base.service";

import { useBaseComposable } from "./useBaseComposable";

import type { UserResponse } from "@/types/response/user";
import type { CreateUserRequest } from "@/types/request/create/createUser";
import type { UpdateUserRequest } from "@/types/request/update/updateUser";

export function useUser() {
  const loggedUser: Ref = ref(useAuthStore().getUser);

  const date = useDate();

  const {
    createValidationRules,
    required,
    email,
    minLength,
    sameAs,
    requiredIf,
  } = useBaseComposable();

  const user = ref<UserResponse>();
  const users = ref<UserResponse[]>([]);

  function billingDayPattern(value: string) {
    // Verifica se o valor corresponde ao padrão "números separados por vírgulas"
    const pattern = /^(\d{1,2}(,\s*\d{1,2})*)?$/;
    return pattern.test(value);
  }

  const columns = ref<object[]>([
    { title: "ID", align: "start", key: "userId" },
    { title: "Nome", align: "center", key: "name" },
    { title: "Email", align: "center", key: "email" },
    {
      title: "Criado Em",
      align: "center",
      key: "created_at",
      value: (item: UserResponse) =>
        date.format(item?.createdAt, "keyboardDateTime"),
    },
    // {
    //   title: "Atualizado Em",
    //   align: "center",
    //   key: "updatedAt",
    //   value: (item: UserResponse) =>
    //     date.format(item?.updatedAt, "keyboardDateTime"),
    // },
    { title: "Status", align: "center", key: "isActive" },
    {
      title: "Ações",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: UserResponse) => item,
    },
  ]);

  const pagination = ref({
    totalElements: 0,
    currentPage: 1,
    totalPerPage: 10,
    totalPages: 1,
  });

  const newUserFormRules = createValidationRules({
    type: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    name: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(3),
        messageKey: "validation.minLength",
        params: { min: 3 },
      },
    },
    email: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      email: {
        validator: email,
        messageKey: "validation.email",
      },
    },
    document: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(11), // Exemplo para CPF/CNPJ
        messageKey: "validation.minLength",
        params: { min: 11 },
      },
    },
    phone: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(10), // Número mínimo de dígitos para telefone
        messageKey: "validation.minLength",
        params: { min: 10 },
      },
    },
    password: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(6),
        messageKey: "validation.minLength",
        params: { min: 6 },
      },
    },
    confirmationPassword: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(6),
        messageKey: "validation.minLength",
        params: { min: 6 },
      },
      sameAsPassword: {
        validator: sameAs(computed(() => newUserForm.password)),
        messageKey: "validation.sameAsPassword",
      },
    },
  });

  const newUserForm = reactive<CreateUserRequest>({
    userParentId: 0,
    type: "ADMIN",
    name: "",
    email: "",
    document: "",
    phone: "",
    password: "",
    confirmationPassword: "",
  });

  const updateUserFormRules = createValidationRules({
    type: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    name: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(3),
        messageKey: "validation.minLength",
        params: { min: 3 },
      },
    },
    email: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      email: {
        validator: email,
        messageKey: "validation.email",
      },
    },
    password: {
      // required: {
      //   validator: required,
      //   messageKey: "validation.required",
      // },
      minLength: {
        validator: minLength(8),
        messageKey: "validation.minLength",
        params: { min: 8 },
      },
    },
    confirmationPassword: {
      sameAsPassword: {
        validator: sameAs(computed(() => updateUserForm.password)),
        messageKey: "validation.sameAsPassword",
      },
      minLength: {
        validator: minLength(8),
        messageKey: "validation.minLength",
        params: { min: 8 },
      },
    },
    billingDay: {
      required: {
        validator: requiredIf(() => loggedUser.value.type === "ADMIN"),
        messageKey: "validation.required",
      },
      // minValue: {
      //   validator: minValue(1),
      //   messageKey: "validation.minValue",
      //   params: { min: 1 },
      // },
      pattern: {
        validator: billingDayPattern,
        messageKey:
          "O campo 'Dia de Cobrança' deve conter apenas números separados por vírgulas, por exemplo, '10, 20, 30'",
      },
    },
  });

  const updateUserForm = reactive<UpdateUserRequest>({
    userParentId: 0,
    type: "ADMIN",
    name: "",
    email: "",
    document: "",
    phone: "",
    password: "",
    confirmationPassword: "",
    billingDay: "10",
    isActive: false,
  });

  const updateUserPasswordFormRules = createValidationRules({
    password: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(8),
        messageKey: "validation.minLength",
        params: { min: 8 },
      },
    },
    confirmationPassword: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      sameAsPassword: {
        validator: sameAs(computed(() => updateUserForm.password)),
        messageKey: "validation.sameAsPassword",
      },
      minLength: {
        validator: minLength(8),
        messageKey: "validation.minLength",
        params: { min: 8 },
      },
    },
  });

  const updateUserPasswordForm = reactive<UpdateUserRequest>({
    userParentId: 0,
    type: "ADMIN",
    name: "",
    email: "",
    document: "",
    phone: "",
    password: "",
    confirmationPassword: "",
    billingDay: "10",
  });

  async function getAllUsers(
    currentPage: number,
    totalElements: number,
    type: "ADMIN" | "PARTNER" | "BUSINESS" | "COLLABORATOR" | "BENEFICIARY"
  ) {
    try {
      const response = await UserService.GetAllUsers(
        currentPage,
        totalElements,
        type
      );

      if (response) users.value = response.data;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllCollaborators(
    currentPage: number,
    totalElements: number
  ) {
    try {
      const response = await UserService.GetAllCollaborators(
        currentPage,
        totalElements
        // user_id
      );

      if (response) {
        users.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllBusinesses(currentPage: number, totalElements: number) {
    try {
      const response = await UserService.GetAllBusinesses(
        currentPage,
        totalElements
      );

      if (response) {
        users.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllBeneficiaries(
    currentPage: number,
    totalElements: number
  ) {
    try {
      const response = await UserService.GetAllBeneficiaries(
        currentPage,
        totalElements
      );

      if (response) {
        users.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserById(user_id: number) {
    try {
      const response = await UserService.GetUserById(user_id);

      if (response) user.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCollaboratorById(collaborator_id: number) {
    try {
      const response = await UserService.GetCollaboratorById(collaborator_id);

      if (response) user.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createUser(payload: CreateUserRequest) {
    try {
      const response = await UserService.CreateUser(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateUser(user_id: number, payload: UpdateUserRequest) {
    try {
      const response = await UserService.UpdateUser(user_id, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteUserById(user_id: number) {
    try {
      const response = await UserService.DeleteUserById(user_id);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    columns,
    pagination,
    user,
    users,
    newUserFormRules,
    newUserForm,
    updateUserFormRules,
    updateUserForm,
    updateUserPasswordFormRules,
    updateUserPasswordForm,
    getAllUsers,
    getAllCollaborators,
    getAllBusinesses,
    getAllBeneficiaries,
    getUserById,
    getCollaboratorById,
    createUser,
    updateUser,
    deleteUserById,
  };
}
