import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { class: "text-start fw-semibold" }
const _hoisted_4 = { class: "gray-color" }
const _hoisted_5 = { class: "gray-color" }
const _hoisted_6 = { class: "gray-color" }
const _hoisted_7 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_8 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_9 = {
  key: 2,
  class: "text-capitalize badge rounded-pill text-soft-info"
}
const _hoisted_10 = {
  key: 3,
  class: "text-capitalize badge rounded-pill text-soft-primary"
}
const _hoisted_11 = {
  key: 4,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_12 = { class: "gray-color text-end" }
const _hoisted_13 = { class: "gray-color fw-semibold text-end" }
const _hoisted_14 = { class: "total" }
const _hoisted_15 = { class: "fw-semibold text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "mb-20 mt-25" }, "Sumário de Pedidos", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[8] || (_cache[8] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "fw-semibold gray-color text-start" }, "Pedido N.º"),
            _createElementVNode("th", { class: "fw-semibold gray-color" }, "Criado Em"),
            _createElementVNode("th", { class: "fw-semibold gray-color" }, "Última Atualização Em"),
            _createElementVNode("th", { class: "fw-semibold gray-color" }, "Status"),
            _createElementVNode("th", { class: "fw-semibold gray-color text-end" }, "Valor")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: order.orderId
            }, [
              _createElementVNode("td", _hoisted_3, [
                _createVNode(_component_router_link, {
                  title: "Clique para ir para detalhes do pedido",
                  to: `/pedidos/${order.orderId}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Order #" + _toDisplayString(order.orderId) + " ", 1),
                    _createVNode(_component_v_icon, {
                      icon: "mdi-open-in-new",
                      size: "x-small"
                    })
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _createElementVNode("td", _hoisted_4, _toDisplayString(new Date(order.createdAt).toLocaleDateString()), 1),
              _createElementVNode("td", _hoisted_5, _toDisplayString(new Date(order.updatedAt).toLocaleDateString()), 1),
              _createElementVNode("td", _hoisted_6, [
                (order.status === 'cancelled')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Cancelado "))
                  : (order.status === 'pending')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Pendente "))
                    : (order.status === 'confirmed')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Confirmado "))
                      : (order.status === 'shipped')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Enviado "))
                        : (order.status === 'delivered')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Entregue "))
                          : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.formatCurrency(order.totalAmount)), 1)
            ]))
          }), 128)),
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
            _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "gray-color fw-semibold" }, "Subtotal:", -1)),
            _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.formatCurrency(_ctx.subtotal)), 1)
          ]),
          _createElementVNode("tr", _hoisted_14, [
            _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1)),
            _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
            _cache[7] || (_cache[7] = _createElementVNode("td", { class: "fw-semibold" }, "Total:", -1)),
            _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.formatCurrency(_ctx.subtotal)), 1)
          ])
        ])
      ])
    ])
  ], 64))
}