<template>
  <PageTitle pageTitle="Novo Pedido em Massa" mainTitle="Pedido" />

  <v-form>
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações do Pedido em Massa</h5>

            <div class="d-flex justify-space-between ga-2">
              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ props }">
                  <v-btn
                    class="text-capitalize"
                    color="blue-darken-3"
                    variant="tonal"
                    v-bind="props"
                  >
                    Adicionar
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="pingo-form-group without-icon mb-0">
                          <label class="d-block mb-12 fw-semibold gray-color">
                            Selecione um colaborador
                            <span>*</span>
                          </label>

                          <AutoCompleteCollaborators
                            v-model="editedItem.user"
                            @update:value="(value: UserResponse) => editedItem.user = value"
                            :required="false"
                          />
                        </div>
                      </v-col>

                      <v-col cols="12" md="12">
                        <div class="pingo-form-group without-icon">
                          <label class="d-block mb-12 fw-semibold gray-color">
                            Agendado para
                          </label>

                          <TextInput
                            v-model="editedItem.dateReference"
                            :type="'date'"
                            :label="'Digite aqui'"
                            :required="false"
                          />
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <div class="d-flex align-items-center justify-space-between">
                          <label class="d-block mb-0 fw-semibold gray-color">
                            Produtos
                          </label>

                          <v-btn
                            class="text-capitalize"
                            color="blue-darken-3"
                            variant="tonal"
                            @click="addItem"
                          >
                            Adicionar
                          </v-btn>
                        </div>

                        <v-expansion-panels v-if="editedItem.items.length > 0">
                          <v-expansion-panel
                            class="mt-4"
                            v-for="(item, index) in editedItem.items"
                            :key="index"
                          >
                            <v-expansion-panel-title>
                              {{ "Produto #" + (index + 1) }}

                              <template v-slot:actions="{ expanded }">
                                <div class="d-flex ga-3">
                                  <span v-if="item.item">
                                    {{
                                      new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(item.item?.price * item.quantity)
                                    }}
                                  </span>

                                  <v-icon
                                    :color="!expanded ? 'teal' : ''"
                                    :icon="expanded ? 'mdi-minus' : 'mdi-plus'"
                                  ></v-icon>

                                  <v-icon color="red" @click="deleteProductItem(index)">
                                    mdi-delete
                                  </v-icon>
                                </div>
                              </template>
                            </v-expansion-panel-title>

                            <v-expansion-panel-text>
                              <v-col cols="12">
                                <div class="pingo-form-group without-icon mb-0">
                                  <label class="d-block mb-12 fw-semibold gray-color">
                                    Selecione um produto
                                    <span>*</span>
                                  </label>

                                  <AutoCompleteProducts
                                    v-model="item.item"
                                    @update:value="(value: ProductResponse) => item.item = value"
                                    :required="false"
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12">
                                <div class="pingo-form-group without-icon">
                                  <label class="d-block mb-12 fw-semibold gray-color">
                                    Quantidade
                                    <span>*</span>
                                  </label>

                                  <TextInput
                                    v-model="item.quantity"
                                    type="number"
                                    :label="'Digite aqui'"
                                    :required="false"
                                  />
                                </div>
                              </v-col>

                              <v-col cols="12">
                                <div class="pingo-form-group without-icon">
                                  <label class="d-block mb-12 fw-semibold gray-color">
                                    Observação
                                  </label>

                                  <TextInput
                                    v-model="item.observation"
                                    :label="'Digite aqui'"
                                    :required="false"
                                  />
                                </div>
                              </v-col>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- Exibir mensagem caso não haja nenhum item -->
                        <div v-if="editedItem.items.length === 0" border="left">
                          Não há nenhum produto adicionado.
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-alert v-if="error" type="error">
                      {{ error }}
                    </v-alert>

                    <v-btn color="blue-darken-1" variant="text" @click="close">
                      Cancelar
                    </v-btn>

                    <v-btn color="blue-darken-1" variant="text" @click="save">
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                class="text-capitalize"
                color="green-darken-3"
                variant="tonal"
                @click="handleSubmit()"
                :disabled="isLoadingOrderRequest || rows.length === 0"
              >
                <v-progress-circular
                  v-if="isLoadingOrderRequest"
                  indeterminate
                  size="18"
                  width="2"
                ></v-progress-circular>

                Finalizar
              </v-btn>
            </div>
          </div>

          <v-data-table
            class="table"
            v-model:expanded="expanded"
            :headers="headers"
            :items="rows"
            item-value="userId"
            hide-default-footer
          >
            <template v-slot:[`item.id`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.collaborator`]="{ value }">
              {{ value ?? "-" }}
            </template>

            <template v-slot:[`item.dateReference`]="{ value }">
              {{ formatDate(value) }}
            </template>

            <template v-slot:[`item.items`]="{ item }">
              {{ item.items.map((i) => i.item?.name).join(", ") }}
            </template>

            <template v-slot:[`item.totalAmount`]="{ value }">
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(value)
              }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-between ga-2" style="max-width: 150px">
                <v-btn
                  class="text-capitalize"
                  color="primary-darken-3"
                  variant="tonal"
                  @click="editItem(item)"
                >
                  Editar
                </v-btn>

                <v-btn
                  class="text-capitalize"
                  color="red-darken-3"
                  variant="tonal"
                  @click="deleteItem(item)"
                >
                  Remover
                </v-btn>
              </div>
            </template>

            <template v-slot:no-data> Não há nenhum pedido </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref, computed, nextTick, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useOrder } from "@/composables/useOrder";
import { useUser } from "@/composables/useUser";
import { useProduct } from "@/composables/useProduct";
import PageTitle from "@/components/Common/PageTitle.vue";
import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import AutoCompleteCollaborators from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteCollaborators.vue";
import AutoCompleteProducts from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteProducts.vue";
import type { UserResponse } from "@/types/response/user";
import type { ProductResponse } from "@/types/response/product";

interface Item {
  item?: ProductResponse;
  itemId: number;
  quantity: number;
  observation: string;
}

interface Row {
  user?: UserResponse;
  userId: number;
  totalAmount: number;
  dateReference: string;
  items: Item[];
}

export default defineComponent({
  name: "CreateOrderBatchPage",
  components: {
    PageTitle,
    TextInput,
    AutoCompleteCollaborators,
    AutoCompleteProducts,
  },
  setup() {
    const router = useRouter();
    const { createOrderBatch } = useOrder();
    const { users, getAllCollaborators } = useUser();
    const { product, getProductById } = useProduct();

    const dialog = ref(false);
    const expanded = ref<string[]>([]);
    const editedIndex = ref(-1);
    const editedItem = ref<Row>({
      userId: 0,
      totalAmount: 0,
      dateReference: new Date().toISOString().substr(0, 10),
      items: [],
    });
    const defaultItem = ref<Row>({
      user: {
        userId: 0,
        email: "",
        createdAt: "",
        updatedAt: "",
        name: "",
        document: "",
        phone: "",
        billingDay: "10",
      },
      userId: 0,
      totalAmount: 0,
      dateReference: new Date().toISOString().substr(0, 10),
      items: [],
    });

    const headers = ref([
      {
        title: "ID",
        key: "id",
      },
      {
        title: "Colaborador",
        key: "user.name",
      },
      { title: "Agendado para", key: "dateReference" },
      {
        title: "Produtos",
        key: "items",
        value: (item: Row) => item,
      },
      { title: "Total", key: "totalAmount" },
      { title: "Ações", key: "actions" },
    ]);

    const rows = ref<Row[]>([]);
    const error = ref<string | null>(null); // Para rastrear mensagens de erro

    const isLoadingOrderRequest = ref(false);
    const isLoadingUserCollaborators = ref(false);
    const isLoadingProduct = ref(false);

    const formatDate = (dateString: string): string => {
      const date = new Date(dateString + "T00:00:00");
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("pt-BR", options);
    };

    const calculateTotalAmount = (items: Item[]) => {
      if (!items || !Array.isArray(items)) {
        return 0;
      }

      const total = items.reduce((sum, item) => {
        const price = item.item?.price ?? 0;
        const quantity = item.quantity ?? 0;
        return sum + quantity * price;
      }, 0);

      return total;
    };

    const formTitle = computed(() =>
      editedIndex.value === -1 ? "Novo Pedido" : "Editar Pedido"
    );

    const fetchUserCollaboratorsData = async () => {
      try {
        isLoadingUserCollaborators.value = true;
        await getAllCollaborators(1, 1000);
        isLoadingUserCollaborators.value = false;
      } catch (error) {
        console.error("Erro ao buscar dados de colaboradores:", error);
      }
    };

    const fetchProductData = async () => {
      try {
        isLoadingProduct.value = true;
        await getProductById(1);
        isLoadingProduct.value = false;
      } catch (error) {
        console.error("Erro ao buscar dados de produto:", error);
      }
    };

    const initialize = () => {
      rows.value = [];
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deepClone = (obj: any) => {
      return JSON.parse(JSON.stringify(obj));
    };

    const editItem = (item: Row) => {
      editedIndex.value = rows.value.indexOf(item);
      editedItem.value = deepClone(item);
      dialog.value = true;
    };

    const deleteItem = (item: Row) => {
      const index = rows.value.indexOf(item);
      if (index > -1) {
        rows.value.splice(index, 1);
      }
    };

    const deleteProductItem = (index: number) => {
      // Remove o item da lista de itens com base no índice
      editedItem.value.items.splice(index, 1);
    };

    const close = () => {
      dialog.value = false;
      nextTick(() => {
        editedItem.value = deepClone(defaultItem.value);
        editedIndex.value = -1;
        error.value = null;
      });
    };

    const save = () => {
      if (!editedItem.value.user) {
        error.value = "Por favor, selecione um colaborador.";
        return;
      }

      if (!editedItem.value.dateReference) {
        error.value = "Por favor, selecione uma data.";
        return;
      }

      if (editedItem.value.items.length === 0) {
        error.value = "Por favor, adicione pelo menos um produto.";
        return;
      }

      for (const item of editedItem.value.items) {
        if (!item.item) {
          error.value = "Por favor, selecione um produto.";
          return;
        }

        if (item.quantity <= 0) {
          error.value = "A quantidade deve ser maior que zero.";
          return;
        }
      }

      editedItem.value.totalAmount = calculateTotalAmount(editedItem.value.items);

      if (editedIndex.value > -1) {
        Object.assign(rows.value[editedIndex.value], editedItem.value);
      } else {
        rows.value.push(deepClone(editedItem.value));
      }
      close();
    };

    const handleSubmit = async () => {
      const missingItemsIndex = rows.value.findIndex(
        (row) => !row.items || row.items.length === 0
      );
      if (missingItemsIndex !== -1) {
        console.log(`Row at index ${missingItemsIndex} is missing items.`);
        return;
      }

      // Criando o payload para o batch de pedidos
      const orderBatchPayload = rows.value.map((row) => ({
        userId: row.user?.userId || 0,
        totalAmount: row.totalAmount || 0,
        dateReference: row.dateReference || new Date().toISOString().substr(0, 10),
        items: row.items.map((item) => ({
          itemId: item.item?.itemId || 0,
          quantity: item.quantity || 0,
          observation: item.observation || "",
        })),
      }));

      isLoadingOrderRequest.value = true;

      try {
        const response = await createOrderBatch(orderBatchPayload);
        if (response) {
          router.go(-1);
        }
      } catch (error) {
        console.error("Error creating order batch:", error);
      } finally {
        isLoadingOrderRequest.value = false;
      }
    };

    const addItem = () => {
      editedItem.value.items.push({
        itemId: 0,
        quantity: 1,
        observation: "",
      });
    };

    onMounted(async () => {
      await fetchUserCollaboratorsData();
      await fetchProductData();

      // Adicionar registros para cada colaborador na tabela rows
      users.value.forEach((user: UserResponse) => {
        const productData = product.value
          ? {
              itemId: product.value.itemId,
              name: product.value.name,
              description: product.value.description,
              price: product.value.price,
              isActive: product.value.isActive,
            }
          : null;

        const items = productData
          ? [
              {
                item: productData,
                itemId: 1,
                quantity: 1,
                observation: "",
              },
            ]
          : [];

        rows.value.push({
          user,
          userId: user.userId,
          totalAmount: items.length > 0 ? 16.0 : 0, // Definir totalAmount com base na existência de itens
          dateReference: new Date().toISOString().substr(0, 10),
          items,
        });
      });
    });

    return {
      dialog,
      expanded,
      headers,
      rows,
      formatDate,
      editedIndex,
      editedItem,
      defaultItem,
      formTitle,
      initialize,
      editItem,
      deleteItem,
      deleteProductItem,
      close,
      save,
      addItem,
      error,
      isLoadingOrderRequest,
      handleSubmit,
      users,
    };
  },
});
</script>

<style lang="scss" scoped>
.pingo-form-group {
  margin-bottom: 0px;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid var(--whiteColor);
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--whiteColor);
  }
}
table,
.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        background-color: #f7faff;
        vertical-align: middle;
        white-space: nowrap;
        text-align: center;
        position: relative;
        padding: 18px 20px;

        &::after {
          top: 50%;
          right: 0;
          width: 1px;
          content: "";
          height: 17px;
          position: absolute;
          background-color: #e7ebf5;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        padding: 18px 20px;
        white-space: nowrap;
        border-bottom-color: #f7faff;
        border-bottom: 1px solid #f7faff;

        .product-info {
          padding-right: 25px;

          h5 {
            font-size: 15px;
          }
          span {
            font-size: 14px;
          }
        }
        .rating {
          i {
            line-height: 1;
            color: #ffbc2b;
            margin: {
              left: 2px;
              right: 2px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  table,
  .table {
    thead {
      tr {
        th {
          padding: 15px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px;

          .product-info {
            padding-right: 50px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  table,
  .table {
    thead {
      tr {
        th {
          padding: 15px 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 20px;
        }
      }
    }
  }
}

.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
