<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex justify-center align-items-center mb-10">
              <v-img src="@/assets/img/logo.svg" alt="logo" width="100" />
            </div>
          </div>

          <div class="d-flex align-center justify-center mb-15 ga-2">
            <p class="mb-0">Já possui uma conta?</p>
            <router-link
              to="/autenticar/acessar"
              class="d-inline-block main-color w-auto fw-medium"
            >
              Faça o login aqui!
            </router-link>
          </div>

          <form @submit.prevent="handleSubmit()">
            <div class="bg-white border-radius p-20">
              <v-row>
                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Nome <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite o seu nome'"
                      :type="'text'"
                      v-model="signUpForm.name"
                      :error-messages="
                        v$.name.$errors
                          ? v$.name.$errors[0]?.$message
                          : undefined
                      "
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Email <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite o seu email'"
                      :type="'email'"
                      v-model="signUpForm.email"
                      :error-messages="
                        v$.email.$errors
                          ? v$.email.$errors[0]?.$message
                          : undefined
                      "
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Documento <span>*</span>
                    </label>

                    <CpfInput
                      v-if="signUpForm.type == 'USER'"
                      :label="'Digite o seu documento'"
                      v-model="signUpForm.document"
                      :required="true"
                      @update:value="(value: string) => signUpForm.document = value.replaceAll('.','').replace('-','')"
                      :error-messages="
                        v$.document.$errors
                          ? v$.document.$errors[0]?.$message
                          : undefined
                      "
                    />

                    <CnpjInput
                      v-if="signUpForm.type == 'BUSINESS'"
                      :label="'Digite o seu documento'"
                      v-model="signUpForm.document"
                      :required="true"
                      @update:value="(value: string) => signUpForm.document = value.replaceAll('.','').replace('-','')"
                      :error-messages="
                        v$.document.$errors
                          ? v$.document.$errors[0]?.$message
                          : undefined
                      "
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Celular <span>*</span>
                    </label>

                    <PhoneInput
                      :label="'Digite o seu celular'"
                      :type="'tel'"
                      v-model="signUpForm.phone"
                      :error-messages="
                        v$.phone.$errors
                          ? v$.phone.$errors[0]?.$message
                          : undefined
                      "
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Senha
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite a sua senha'"
                      :type="'password'"
                      v-model="signUpForm.password"
                      :error-messages="
                        v$.password.$errors
                          ? v$.password.$errors[0]?.$message
                          : undefined
                      "
                    />
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6">
                  <div class="pingo-form-group without-icon mb-0">
                    <label class="d-block mb-12 fw-semibold gray-color">
                      Confirme a sua senha
                      <span>*</span>
                    </label>

                    <TextInput
                      :label="'Digite a sua senha novamente'"
                      :type="'password'"
                      v-model="signUpForm.confirmationPassword"
                      :error-messages="
                        v$.confirmationPassword.$errors
                          ? v$.confirmationPassword.$errors[0]?.$message
                          : undefined
                      "
                    />
                  </div>
                </v-col>
              </v-row>
            </div>

            <PrimaryButton :is-loading="isLoading" label="Criar conta" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuth } from "@/composables/useAuth";

import PrimaryButton from "@/components/Forms/BasicElements/Button/PrimaryButton.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import CpfInput from "@/components/Forms/AdvancedElements/Input/CpfInput.vue";
import CnpjInput from "@/components/Forms/AdvancedElements/Input/CnpjInput.vue";
import PhoneInput from "@/components/Forms/AdvancedElements/Input/PhoneInput.vue";

export default defineComponent({
  name: "SignUpPage",
  components: {
    TextInput,
    PrimaryButton,
    CpfInput,
    CnpjInput,
    PhoneInput,
  },
  setup() {
    const router = useRouter();

    const { signUpFormRules, signUpForm, signUp, isLoading } = useAuth();

    const handleSubmit = async () => {
      try {
        const result = await v$.value.$validate();

        if (!result) return;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { confirmationPassword, ...signUpPayload } = signUpForm;

        const response = await signUp(signUpPayload);

        if (response) {
          router.push({ name: "SignInPage" });
        }
      } catch (e) {
        console.error("Ocorreu um erro ao conectar-se.");
      }
    };

    const v$ = useVuelidate(signUpFormRules, signUpForm);

    return {
      signUpForm,
      isLoading,
      handleSubmit,
      v$,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-selection-control {
  .v-label {
    white-space: nowrap !important;
  }
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }

  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}

.pingo-form {
  max-width: 720px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;

      a {
        font-size: 18px !important;
      }
    }
  }

  .login-with-socials {
    margin-bottom: 25px;

    button {
      margin-top: 0;
      font-size: 15px !important;
      padding: 17px 25px 17px 60px !important;
      position: relative;
      border-radius: 5px !important;
      width: 100%;

      .v-img {
        top: 52%;
        position: absolute;
        transform: translateY(-52%);

        margin: {
          left: -25px;
        }
      }

      &.gray {
        border: 1px solid #edeff5 !important;
        background-color: var(--whiteColor) !important;
      }

      &.success {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
  }

  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;

      padding: {
        left: 15px;
        right: 15px;
      }
    }

    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: "";
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }

  button {
    margin-top: 15px;
    padding: 17px 35px !important;
    width: 100%;

    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }

  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;

    padding: {
      left: 15px;
      right: 15px;
    }

    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
