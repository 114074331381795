<template>
  <v-select
    label="Selecione um status"
    item-title="text"
    item-value="value"
    :items="statusOptions"
    @update:model-value="(value) => $emit('update:value', value)"
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType } from "vue";

import { OrderStatus } from "@/types/enum/orderStatus.enum";

export default defineComponent({
  name: "SelectOrderStatus",
  props: {
    currentStatus: {
      type: String as PropType<OrderStatus>,
      required: true,
    },
    allStatuses: {
      type: Array as PropType<OrderStatus[]>,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const selectedStatus = ref<OrderStatus>(props.currentStatus);

    const mapStatusTitle = (status: OrderStatus) => {
      switch (status) {
        case OrderStatus.Pending:
          return "Pendente";
        case OrderStatus.Confirmed:
          return "Confirmado";
        case OrderStatus.Shipped:
          return "Enviado";
        case OrderStatus.Delivered:
          return "Entregue";
        case OrderStatus.Cancelled:
          return "Cancelado";
        default:
          return status;
      }
    };

    const statusOptions = props.allStatuses.map((status) => ({
      text: mapStatusTitle(status),
      value: status,
    }));

    watch(
      () => props.currentStatus,
      (newStatus) => {
        selectedStatus.value = newStatus;
      }
    );

    return {
      selectedStatus,
      statusOptions,
    };
  },
});
</script>
