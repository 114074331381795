import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/img/admin.jpg'


const _hoisted_1 = { class: "ps-0 mt-0" }
const _hoisted_2 = { class: "row mt-20" }
const _hoisted_3 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_4 = { class: "pingo-form-group" }
const _hoisted_5 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_6 = { class: "pingo-form-group" }
const _hoisted_7 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_8 = { class: "pingo-form-group" }
const _hoisted_9 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_10 = { class: "pingo-form-group" }
const _hoisted_11 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_12 = { class: "pingo-form-group" }
const _hoisted_13 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_14 = { class: "pingo-form-group" }
const _hoisted_15 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_16 = { class: "pingo-form-group" }
const _hoisted_17 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_18 = { class: "pingo-form-group" }
const _hoisted_19 = { class: "d-flex align-items-center" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-lg-4 col-md-6 col-sm-6" }
const _hoisted_22 = { class: "pingo-form-group" }
const _hoisted_23 = { class: "col-lg-4 col-md-6 col-sm-6" }
const _hoisted_24 = { class: "pingo-form-group" }
const _hoisted_25 = { class: "col-lg-4 col-md-6 col-sm-6" }
const _hoisted_26 = { class: "pingo-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-25 pingo-card" }, {
    default: _withCtx(() => [
      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
        _createElementVNode("h5", { class: "mb-0" }, "Configurações")
      ], -1)),
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/enderecos" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Endereços ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/politica-de-privacidade" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Política de Privacidade ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/termos-e-condicoes" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Termos e Condições ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("form", null, [
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "mb-20" }, [
          _createElementVNode("h5", { class: "fw-semibold mb-8" }, "Conta"),
          _createElementVNode("p", null, "Atualize sua foto e dados pessoais aqui.")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " First Name ", -1)),
              _createVNode(_component_v_text_field, { value: "Andrew" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "ri-user-3-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Last Name ", -1)),
              _createVNode(_component_v_text_field, { value: "Bruns" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "ri-user-3-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Email Address ", -1)),
              _createVNode(_component_v_text_field, {
                type: "email",
                value: "hello@pingo.com"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "ri-mail-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, "Phone", -1)),
              _createVNode(_component_v_text_field, {
                type: "phone",
                value: "+(555) 555-1234"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("i", { class: "ri-phone-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, "Live in", -1)),
              _createVNode(_component_v_text_field, {
                type: "text",
                value: "Zuichi, Switzerland"
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("i", { class: "ri-home-7-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Street Address ", -1)),
              _createVNode(_component_v_text_field, {
                type: "text",
                value: "2445 Crosswind Drive"
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createElementVNode("i", { class: "ri-home-7-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _cache[16] || (_cache[16] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Date Of Birth ", -1)),
              _createVNode(_component_v_text_field, { value: "20 March 1999" }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createElementVNode("i", { class: "ri-cake-2-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, "Gender", -1)),
              _createVNode(_component_v_text_field, { value: "Male" }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createElementVNode("i", { class: "ri-men-line" }, null, -1)
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _cache[27] || (_cache[27] = _createElementVNode("h5", { class: "fw-semibold mb-8 mt-5" }, "Your Photo", -1)),
        _cache[28] || (_cache[28] = _createElementVNode("p", null, "This will be displayed on your profile.", -1)),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_v_img, {
            src: _imports_0,
            class: "rounded-circle",
            width: "110",
            alt: "user"
          }),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "pingo-form-group ms-20" }, [
            _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Upload Image "),
            _createElementVNode("input", { type: "file" })
          ], -1))
        ]),
        _cache[29] || (_cache[29] = _createElementVNode("h5", { class: "fw-semibold mb-20 mt-25" }, "Socials Profile", -1)),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, "Facebook", -1)),
              _createVNode(_component_v_text_field, { value: "https://www.facebook.com/" }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createElementVNode("i", { class: "ri-facebook-fill" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _cache[23] || (_cache[23] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, "Twitter", -1)),
              _createVNode(_component_v_text_field, { value: "https://twitter.com/" }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createElementVNode("i", { class: "ri-twitter-fill" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _cache[25] || (_cache[25] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, "Linkedin", -1)),
              _createVNode(_component_v_text_field, { value: "https://linkedin.com/" }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createElementVNode("i", { class: "ri-linkedin-fill" }, null, -1)
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _cache[30] || (_cache[30] = _createElementVNode("button", { class: "success fw-semibold" }, "Update", -1))
      ])
    ]),
    _: 1
  }))
}