import { createI18n, useI18n } from "vue-i18n";
import { pt, en } from "vuetify/locale";

import enLocale from "./i18n/locales/en.json";
import ptbrLocale from "./i18n/locales/ptbr.json";

import { helpers } from "@vuelidate/validators";

const messages = {
  en: {
    $vuetify: {
      ...en,
      dataIterator: {
        rowsPerPageText: "Items per page:",
        pageText: "{0}-{1} of {2}",
      },
    },
    ...enLocale,
  },
  pt: {
    $vuetify: {
      ...pt,
      dataIterator: {
        rowsPerPageText: "Itens por página:",
        pageText: "{0}-{1} de {2}",
      },
    },
    ...ptbrLocale,
  },
};

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: "pt",
  fallbackLocale: "en",
  messages,
});

// Função auxiliar para adicionar mensagens de validação internacionalizadas
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withI18nMessage = (rule: any, messageKey: any, params?: any) => {
  const { t } = useI18n(); // Obtém a função `t` do i18n
  return helpers.withMessage(() => t(messageKey, params), rule);
};

export default i18n;
