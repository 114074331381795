<template>
  <div class="d-flex align-items-center justify-space-between">
    <div class="d-flex align-items-center">
      <div class="mr-2">Itens por página:</div>
      <v-select
        density="compact"
        v-model="pagination.size"
        :hide-details="true"
        item-title="state"
        variant="outlined"
        :items="[10, 25, 50, 100, 'Todos']"
        @update:modelValue="updatePaginationSize"
      />
    </div>

    <div class="mx-3">
      Página {{ pagination.page }} de {{ props.total_pages }}
    </div>

    <v-pagination
      density="compact"
      :length="props.total_pages"
      :total-visible="3"
      v-model="pagination.page"
      @change="updateCurrentPage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "ItemsPerPage",
  props: {
    total_pages: {
      type: Number,
      required: true,
    },
    total_items: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pagination = ref({
      size: 10,
      page: 1,
    });

    const updatePaginationSize = (selectedSize: string | number) => {
      if (selectedSize === "Todos") {
        pagination.value.size = props.total_items;
      } else {
        pagination.value.size = Number(selectedSize);
      }
      emit("update:pagination", {
        page: pagination.value.page,
        size: pagination.value.size,
      });
    };

    const updateCurrentPage = (newPage: number) => {
      pagination.value.page = newPage;
      emit("update:pagination", { page: newPage, size: pagination.value.size });
    };

    watch(
      () => pagination.value.page,
      (newPage) => {
        emit("update:pagination", {
          page: newPage,
          size: pagination.value.size,
        });
      }
    );

    return {
      props,
      pagination,
      updatePaginationSize,
      updateCurrentPage,
    };
  },
});
</script>

<style scoped>
.items-per-page {
  margin: 1rem 0;
}
</style>
