import BaseService from "@/shared/base.service";

import { useAlertStore } from "@/stores/alert.store";

import type { PaginationResponse } from "@/types/response/pagination";

import type { UserResponse } from "@/types/response/user";
import type { CreateUserRequest } from "@/types/request/create/createUser";
import type { UpdateUserRequest } from "@/types/request/update/updateUser";

export class UserService {
  static async GetAllUsers(
    currentPage: number,
    totalPerPage: number,
    type: "ADMIN" | "PARTNER" | "BUSINESS" | "COLLABORATOR" | "BENEFICIARY"
  ): Promise<PaginationResponse<UserResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
        type,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<PaginationResponse<UserResponse>>(
        "users",
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllUsers:", error);
      return null;
    }
  }

  static async GetAllCollaborators(
    currentPage: number,
    totalPerPage: number
  ): Promise<PaginationResponse<UserResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<PaginationResponse<UserResponse>>(
        `users/collaborators`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllCollaborators:", error);
      return null;
    }
  }

  static async GetAllBeneficiaries(
    currentPage: number,
    totalPerPage: number
  ): Promise<PaginationResponse<UserResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<PaginationResponse<UserResponse>>(
        `users/beneficiaries`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllBeneficiaries:", error);
      return null;
    }
  }

  static async GetAllBusinesses(
    currentPage: number,
    totalPerPage: number
  ): Promise<PaginationResponse<UserResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<PaginationResponse<UserResponse>>(
        `users/businesses`,
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllBusinesses:", error);
      return null;
    }
  }

  static async GetUserById(user_id: number): Promise<UserResponse | null> {
    try {
      const response = await BaseService.get<UserResponse>(`users/${user_id}`);

      return response ?? null;
    } catch (error) {
      console.error("Error in GetUserById:", error);
      return null;
    }
  }

  static async GetCollaboratorById(
    collaborator_id: number
  ): Promise<UserResponse | null> {
    try {
      const response = await BaseService.get<UserResponse>(
        `users/collaborators/${collaborator_id}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetCollaboratorById:", error);
      return null;
    }
  }

  static async CreateUser(payload: CreateUserRequest): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("users", {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Usuário criado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in CreateUser:", error);
      return null;
    }
  }

  static async UpdateUser(
    user_id: number,
    payload: UpdateUserRequest
  ): Promise<object | null> {
    try {
      const response = await BaseService.patch<object>(`users/${user_id}`, {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Usuário atualizado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateUser:", error);
      return null;
    }
  }

  static async DeleteUserById(user_id: number): Promise<boolean | null> {
    try {
      const response = await BaseService.delete<boolean>(`users/${user_id}`);

      return response ?? false;
    } catch (error) {
      console.error("Error in DeleteUserById:", error);
      return false;
    }
  }
}
