import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.svg'


const _hoisted_1 = { class: "h-100vh pt-50 pb-50" }
const _hoisted_2 = { class: "d-table" }
const _hoisted_3 = { class: "d-table-cell" }
const _hoisted_4 = { class: "pingo-form ms-auto me-auto" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "d-flex justify-center align-items-center mb-10" }
const _hoisted_7 = { class: "d-flex align-center justify-center mb-15 ga-2" }
const _hoisted_8 = { class: "bg-white border-radius p-20" }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_10 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_11 = { class: "pingo-form-group without-icon" }
const _hoisted_12 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_13 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_14 = { class: "pingo-form-group without-icon mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CpfInput = _resolveComponent("CpfInput")!
  const _component_CnpjInput = _resolveComponent("CnpjInput")!
  const _component_PhoneInput = _resolveComponent("PhoneInput")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_img, {
                src: _imports_0,
                alt: "logo",
                width: "100"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "mb-0" }, "Já possui uma conta?", -1)),
            _createVNode(_component_router_link, {
              to: "/autenticar/acessar",
              class: "d-inline-block main-color w-auto fw-medium"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" Faça o login aqui! ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    lg: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                          _createTextVNode(" Nome "),
                          _createElementVNode("span", null, "*")
                        ], -1)),
                        _createVNode(_component_TextInput, {
                          label: 'Digite o seu nome',
                          type: 'text',
                          modelValue: _ctx.signUpForm.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.signUpForm.name) = $event)),
                          "error-messages": 
                        _ctx.v$.name.$errors
                          ? _ctx.v$.name.$errors[0]?.$message
                          : undefined
                      
                        }, null, 8, ["modelValue", "error-messages"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    lg: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                          _createTextVNode(" Email "),
                          _createElementVNode("span", null, "*")
                        ], -1)),
                        _createVNode(_component_TextInput, {
                          label: 'Digite o seu email',
                          type: 'email',
                          modelValue: _ctx.signUpForm.email,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signUpForm.email) = $event)),
                          "error-messages": 
                        _ctx.v$.email.$errors
                          ? _ctx.v$.email.$errors[0]?.$message
                          : undefined
                      
                        }, null, 8, ["modelValue", "error-messages"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    lg: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                          _createTextVNode(" Documento "),
                          _createElementVNode("span", null, "*")
                        ], -1)),
                        (_ctx.signUpForm.type == 'USER')
                          ? (_openBlock(), _createBlock(_component_CpfInput, {
                              key: 0,
                              label: 'Digite o seu documento',
                              modelValue: _ctx.signUpForm.document,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.signUpForm.document) = $event)),
                              required: true,
                              "onUpdate:value": _cache[3] || (_cache[3] = (value) => _ctx.signUpForm.document = value.replaceAll('.','').replace('-','')),
                              "error-messages": 
                        _ctx.v$.document.$errors
                          ? _ctx.v$.document.$errors[0]?.$message
                          : undefined
                      
                            }, null, 8, ["modelValue", "error-messages"]))
                          : _createCommentVNode("", true),
                        (_ctx.signUpForm.type == 'BUSINESS')
                          ? (_openBlock(), _createBlock(_component_CnpjInput, {
                              key: 1,
                              label: 'Digite o seu documento',
                              modelValue: _ctx.signUpForm.document,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.signUpForm.document) = $event)),
                              required: true,
                              "onUpdate:value": _cache[5] || (_cache[5] = (value) => _ctx.signUpForm.document = value.replaceAll('.','').replace('-','')),
                              "error-messages": 
                        _ctx.v$.document.$errors
                          ? _ctx.v$.document.$errors[0]?.$message
                          : undefined
                      
                            }, null, 8, ["modelValue", "error-messages"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    lg: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                          _createTextVNode(" Celular "),
                          _createElementVNode("span", null, "*")
                        ], -1)),
                        _createVNode(_component_PhoneInput, {
                          label: 'Digite o seu celular',
                          type: 'tel',
                          modelValue: _ctx.signUpForm.phone,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.signUpForm.phone) = $event)),
                          "error-messages": 
                        _ctx.v$.phone.$errors
                          ? _ctx.v$.phone.$errors[0]?.$message
                          : undefined
                      
                        }, null, 8, ["modelValue", "error-messages"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    lg: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                          _createTextVNode(" Senha "),
                          _createElementVNode("span", null, "*")
                        ], -1)),
                        _createVNode(_component_TextInput, {
                          label: 'Digite a sua senha',
                          type: 'password',
                          modelValue: _ctx.signUpForm.password,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.signUpForm.password) = $event)),
                          "error-messages": 
                        _ctx.v$.password.$errors
                          ? _ctx.v$.password.$errors[0]?.$message
                          : undefined
                      
                        }, null, 8, ["modelValue", "error-messages"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    lg: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                          _createTextVNode(" Confirme a sua senha "),
                          _createElementVNode("span", null, "*")
                        ], -1)),
                        _createVNode(_component_TextInput, {
                          label: 'Digite a sua senha novamente',
                          type: 'password',
                          modelValue: _ctx.signUpForm.confirmationPassword,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.signUpForm.confirmationPassword) = $event)),
                          "error-messages": 
                        _ctx.v$.confirmationPassword.$errors
                          ? _ctx.v$.confirmationPassword.$errors[0]?.$message
                          : undefined
                      
                        }, null, 8, ["modelValue", "error-messages"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_PrimaryButton, {
              "is-loading": _ctx.isLoading,
              label: "Criar conta"
            }, null, 8, ["is-loading"])
          ], 32)
        ])
      ])
    ])
  ]))
}