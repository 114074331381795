<template>
  <v-card class="mb-25 pingo-card">
    <div class="v-card-header border-bottom">
      <h5 class="mb-0">Configurações</h5>
    </div>

    <!-- Navigate -->
    <ul class="ps-0 mt-0">
      <!-- <li>
        <router-link to="/configuracoes/conta"> Conta </router-link>
      </li> -->
      <li v-if="user.type == 'BUSINESS'">
        <router-link to="/configuracoes/enderecos"> Endereços </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/politica-de-privacidade">
          Política de Privacidade
        </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/termos-e-condicoes">
          Termos e Condições
        </router-link>
      </li>
    </ul>

    <!-- Form -->
    <form>
      <div class="mb-20">
        <h5 class="fw-semibold mb-8">
          TERMO DE USO DO SISTEMA MÃOS QUE NUTREM
        </h5>
        <p>Última atualização: [Data]</p>
      </div>

      <h2 class="fw-semibold mb-10">Bem-vindo ao Sistema Mãos que Nutrem!</h2>

      <p>
        Este Termo de Uso estabelece as condições para a utilização do sistema
        desenvolvido pela MÃOS QUE NUTREM LTDA, CNPJ nº 55.263.890/0001-16, que
        oferece serviços integrados de gestão de pedidos de refeições e
        fornecimento de alimentos produzidos diretamente por nossa empresa.
      </p>

      <p>
        A aceitação deste Termo de Uso é indispensável para acessar e utilizar o
        sistema e os serviços associados.
      </p>

      <h3 class="fw-semibold mt-20 mb-10">1. OBJETO DO SISTEMA</h3>

      <h3 class="fw-semibold mt-20 mb-10">Política de Privacidade</h3>
      <p>
        A Mãos Que Nutrem valoriza a privacidade e a proteção dos dados pessoais dos seus usuários, conforme as disposições da Lei Geral de Proteção de Dados Pessoais (LGPD - Lei nº 13.709/2018). Esta Política de Privacidade tem como objetivo esclarecer como coletamos, utilizamos, armazenamos, compartilhamos e protegemos as informações de nossos usuários, bem como os direitos dos mesmos em relação aos seus dados pessoais.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">1. Coleta de Dados Pessoais</h4>
      <p><strong>1.1 Dados Coletados:</strong></p>
      <p>
        Coletamos os seguintes dados pessoais dos usuários durante o uso do sistema, conforme necessário para o funcionamento adequado da plataforma:
        <ul>
          <li>Dados de Identificação: Nome completo, e-mail, CPF, cargo, e informações relacionadas ao vínculo com a empresa contratante.</li>
          <li>Dados de Acesso: Login, senha e informações de autenticação no sistema.</li>
          <li>Dados de Solicitação de Refeições: Informações sobre as refeições solicitadas, quantidade, datas, opções de cardápio e preferências alimentares.</li>
          <li>Dados de Navegação: Informações sobre a interação dos usuários com a plataforma, como o endereço de IP, dispositivo utilizado, e outras informações de navegação (coletadas automaticamente para melhorar a experiência do usuário e a funcionalidade do sistema).</li>
        </ul>
      </p>

      <p><strong>1.2 Finalidade da Coleta:</strong></p>
      <p>
        Os dados coletados são utilizados para as seguintes finalidades:
        <ul>
          <li>Gerenciar as solicitações de refeições dos funcionários e fornecer os serviços contratados pela Empresa Contratante.</li>
          <li>Emitir relatórios sobre o consumo de refeições para as Empresas Contratantes e para os funcionários.</li>
          <li>Enviar informações de suporte técnico, atualizações e notificações sobre o sistema.</li>
          <li>Garantir a segurança do sistema e o cumprimento das obrigações contratuais e legais.</li>
        </ul>
      </p>

      <h4 class="fw-semibold mt-20 mb-10">2. Uso de Dados Pessoais</h4>
      <p><strong>2.1 Base Legal para o Tratamento de Dados:</strong></p>
      <p>
        A Mãos que Nutrem trata os dados pessoais dos usuários com base nas seguintes hipóteses previstas na LGPD:
        <ul>
          <li>Execução de Contrato: Para fornecer os serviços contratados pela Empresa Contratante e permitir que os funcionários façam suas solicitações de refeições.</li>
          <li>Obrigações Legais: Para cumprir obrigações legais ou regulatórias, como emissão de notas fiscais e registros de dados para fins fiscais e tributários.</li>
          <li>Legítimo Interesse: Para garantir a segurança do sistema, prevenir fraudes e melhorar os serviços prestados.</li>
          <li>Consentimento: Para a coleta e uso de dados sensíveis, como preferências alimentares, caso seja necessário.</li>
        </ul>
      </p>

      <p><strong>2.2 Compartilhamento de Dados:</strong></p>
      <p>
        A Mãos que Nutrem não compartilha dados pessoais com terceiros, exceto nas seguintes situações:
        <ul>
          <li>Com as Empresas Contratantes: Para fornecer relatórios de consumo e para o gerenciamento das solicitações de refeições.</li>
          <li>Com prestadores de serviços: Quando necessário para a execução de tarefas específicas, como a hospedagem de dados ou o suporte técnico, sempre que esses prestadores sigam as mesmas obrigações de confidencialidade e proteção de dados.</li>
          <li>Por força de lei ou ordem judicial: Quando solicitado por autoridades competentes, conforme exigido por lei ou para a proteção de direitos.</li>
        </ul>
      </p>

      <h4 class="fw-semibold mt-20 mb-10">3. Armazenamento e Proteção de Dados</h4>
      <p><strong>3.1 Segurança dos Dados:</strong></p>
      <p>
        A Mãos que Nutrem adota medidas técnicas e administrativas adequadas para proteger os dados pessoais contra acessos não autorizados, perdas, roubos ou alterações indevidas. Isso inclui o uso de criptografia, firewalls, controles de acesso e outras práticas de segurança da informação.
      </p>

      <p><strong>3.2 Tempo de Retenção dos Dados:</strong></p>
      <p>
        Os dados pessoais serão mantidos pelo tempo necessário para cumprir as finalidades para as quais foram coletados, ou conforme exigido por disposições legais, fiscais ou contratuais.
        Após o término da relação contratual ou solicitação de exclusão dos dados, a Mãos que Nutrem se compromete a excluir todos os dados pessoais de forma segura, exceto aqueles necessários para cumprimento de obrigações legais.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">4. Direitos dos Usuários</h4>
      <p><strong>4.1 Direitos de Acesso, Correção e Exclusão:</strong></p>
      <p>
        Os usuários têm os seguintes direitos em relação aos seus dados pessoais:
        <ul>
          <li>Acesso: O direito de acessar os dados pessoais que estão sendo processados pela Mãos que Nutrem.</li>
          <li>Correção: O direito de corrigir dados incompletos, desatualizados ou incorretos.</li>
          <li>Exclusão: O direito de solicitar a exclusão de dados pessoais, exceto quando a retenção for necessária para o cumprimento de obrigações legais ou contratuais.</li>
          <li>Portabilidade: O direito de solicitar que os dados sejam transferidos para outra empresa, desde que em formato estruturado e legível.</li>
          <li>Revogação do Consentimento: O direito de revogar o consentimento dado para o tratamento de dados pessoais a qualquer momento, sem afetar a legalidade do tratamento realizado até a revogação.</li>
          <li>Oposição: O direito de se opor ao tratamento dos dados pessoais, em algumas situações previstas por lei.</li>
        </ul>
      </p>

      <p><strong>4.2 Como Exercitar Seus Direitos:</strong></p>
      <p>
        Os usuários podem exercer seus direitos entrando em contato com a Mãos que Nutrem por meio dos canais de comunicação disponíveis no sistema, solicitando a ação desejada. A empresa se compromete a responder a essas solicitações dentro dos prazos estabelecidos pela LGPD.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">5. Alterações nesta Política de Privacidade</h4>
      <p><strong>5.1 Modificações:</strong></p>
      <p>
        A Mãos que Nutrem pode alterar esta Política de Privacidade a qualquer momento, para refletir mudanças em suas práticas de privacidade, serviços ou requisitos legais.
        Quaisquer alterações serão informadas aos usuários por meio de comunicação no sistema, e a versão atualizada será publicada nesta página.
        O uso contínuo do sistema após qualquer modificação implica na aceitação da nova política.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">6. Contato</h4>
      <p><strong>6.1 Responsável pelo Tratamento de Dados:</strong></p>
      <p>
        Caso os usuários tenham dúvidas ou preocupações em relação ao tratamento de seus dados pessoais ou queiram exercer seus direitos, podem entrar em contato com a Mãos que Nutrem por meio dos seguintes canais:
        <br>E-mail: 
        <br>Telefone: 
        <br>Endereço:
      </p>
    </form>

  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  name: "SettingsPrivacyPolicyPage",
  components: {},
  setup() {
    const authStore = useAuthStore();

    const user: Ref = ref(authStore.getUser);

    return {
      user,
    };
  },
});
</script>

<style lang="scss" scoped>
button,
a {
  padding: 14px 35px !important;
  border-radius: 5px !important;

  &.gray {
    color: var(--blackColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}

ul {
  list-style-type: none;
  margin-bottom: 15px;

  li {
    display: inline-block;
    margin: {
      right: 15px;
      bottom: 15px;
    }
    a {
      color: var(--paragraphColor);
      background-color: #f4f4f4;
      display: inline-block;
      border-radius: 5px;
      padding: 10px 25px !important;
      font: {
        size: var(--fontSize);
        weight: 600;
      }
      &:hover,
      &.active {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  ul {
    li {
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
