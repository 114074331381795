import { reactive, ref } from "vue";

import { useDate } from "vuetify";

import { useBaseComposable } from "./useBaseComposable";

import { InvoiceService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { InvoiceResponse } from "@/types/response/invoice";
import type { UpdateInvoiceRequest } from "@/types/request/update/updateInvoice";
import type { InvoiceDetailsResponse } from "@/types/response/invoiceDetails";

import type { Item } from "@/types/model/item";

export function useInvoice() {
  const date = useDate();

  const { createValidationRules, minValue, required } = useBaseComposable();

  const invoice = ref<InvoiceDetailsResponse>({
    invoiceId: 0,
    invoiceOrderId: 0,
    totalAmount: 0,
    user: {
      createdAt: "",
      document: "",
      email: "",
      name: "",
      phone: "",
      updatedAt: "",
      userId: 0,
    },
    orders: [],
    history: [],
    status: "pending",
    createdAt: new Date(),
    updatedAt: new Date(),
  });
  const invoices = ref<InvoiceResponse[]>([]);

  const columns = ref<object[]>([
    { title: "ID", align: "start", key: "invoiceId" },
    { title: "Empresa", align: "center", key: "user.name" },
    { title: "Valor Total", align: "center", key: "totalAmount" },
    {
      title: "Criado Em",
      align: "center",
      key: "createdAt",
      value: (item: InvoiceResponse) =>
        date.format(item?.createdAt, "keyboardDateTime"),
    },
    {
      title: "Atualizado Em",
      align: "center",
      key: "updatedAt",
      value: (item: InvoiceResponse) =>
        date.format(item?.updatedAt, "keyboardDateTime"),
    },
    {
      title: "Status",
      align: "center",
      key: "status",
      value: (item: InvoiceResponse) => item,
    },
    {
      title: "Ações",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: InvoiceResponse) => item,
    },
  ]);

  const newInvoiceColumns = ref<object[]>([
    {
      title: "Produto",
      align: "start",
      key: "name",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Preço",
      align: "center",
      key: "price",
      sortable: false,
      value: (item: Item) => item,
    },
    { title: "Quantidade", align: "center", key: "quantity", sortable: false },
    {
      title: "Observação",
      align: "center",
      key: "observation",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Total",
      align: "center",
      key: "total",
      sortable: false,
      value: (item: Item) => item,
    },

    {
      title: "Ação",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: InvoiceResponse) => item,
    },
  ]);

  const pagination = ref({
    totalElements: 0,
    currentPage: 1,
    totalPerPage: 10,
    totalPages: 1,
  });

  const newInvoiceItemFormRules = createValidationRules({
    itemId: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minValue: {
        validator: minValue(1),
        messageKey: "validation.minValue",
        params: { min: 1 },
      },
    },
    quantity: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minValue: {
        validator: minValue(1),
        messageKey: "validation.minValue",
        params: { min: 1 },
      },
    },
  });

  const updateInvoiceFormRules = createValidationRules({
    status: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
  });

  const updateInvoiceForm = reactive<UpdateInvoiceRequest>({
    invoiceId: 0,
    status: "pending",
  });

  async function getAllInvoices(
    currentPage: number,
    totalElements: number,
    userId?: number
  ) {
    try {
      const response = await InvoiceService.GetAllInvoices(
        currentPage,
        totalElements,
        userId
      );

      if (response) {
        invoices.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getInvoiceById(id: number) {
    try {
      const response = await InvoiceService.GetInvoiceById(id);

      if (response) invoice.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateInvoice(id: number, payload: UpdateInvoiceRequest) {
    try {
      const response = await InvoiceService.UpdateInvoice(id, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    columns,
    newInvoiceColumns,
    pagination,
    invoice,
    invoices,
    newInvoiceItemFormRules,
    updateInvoiceFormRules,
    updateInvoiceForm,
    getAllInvoices,
    getInvoiceById,
    updateInvoice,
  };
}
