<template>
  <PageTitle pageTitle="Novo Usuário" mainTitle="Novo Usuário" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações Básicas</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="12" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nome
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o nome do usuário aqui'"
                  :type="'email'"
                  v-model="newUserForm.name"
                  :error-messages="
                    v$.name.$errors ? v$.name.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Email
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o email do usuário aqui'"
                  :type="'email'"
                  v-model="newUserForm.email"
                  :error-messages="
                    v$.email.$errors ? v$.email.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Documento <span>*</span>
                </label>

                <CpfInput
                  v-if="newUserForm.type != 'BUSINESS'"
                  :label="'Digite o CPF do usuário aqui'"
                  v-model="newUserForm.document"
                  :required="true"
                  @update:value="(value: string) => newUserForm.document = value.replaceAll('.','').replace('-','')"
                  :error-messages="
                    v$.document.$errors ? v$.document.$errors[0]?.$message : undefined
                  "
                />

                <CnpjInput
                  v-if="newUserForm.type == 'BUSINESS'"
                  :label="'Digite o CNPJ do usuário aqui'"
                  v-model="newUserForm.document"
                  :required="true"
                  @update:value="(value: string) => newUserForm.document = value.replaceAll('.','').replace('-','')"
                  :error-messages="
                    v$.document.$errors ? v$.document.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Celular <span>*</span>
                </label>

                <PhoneInput
                  :label="'Digite o celular do usuário aqui'"
                  :type="'tel'"
                  v-model="newUserForm.phone"
                  :error-messages="
                    v$.phone.$errors ? v$.phone.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col v-if="user.type == 'ADMIN'" cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Tipo de usuário
                  <span>*</span>
                </label>

                <v-select
                  label="Selecione um tipo"
                  @update:model-value="(value: 'ADMIN' | 'PARTNER' | 'BUSINESS' | 'COLLABORATOR' | 'BENEFICIARY') => newUserForm.type = value"
                  :items="[
                    {
                      title: 'Administrador',
                      value: 'ADMIN',
                    },
                    {
                      title: 'Parceiro',
                      value: 'PARTNER',
                    },
                    {
                      title: 'Empresa',
                      value: 'BUSINESS',
                    },
                    {
                      title: 'Colaborador',
                      value: 'COLLABORATOR',
                    },
                    {
                      title: 'Beneficiário',
                      value: 'BENEFICIARY',
                    },
                  ]"
                  v-model="newUserForm.type"
                  :error-messages="
                    v$.type.$errors ? v$.type.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Senha <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a senha do usuário aqui'"
                  :type="'password'"
                  v-model="newUserForm.password"
                  :error-messages="
                    v$.password.$errors ? v$.password.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Confirme a sua senha
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a senha novamente'"
                  :type="'password'"
                  v-model="newUserForm.confirmationPassword"
                  :error-messages="
                    v$.confirmationPassword.$errors
                      ? v$.confirmationPassword.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>

          <div class="text-end">
            <button type="submit" class="default-btn">
              <v-progress-circular
                v-if="isLoadingUserRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>
              Criar Usuário
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";
import { useLoadingDialog } from "@/stores/loading.store";

import { useUser } from "@/composables/useUser";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import CpfInput from "@/components/Forms/AdvancedElements/Input/CpfInput.vue";
import CnpjInput from "@/components/Forms/AdvancedElements/Input/CnpjInput.vue";
import PhoneInput from "@/components/Forms/AdvancedElements/Input/PhoneInput.vue";

export default defineComponent({
  name: "NewUserPage",
  components: {
    PageTitle,
    TextInput,
    CpfInput,
    CnpjInput,
    PhoneInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const user: Ref = ref(authStore.getUser);

    const { newUserForm, newUserFormRules, createUser } = useUser();

    const isLoadingUserInfo = ref(false);
    const isLoadingUserRequest = ref(false);

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      loadingStore.setLoading("Criando usuário...");

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmationPassword, ...newUserFormPayload } = newUserForm;

      newUserFormPayload.userParentId = user.value.userId;
      newUserFormPayload.document = newUserFormPayload.document.replace(/\D/g, "");
      newUserFormPayload.phone = newUserFormPayload.phone.replace(/\D/g, "");

      const response = await createUser(newUserFormPayload);

      loadingStore.setLoadingOff();

      if (response) {
        setTimeout(() => {
          router.go(-1);
        }, 2000);
      }
    };

    const v$ = useVuelidate(newUserFormRules, newUserForm, { $scope: false });

    onMounted(() => {
      const type = route.query.type;
      if (type)
        newUserForm.type = type.toString() as
          | "ADMIN"
          | "PARTNER"
          | "BUSINESS"
          | "COLLABORATOR"
          | "BENEFICIARY";
    });

    return {
      v$,
      user,
      route,
      router,
      isLoadingUserInfo,
      isLoadingUserRequest,
      newUserForm,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
