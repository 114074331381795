import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.svg'


const _hoisted_1 = { class: "logo bg-white" }
const _hoisted_2 = { class: "mt-4 virtual-scroll" }
const _hoisted_3 = { class: "sidebar-inner" }
const _hoisted_4 = { class: "sidebar-menu" }
const _hoisted_5 = {
  key: 1,
  class: "sub-title gray-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AccordionSlot = _resolveComponent("AccordionSlot")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'sidebar-area bg-white box-shadow',
      { active: _ctx.stateStoreInstance.open },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "d-flex justify-content-center align-items-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_img, {
            src: _imports_0,
            alt: "logo",
            height: "80"
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['burger-menu', { active: _ctx.stateStoreInstance.open }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("span", { class: "top-bar" }, null, -1),
      _createElementVNode("span", { class: "middle-bar" }, null, -1),
      _createElementVNode("span", { class: "bottom-bar" }, null, -1)
    ]), 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AccordionSlot, null, {
            default: _withCtx(() => [
              (_ctx.menuPermissions.includes('Dashboard'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: "/",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("i", { class: "flaticon-visualization" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Dashboard", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Menu "))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.includes('Empresas'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 2,
                    to: "/usuarios/empresas",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("i", { class: "flaticon-customer-service" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Empresas", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.includes('Colaboradores'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 3,
                    to: "/usuarios/colaboradores",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "flaticon-users-group" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Colaboradores", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.includes('Beneficiários'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 4,
                    to: "/usuarios/beneficiarios",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "flaticon-idea" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Beneficiários", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.includes('Pedidos'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 5,
                    to: "/pedidos",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "flaticon-bag" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Pedidos", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.includes('Financeiro'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 6,
                    to: "/financeiro",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("i", { class: "flaticon-pie-chart-6" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Financeiro", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.menuPermissions.includes('Produtos'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 7,
                    to: "/produtos",
                    class: "sidebar-menu-link"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("i", { class: "flaticon-shopping-cart-2" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Produtos", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 2))
}