<template>
  <PageTitle pageTitle="Product Details" mainTitle="Product Details" />

  <ProductDetails />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PageTitle from "@/components/Common/PageTitle.vue";
import ProductDetails from "@/components/eCommerce/ProductDetails/ProductDetails.vue";

export default defineComponent({
  name: "ProductDetailsPage",
  components: {
    PageTitle,
    ProductDetails,
  },
});
</script>
