<template>
  <PageTitle pageTitle="Financeiro" mainTitle="Financeiro" />

  <InvoiceDetails :invoiceData="invoice" />
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useRoute } from "vue-router";

import { useAuthStore } from "@/stores/auth.store";

import { useInvoice } from "@/composables/useInvoice";

import PageTitle from "@/components/Common/PageTitle.vue";

import InvoiceDetails from "@/components/Invoice/InvoiceDetails/InvoiceDetails.vue";

export default defineComponent({
  name: "FinancialDetailsPage",
  components: {
    PageTitle,
    InvoiceDetails,
  },
  setup() {
    const route = useRoute();

    const authStore = useAuthStore();

    const { columns, pagination, invoice, getInvoiceById } = useInvoice();

    const user: Ref = ref(authStore.getUser);

    const isLoadingFinancials = ref(false);

    const fetchFinancialData = async (invoiceId: number) => {
      try {
        isLoadingFinancials.value = true;

        await getInvoiceById(invoiceId).then(() => {
          isLoadingFinancials.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar detalhes de faturas:", error);
      }
    };

    onMounted(async () => {
      const invoiceId = parseInt(route.params.id.toString());
      await fetchFinancialData(invoiceId);
    });

    return {
      columns,
      pagination,
      isLoadingFinancials,
      invoice,
      user,
    };
  },
});
</script>
