import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-content-center flex-wrap justify-space-between" }
const _hoisted_2 = { class: "header-left-side d-flex align-content-center flex-wrap" }
const _hoisted_3 = { class: "header-right-side d-flex align-content-center align-items-center flex-wrap mt-0 mb-0" }
const _hoisted_4 = { class: "d-none d-lg-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrentDate = _resolveComponent("CurrentDate")!
  const _component_AdminProfile = _resolveComponent("AdminProfile")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([
      'header-area bg-white border-bottom-radius box-shadow mb-25',
      { sticky: _ctx.isSticky },
      { active: _ctx.stateStoreInstance.open },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(['burger-menu', { active: _ctx.stateStoreInstance.open }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "top-bar" }, null, -1),
          _createElementVNode("span", { class: "middle-bar" }, null, -1),
          _createElementVNode("span", { class: "bottom-bar" }, null, -1)
        ]), 2)
      ]),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_CurrentDate)
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_AdminProfile)
        ])
      ])
    ])
  ], 2))
}