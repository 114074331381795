import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, {
    label: "Selecione um Produto",
    loading: _ctx.isLoadingProductsInfo,
    "item-value": 'userId',
    "item-title": 'name',
    "return-object": true,
    items: _ctx.products,
    clearable: false,
    "hide-details": !_ctx.required,
    "no-data-text": 
      _ctx.isLoadingProductsInfo == true ? 'Carregando...' : 'Não há nenhum produto'
    ,
    "onUpdate:menu": _cache[0] || (_cache[0] = (value) => (_ctx.isSelectMenuOpen = value)),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => _ctx.$emit('update:value', value))
  }, {
    item: _withCtx(({ props, item }) => [
      _createVNode(_component_v_list_item, _mergeProps(props, {
        subtitle: item.raw.description ?? 'Não possui descrição'
      }), null, 16, ["subtitle"])
    ]),
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.isLoadingProductsInfo ? "Carregando..." : "Não há nenhum produto"), 1)
    ]),
    _: 1
  }, 8, ["loading", "items", "hide-details", "no-data-text"]))
}