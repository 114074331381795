<template>
  <v-text-field
    :label="label"
    type="tel"
    v-maskito="phoneMask"
    @update:model-value="(value) => $emit('update:value', value)"
  >
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { phoneMask } from "@/shared/mask/mask";

export default defineComponent({
  name: "PhoneInput",
  props: {
    label: {
      type: String,
      default: "Escreva",
    },
  },
  emits: ["update:value"],
  setup() {
    return {
      phoneMask,
    };
  },
});
</script>
