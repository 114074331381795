import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ri-user-3-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_maskito = _resolveDirective("maskito")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, {
    label: _ctx.label,
    type: _ctx.type,
    "hide-details": !_ctx.required,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:value', value))
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("i", _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "type", "hide-details"])), [
    [_directive_maskito, _ctx.cnpjMask]
  ])
}