<template>
  <PageTitle pageTitle="Beneficiários" mainTitle="Beneficiários" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Lista de Beneficiários</h5>

      <router-link
        class="default-btn pingo small"
        :to="{ name: 'NewUserPage', query: { type: 'BENEFICIARY' } }"
      >
        <i class="ri-add-fill"></i>
        Criar novo usuário
      </router-link>

      <!-- <p>Atualizado por último em {{ new Date().toLocaleString() }}</p> -->
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="users"
          :loading="isLoadingUserBeneficiaries"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.isActive`]="{ value }">
            <span
              v-if="value == true"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Ativo
            </span>
            <span
              v-if="value == false"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Inativo
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-items-center ga-6" style="width: 150px">
              <router-link
                v-if="user.type == 'ADMIN'"
                :to="{
                  name: 'EditUserPage',
                  params: { id: item.userId },
                  query: { type: 'BENEFICIARY' },
                }"
              >
                <v-btn
                  class="text-capitalize"
                  color="primary-darken-3"
                  variant="tonal"
                >
                  Editar
                </v-btn>
              </router-link>

              <v-dialog width="auto" scrollable>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="text-capitalize"
                    color="red-darken-3"
                    variant="tonal"
                    v-bind="activatorProps"
                  >
                    Remover
                  </v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card class="py-0" title="Remover beneficiário">
                    <v-divider></v-divider>

                    <v-card-text class="px-4">
                      Tem certeza que deseja remover este beneficiário?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        class="text-capitalize"
                        @click="isActive.value = false"
                      >
                        Fechar
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="text-capitalize"
                        color="red-darken-3"
                        variant="tonal"
                        @click="handleRemoveBeneficiary(item.userId)"
                      >
                        Remover
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum beneficiário </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import { useLoadingDialog } from "@/stores/loading.store";

import { useAuthStore } from "@/stores/auth.store";

import { useUser } from "@/composables/useUser";

import PageTitle from "@/components/Common/PageTitle.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";
import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "BeneficiariesPage",
  components: {
    PageTitle,
    ItemsPerPage,
  },
  setup() {
    const authStore = useAuthStore();
    const loadingStore = useLoadingDialog();

    const {
      columns,
      pagination,
      users,
      getAllUsers,
      getAllBeneficiaries,
      deleteUserById,
    } = useUser();

    const user: Ref = ref(authStore.getUser);

    const isLoadingUserBeneficiaries = ref(false);

    const fetchAllUsersData = async () => {
      try {
        isLoadingUserBeneficiaries.value = true;

        getAllUsers(
          pagination.value.currentPage ?? 1,
          pagination.value.totalPerPage ?? 10,
          UserType.BENEFICIARY
        ).then(() => {
          isLoadingUserBeneficiaries.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de beneficiários:", error);
      }
    };

    const fetchUserBeneficiariesData = async () => {
      try {
        isLoadingUserBeneficiaries.value = true;

        getAllBeneficiaries(
          pagination.value.currentPage ?? 1,
          pagination.value.totalPerPage ?? 10
        ).then(() => {
          isLoadingUserBeneficiaries.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de beneficiários:", error);
      }
    };

    const handleRemoveBeneficiary = async (user_id: number) => {
      loadingStore.setLoading("Removendo beneficiário...");

      await deleteUserById(user_id);

      loadingStore.setLoadingOff();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      if (user.value.type == UserType.ADMIN) await fetchAllUsersData();
      if (user.value.type == UserType.BUSINESS)
        await fetchUserBeneficiariesData();
    };

    onMounted(async () => {
      if (user.value.type == UserType.ADMIN) await fetchAllUsersData();
      if (user.value.type == UserType.BUSINESS)
        await fetchUserBeneficiariesData();
    });

    return {
      user,
      columns,
      pagination,
      isLoadingUserBeneficiaries,
      users,
      handleUpdatePageProps,
      handleRemoveBeneficiary,
    };
  },
});
</script>
