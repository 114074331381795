<template>
  <v-card class="mb-25 pingo-card">
    <div class="v-card-header align-items-center border-bottom">
      <h5 class="mb-0">Detalhes de faturamento</h5>
      <h5 class="mb-0">
        Faturamento #{{ formatInvoiceId(invoice.invoiceId) }}
      </h5>
    </div>

    <div class="d-flex justify-space-between">
      <div>
        <h5 class="mb-15">Faturado para:</h5>
        <span class="fw-semibold gray-color d-block mb-8">
          {{ invoice.user.name }}
        </span>

        <!-- 
        <div v-if="invoice.user.addresses && invoice.user.addresses.length > 0">
          <span class="gray-color d-block mb-8">{{
            invoice.user.addresses?.street
          }}</span>
          <span class="gray-color d-block mb-8">{{
            invoice.user.addresses?.number
          }}</span>
          <span class="gray-color d-block mb-8">{{
            invoice.user.addresses?.neighborhood
          }}</span>
          <span class="gray-color d-block mb-8"
            >{{ invoice.user.addresses?.city }}
            {{ invoice.user.addresses?.state }}
            {{ invoice.user.addresses?.zipCode }}</span
          >
          <span class="gray-color d-block mb-8">{{
            invoice.user.addresses?.country
          }}</span>
        </div> -->

        <h5 class="mb-15 mt-25">Pagamento:</h5>
        <span class="gray-color d-block mb-8">
          Valor total da cobrança: {{ formatCurrency(invoice.totalAmount) }}
        </span>

        <h5 class="mb-15 mt-25">Status:</h5>
        <span
          v-if="invoice.status === InvoiceStatus.Cancelled"
          class="text-capitalize badge rounded-pill text-soft-danger"
        >
          Cancelado
        </span>
        <span
          v-else-if="invoice.status === InvoiceStatus.Pending"
          class="text-capitalize badge rounded-pill text-soft-warning"
        >
          Pendente
        </span>
        <span
          v-else-if="invoice.status === InvoiceStatus.Paid"
          class="text-capitalize badge rounded-pill text-soft-success"
        >
          Pago
        </span>
        <span
          v-else-if="invoice.status === InvoiceStatus.PartiallyPaid"
          class="text-capitalize badge rounded-pill text-soft-info"
        >
          Parcialmente Pago
        </span>
        <span
          v-else-if="invoice.status === InvoiceStatus.Overdue"
          class="text-capitalize badge rounded-pill text-soft-danger"
        >
          Vencido
        </span>
        <span
          v-else-if="invoice.status === InvoiceStatus.Refunded"
          class="text-capitalize badge rounded-pill text-soft-secondary"
        >
          Reembolsado
        </span>
      </div>

      <!-- <div class="text-end">
        <h5 class="mb-15">Shipped To :</h5>
        <span class="fw-semibold gray-color d-block mb-8">{{
          invoice.user.name
        }}</span>
        <span class="gray-color d-block mb-8">{{
          invoice.user.addresses.street
        }}</span>
        <span class="gray-color d-block mb-8">{{
          invoice.user.addresses.number
        }}</span>
        <span class="gray-color d-block mb-8">{{
          invoice.user.addresses.neighborhood
        }}</span>
        <span class="gray-color d-block mb-8"
          >{{ invoice.user.addresses.city }} {{ invoice.user.addresses.state }}
          {{ invoice.user.addresses.zipCode }}</span
        >
        <span class="gray-color d-block mb-8">{{
          invoice.user.addresses.country
        }}</span> -->

      <div class="d-flex flex-column">
        <h5 class="mb-15">Faturado em:</h5>
        <span class="gray-color d-block text-right">
          {{ new Date(invoice.createdAt).toLocaleDateString() }}
        </span>
      </div>
    </div>

    <OrderSummary :orders="invoice.orders" />

    <div class="btn-box">
      <!-- <button class="gray" @click="generatePDF">Print</button> -->
      <router-link
        class="mr-0 p-0"
        :to="`/financeiro`"
        style="padding: 0px !important"
      >
        <button class="gray">Voltar</button>
      </router-link>

      <v-dialog width="380" scrollable v-model="isInvoiceStatusDialogOpen">
        <template v-slot:activator="{ props: activatorProps }">
          <div v-if="user.type === 'ADMIN'" class="d-flex ga-2">
            <button
              v-if="updateInvoiceForm.status != InvoiceStatus.Paid"
              class="success"
              v-bind="activatorProps"
              type="button"
            >
              Atualizar status
            </button>
          </div>
        </template>

        <template v-slot:default="">
          <v-card class="py-0" title="Atualizar Status">
            <v-divider></v-divider>

            <v-card-text class="px-4">
              <p class="mb-10">
                Selecione o novo status para a fatura abaixo. Certifique-se de
                que a alteração seja apropriada para o estado atual da fatura.
              </p>

              <SelectInvoiceStatus
                v-model="updateInvoiceForm.status"
                :current-status="updateInvoiceForm.status as InvoiceStatus"
                :allStatuses="allStatuses"
                :error-messages="
                  v$.status.$errors ? v$.status.$errors[0]?.$message : undefined
                "
              />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                class="text-capitalize"
                @click="isInvoiceStatusDialogOpen = false"
              >
                Fechar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                class="text-capitalize"
                color="blue-darken-3"
                variant="tonal"
                @click="updateInvoiceStatus"
              >
                Atualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import useVuelidate from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";

import { useInvoice } from "@/composables/useInvoice";

import SelectInvoiceStatus from "@/components/Forms/AdvancedElements/Select/SelectInvoiceStatus.vue";

import { UserType } from "@/types/enum/userType.enum";
import { InvoiceStatus } from "@/types/enum/invoiceStatus.enum";

import type { UpdateInvoiceRequest } from "@/types/request/update/updateInvoice";

import OrderSummary from "./OrderSummary.vue";

export default defineComponent({
  name: "InvoiceDetails",
  components: {
    OrderSummary,
    SelectInvoiceStatus,
  },
  props: {
    invoiceData: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const authStore = useAuthStore();

    const { updateInvoiceForm, updateInvoiceFormRules, updateInvoice } =
      useInvoice();

    const allStatuses: InvoiceStatus[] = [
      InvoiceStatus.Pending,
      InvoiceStatus.Paid,
      InvoiceStatus.Overdue,
      InvoiceStatus.PartiallyPaid,
      InvoiceStatus.Cancelled,
      InvoiceStatus.Refunded,
    ];

    const user: Ref = ref(authStore.getUser);

    const isInvoiceStatusDialogOpen = ref(false);

    const invoice = ref(props.invoiceData);

    const formatCurrency = (value: number) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    };

    const formatInvoiceId = (invoiceId: number) =>
      invoiceId.toString().padStart(4, "0");

    const updateInvoiceStatus = async () => {
      if (!invoice.value) return;

      const result = await v$.value.$validate();

      if (!result) return;

      const updatedInvoicePayload: UpdateInvoiceRequest = {
        status: updateInvoiceForm.status as InvoiceStatus,
      };

      const response = await updateInvoice(
        invoice.value.invoiceId,
        updatedInvoicePayload
      );

      if (response) {
        isInvoiceStatusDialogOpen.value = false;
      }
    };

    const v$ = useVuelidate(updateInvoiceFormRules, updateInvoiceForm);

    watch(
      () => props.invoiceData,
      (newValue) => {
        invoice.value = newValue;
        updateInvoiceForm.status = newValue.status;
      }
    );

    return {
      v$,
      UserType,
      InvoiceStatus,
      allStatuses,
      user,
      invoice,
      formatInvoiceId,
      formatCurrency,
      isInvoiceStatusDialogOpen,
      updateInvoiceForm,
      updateInvoiceStatus,
    };
  },
});
</script>

<style lang="scss">
.btn-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button,
  a {
    padding: 10px 18px 11px !important;
    font: {
      weight: 600 !important;
      size: 14px !important;
    }
  }
  a {
    margin-right: 8px;
  }
  button {
    margin-left: 8px;
  }
}
</style>
