import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    label: "Selecione um status",
    "item-title": "text",
    "item-value": "value",
    items: _ctx.statusOptions,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:value', value))
  }, null, 8, ["items"]))
}