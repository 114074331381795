import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = { class: "col-lg-5 col-md-12" }
const _hoisted_3 = { class: "product-image border-radius" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!
  const _component_TabsContent = _resolveComponent("TabsContent")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-25 pingo-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_carousel, {
              "hide-delimiters": "",
              style: {"height":"auto"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_carousel_item, {
                    key: i,
                    src: item.image,
                    class: "border-radius"
                  }, null, 8, ["src"]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-lg-7 col-md-12" }, [
          _createElementVNode("div", { class: "product-content" }, [
            _createElementVNode("h4", null, "Black T-Shirt Sleeve"),
            _createElementVNode("p", null, "Fendi began life in"),
            _createElementVNode("div", { class: "rating d-flex align-items-center" }, [
              _createElementVNode("i", { class: "flaticon-star-1" }),
              _createElementVNode("i", { class: "flaticon-star-1" }),
              _createElementVNode("i", { class: "flaticon-star-1" }),
              _createElementVNode("i", { class: "flaticon-star-1" }),
              _createElementVNode("i", { class: "flaticon-star-1" }),
              _createElementVNode("span", { class: "fw-semibold" }, "5.0"),
              _createElementVNode("span", { class: "gray-color" }, "(61 votes)")
            ]),
            _createElementVNode("ul", { class: "info ps-0" }, [
              _createElementVNode("li", { class: "fw-semibold d-inline-block position-relative border-radius" }, [
                _createElementVNode("i", { class: "ri-money-dollar-circle-line" }),
                _createElementVNode("span", { class: "fw-semibold gray-color d-block" }, "Price :"),
                _createTextVNode(" $10.99 ")
              ]),
              _createElementVNode("li", { class: "fw-semibold d-inline-block position-relative border-radius" }, [
                _createElementVNode("i", { class: "ri-hashtag" }),
                _createElementVNode("span", { class: "fw-semibold gray-color d-block" }, "Orders No :"),
                _createTextVNode(" 35,890 ")
              ]),
              _createElementVNode("li", { class: "fw-semibold d-inline-block position-relative border-radius" }, [
                _createElementVNode("i", { class: "ri-bar-chart-line" }),
                _createElementVNode("span", { class: "fw-semibold gray-color d-block" }, "Revenue :"),
                _createTextVNode(" $25,890k ")
              ])
            ]),
            _createElementVNode("span", { class: "d-block fw-semibold mb-10" }, "Description :"),
            _createElementVNode("p", null, " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adlino minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. "),
            _createElementVNode("div", { class: "product-size d-flex align-items-center" }, [
              _createElementVNode("span", { class: "fw-semibold" }, "Size :"),
              _createElementVNode("select", null, [
                _createElementVNode("option", { value: "1" }, "XXXS"),
                _createElementVNode("option", { value: "2" }, "XXS"),
                _createElementVNode("option", { value: "3" }, "XS"),
                _createElementVNode("option", { value: "4" }, "S"),
                _createElementVNode("option", { value: "5" }, "M"),
                _createElementVNode("option", { value: "6" }, "L"),
                _createElementVNode("option", { value: "7" }, "XL"),
                _createElementVNode("option", { value: "8" }, "XXL"),
                _createElementVNode("option", { value: "9" }, "XXXL")
              ])
            ]),
            _createElementVNode("ul", { class: "list" }, [
              _createElementVNode("li", null, [
                _createElementVNode("span", { class: "fw-semibold" }, "Vendor :"),
                _createTextVNode(" Lereve ")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", { class: "fw-semibold" }, "Category :"),
                _createTextVNode(" T-Shirt ")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", { class: "fw-semibold" }, "SKU :"),
                _createTextVNode(" U2015 ")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", { class: "fw-semibold" }, "Availability :"),
                _createTextVNode(" In Stock 10 Items ")
              ])
            ]),
            _createElementVNode("ul", { class: "social-links d-flex align-items-center" }, [
              _createElementVNode("li", null, [
                _createElementVNode("span", { class: "fw-semibold" }, "Share :")
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  target: "_blank"
                }, [
                  _createElementVNode("i", { class: "flaticon-facebook-app-symbol" })
                ])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  target: "_blank"
                }, [
                  _createElementVNode("i", { class: "flaticon-twitter" })
                ])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  target: "_blank"
                }, [
                  _createElementVNode("i", { class: "flaticon-instagram" })
                ])
              ])
            ])
          ])
        ], -1))
      ]),
      _createVNode(_component_TabsContent)
    ]),
    _: 1
  }))
}