import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pingo-form-group without-icon" }
const _hoisted_2 = { class: "pingo-form-group without-icon" }
const _hoisted_3 = { class: "pingo-form-group without-icon" }
const _hoisted_4 = { class: "text-end" }
const _hoisted_5 = {
  type: "submit",
  class: "default-btn"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_MonetaryInput = _resolveComponent("MonetaryInput")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Atualizar Pedido",
      mainTitle: "Pedido"
    }),
    _createVNode(_component_v_form, {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              lg: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "create-project-card pingo-card" }, {
                  default: _withCtx(() => [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
                      _createElementVNode("h5", { class: "mb-0" }, "Informações Básicas")
                    ], -1)),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _cache[5] || (_cache[5] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Nome "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_TextInput, {
                                label: 'Digite o Nome aqui',
                                modelValue: _ctx.updateProductForm.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updateProductForm.name) = $event)),
                                "error-messages": 
                    _ctx.v$.name.$errors ? _ctx.v$.name.$errors[0]?.$message : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Valor "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_MonetaryInput, {
                                label: 'Digite o preço aqui',
                                modelValue: _ctx.updateProductForm.price,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updateProductForm.price) = $event)),
                                "onUpdate:value": _cache[2] || (_cache[2] = (value) => (_ctx.updateProductForm.price = value)),
                                "error-messages": 
                    _ctx.v$.price.$errors ? _ctx.v$.price.$errors[0]?.$message : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _cache[7] || (_cache[7] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                _createTextVNode(" Descrição "),
                                _createElementVNode("span", null, "*")
                              ], -1)),
                              _createVNode(_component_TextInput, {
                                label: 'Digite a Descrição aqui',
                                modelValue: _ctx.updateProductForm.description,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.updateProductForm.description) = $event)),
                                "error-messages": 
                    _ctx.v$.description.$errors
                      ? _ctx.v$.description.$errors[0]?.$message
                      : undefined
                  
                              }, null, 8, ["modelValue", "error-messages"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("button", _hoisted_5, [
                        (_ctx.isLoadingProductRequest)
                          ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                              key: 0,
                              indeterminate: "",
                              size: "18",
                              width: "2"
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.isLoadingProductRequest)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Atualizando Produto... "))
                          : _createCommentVNode("", true),
                        (!_ctx.isLoadingProductRequest)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Atualizar Produto "))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}