import { reactive, ref } from "vue";

import EventBus from "@/utils/eventBus";

import { useBaseComposable } from "./useBaseComposable";

import { AddressService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { AddressResponse } from "@/types/response/address";
import type { CreateAddressRequest } from "@/types/request/create/createAddress";
import type { UpdateAddressRequest } from "@/types/request/update/updateAddress";

export function useAddress() {
  const { createValidationRules, required, minLength, maxLength } =
    useBaseComposable();

  const address = ref<AddressResponse>();
  const addresses = ref<AddressResponse[]>([]);

  const pagination = ref({
    totalElements: 0,
    currentPage: 1,
    totalPerPage: 10,
    totalPages: 1,
  });

  const newAddressFormRules = createValidationRules({
    street: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    city: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    state: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    country: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    zipCode: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(9),
        messageKey: "validation.minLength",
        params: { min: 9 },
      },
      maxLength: {
        validator: maxLength(9),
        messageKey: "validation.maxLength",
        params: { max: 9 },
      },
    },
    neighborhood: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    number: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    complement: {
      // Opcional, pode ser validado se necessário
    },
    isDefault: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
  });

  const newAddressForm = reactive<CreateAddressRequest>({
    street: "",
    city: "",
    state: "",
    country: "Brasil",
    zipCode: "",
    neighborhood: "",
    number: "",
    complement: "",
    isDefault: false,
    userId: undefined,
  });

  const updateAddressFormRules = createValidationRules({
    street: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    city: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    state: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    country: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    zipCode: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minLength: {
        validator: minLength(9),
        messageKey: "validation.minLength",
        params: { min: 9 },
      },
      maxLength: {
        validator: maxLength(9),
        messageKey: "validation.maxLength",
        params: { max: 9 },
      },
    },
    neighborhood: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    number: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    complement: {
      // Opcional, pode ser validado se necessário
    },
    isDefault: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
  });

  const updateAddressForm = reactive<UpdateAddressRequest>({
    street: "",
    city: "",
    state: "",
    country: "Brasil",
    zipCode: "",
    neighborhood: "",
    number: "",
    complement: "",
    isDefault: false,
  });

  async function getAllAddresses(
    currentPage: number,
    totalElements: number,
    userId?: number
  ) {
    try {
      const response = await AddressService.GetAllAddresses(
        currentPage,
        totalElements,
        userId
      );

      if (response) {
        addresses.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getAddressById(id: number) {
    try {
      const response = await AddressService.GetAddressById(id);

      if (response) address.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createAddress(payload: CreateAddressRequest) {
    try {
      const response = await AddressService.CreateAddress(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateAddress(id: number, payload: UpdateAddressRequest) {
    try {
      const response = await AddressService.UpdateAddress(id, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteAddressById(id: number) {
    try {
      const response = await AddressService.DeleteAddressById(id);

      if (response) EventBus.emit("address-update");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    address,
    addresses,
    newAddressFormRules,
    newAddressForm,
    updateAddressFormRules,
    updateAddressForm,
    getAllAddresses,
    getAddressById,
    createAddress,
    updateAddress,
    deleteAddressById,
  };
}
