<template>
  <v-card class="mb-25 pingo-card">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="product-image border-radius">
          <v-carousel hide-delimiters style="height: auto">
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.image"
              class="border-radius"
            ></v-carousel-item>
          </v-carousel>
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="product-content">
          <h4>Black T-Shirt Sleeve</h4>
          <p>Fendi began life in</p>
          <div class="rating d-flex align-items-center">
            <i class="flaticon-star-1"></i>
            <i class="flaticon-star-1"></i>
            <i class="flaticon-star-1"></i>
            <i class="flaticon-star-1"></i>
            <i class="flaticon-star-1"></i>
            <span class="fw-semibold">5.0</span>
            <span class="gray-color">(61 votes)</span>
          </div>
          <ul class="info ps-0">
            <li
              class="fw-semibold d-inline-block position-relative border-radius"
            >
              <i class="ri-money-dollar-circle-line"></i>
              <span class="fw-semibold gray-color d-block">Price :</span>
              $10.99
            </li>
            <li
              class="fw-semibold d-inline-block position-relative border-radius"
            >
              <i class="ri-hashtag"></i>
              <span class="fw-semibold gray-color d-block">Orders No :</span>
              35,890
            </li>
            <li
              class="fw-semibold d-inline-block position-relative border-radius"
            >
              <i class="ri-bar-chart-line"></i>
              <span class="fw-semibold gray-color d-block">Revenue :</span>
              $25,890k
            </li>
          </ul>
          <span class="d-block fw-semibold mb-10">Description :</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            adlino minim veniam, quis nostrud exercitation ullamco laboris nisi
            ut aliquip ex ea commodo.
          </p>
          <div class="product-size d-flex align-items-center">
            <span class="fw-semibold">Size :</span>
            <select>
              <option value="1">XXXS</option>
              <option value="2">XXS</option>
              <option value="3">XS</option>
              <option value="4">S</option>
              <option value="5">M</option>
              <option value="6">L</option>
              <option value="7">XL</option>
              <option value="8">XXL</option>
              <option value="9">XXXL</option>
            </select>
          </div>
          <ul class="list">
            <li>
              <span class="fw-semibold">Vendor :</span>
              Lereve
            </li>
            <li>
              <span class="fw-semibold">Category :</span>
              T-Shirt
            </li>
            <li>
              <span class="fw-semibold">SKU :</span>
              U2015
            </li>
            <li>
              <span class="fw-semibold">Availability :</span>
              In Stock 10 Items
            </li>
          </ul>
          <ul class="social-links d-flex align-items-center">
            <li>
              <span class="fw-semibold">Share :</span>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="flaticon-facebook-app-symbol"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="flaticon-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="flaticon-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <TabsContent />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TabsContent from "./TabsContent.vue";

export default defineComponent({
  name: "ProductDetails",
  components: {
    TabsContent,
  },
  data: () => ({
    items: [
      {
        id: 1,
        image: require("../../../assets/img/recent-orders/product-details1.jpg"),
      },
      {
        id: 2,
        image: require("../../../assets/img/recent-orders/product-details2.jpg"),
      },
    ],
  }),
});
</script>

<style lang="scss" scoped>
.product-content {
  padding-left: 15px;

  h4 {
    margin-bottom: 8px;
  }
  .rating {
    margin-bottom: 20px;

    i {
      line-height: 1;
      color: #ffbc2b;
      margin-right: 4px;
    }
    span {
      position: relative;
      margin-right: 4px;
      top: -1px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .info {
    margin: {
      top: 0;
      bottom: 5px;
    }
    li {
      font-size: 18px;
      border: 1px solid #e2e8f0;
      padding: 15px 20px 15px 70px;
      margin: {
        right: 15px;
        bottom: 15px;
      }
      i {
        top: 50%;
        left: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        font-weight: normal;
        display: inline-block;
        color: var(--whiteColor);
        transform: translateY(-50%);
        background-color: var(--heraBlueColor);
      }
      span {
        font-size: 14px;
        margin-bottom: 5px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .list {
    padding-left: 0;
    list-style-type: none;
    margin: {
      top: 0;
      bottom: 0;
    }
    li {
      margin-bottom: 12px;
      color: var(--paragraphColor);

      span {
        color: var(--blackColor);
        font-family: var(--headingFontFamily);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-size {
    margin: {
      bottom: 20px;
      top: 20px;
    }
    span {
      font-family: var(--headingFontFamily);
    }
    select {
      background-color: #edeff5;
      color: var(--blackColor);
      border-radius: 30px;
      margin-left: 10px;
      cursor: pointer;
      display: block;
      width: 200px;
      height: 45px;
      outline: 0;
      border: 0;
      padding: {
        left: 15px;
        right: 15px;
      }
      font: {
        family: var(--fontFamily);
        size: var(--fontSize);
      }
    }
  }
  .social-links {
    padding-left: 0;
    list-style-type: none;
    margin: {
      top: 20px;
      bottom: 0;
    }
    li {
      span {
        top: -2px;
        position: relative;
        font-family: var(--headingFontFamily);
      }
      a {
        background-color: #edeff5;
        color: var(--heraBlueColor);
        display: inline-block;
        border-radius: 50%;
        position: relative;
        text-align: center;
        margin-left: 5px;
        font-size: 14px;
        height: 32px;
        width: 32px;

        i {
          left: 0;
          right: 0;
          top: 50%;
          line-height: 1;
          margin-top: 1px;
          position: absolute;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: var(--heraBlueColor);
          color: var(--whiteColor);
        }
      }
    }
  }
}
.v-btn--variant-elevated {
  box-shadow: none;
  background: transparent;
}
.v-icon--size-default {
  font-size: 39px;
  position: absolute;
  color: var(--heraBlueColor);
}

@media only screen and (max-width: 767px) {
  .product-content {
    margin-top: 25px;
    padding-left: 0;

    h4 {
      margin-bottom: 5px;
    }
    .rating {
      margin-bottom: 15px;
    }
    .info {
      margin-bottom: 0;

      li {
        font-size: 15px;
        padding: 12px 15px 12px 62px;

        i {
          left: 15px;
          width: 38px;
          height: 38px;
        }
        span {
          font-size: 13px;
          margin-bottom: 3px;
        }
      }
    }
    .product-size {
      margin: {
        bottom: 15px;
        top: 15px;
      }
      select {
        width: 170px;
        font-size: 14px;
      }
    }
    .social-links {
      margin-top: 15px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-content {
    margin-top: 25px;
    padding-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product-content {
    padding-left: 0;

    .info {
      li {
        font-size: 15px;
        margin-right: 10px;
      }
    }
  }
}
</style>