<template>
  <v-alert
    :class="`alert d-flex justify-space-between align-items-center`"
    closable
    @click:close="closeAlert"
    :type="type"
  >
    {{ message }}
  </v-alert>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "AlertComponent",
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String as () => "success" | "error" | "warning" | "info",
      default: "success",
      required: true,
    },
  },
  setup(props, { emit }) {
    const closeAlert = () => {
      emit("close-alert", true);
    };

    onMounted(() => {
      setTimeout(() => {
        closeAlert();
      }, 3500);
    });

    return {
      closeAlert,
    };
  },
});
</script>
