<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <button
        class="profile-btn main-color border-none bg-transparent position-relative"
        v-bind="props"
      >
        <v-img
          src="@/assets/img/admin.jpg"
          class="rounded-circle"
          alt="admin"
        />

        <span> {{ userTypeMessage }} </span>
        <span class="fw-semibold">Olá, {{ user?.name ?? "Usuário" }}</span>
      </button>
    </template>

    <v-list class="profile-menu-dropdown">
      <ul class="m-0">
        <li>
          <router-link
            v-if="user.type == 'ADMIN'"
            to="configuracoes/politica-de-privacidade"
          >
            <i class="ri-settings-3-line"></i>
            Configurações
          </router-link>

          <router-link
            v-if="user.type == 'BUSINESS'"
            to="/configuracoes/enderecos"
          >
            <i class="ri-settings-3-line"></i>
            Configurações
          </router-link>
        </li>

        <!-- <li>
          <router-link to="/configuracoes/perfil">
            <i class="ri-pencil-line"></i>
            Editar Perfil
          </router-link>
        </li>

        <li>
          <router-link to="/configuracoes/senha">
            <i class="ri-lock-line"></i>
            Alterar Senha
          </router-link>
        </li> -->

        <li v-on:click="handleLogout()">
          <router-link to="/">
            <i class="ri-shut-down-line"></i>
            Sair da conta
          </router-link>
        </li>
      </ul>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";

import router from "@/router";

import { useAuthStore } from "@/stores/auth.store";

import { useAuth } from "@/composables/useAuth";

export default defineComponent({
  name: "AdminProfile",
  components: {},
  setup() {
    const authStore = useAuthStore();

    const { logout } = useAuth();

    const user: Ref = ref(authStore.getUser);

    const userTypeMessage = ref("");

    switch (user.value?.type) {
      case "ADMIN":
        userTypeMessage.value = "Administrador";
        break;
      case "ATTENDANT":
        userTypeMessage.value = "Atendente";
        break;
      case "PARTNER":
        userTypeMessage.value = "Parceiro";
        break;
      case "BUSINESS":
        userTypeMessage.value = "Empresário";
        break;
      case "COLLABORATOR":
        userTypeMessage.value = "Colaborador";
        break;
      case "BENEFICIARY":
        userTypeMessage.value = "Beneficiário";
        break;
      default:
        userTypeMessage.value = "Tipo Desconhecido";
    }

    const handleLogout = async () => {
      logout().then(() => {
        authStore.setLogout();

        router.push({ name: "SignInPage" }).then(() => {
          router.go(0);
        });
      });
    };

    return {
      user,
      userTypeMessage,
      handleLogout,
    };
  },
});
</script>
