<template>
  <v-autocomplete
    label="Selecione um Colaborador"
    :loading="isLoadingCollaboratorsInfo"
    :item-value="'userId'"
    :item-title="'name'"
    :return-object="true"
    :items="users"
    :clearable="false"
    :hide-details="!required"
    :no-data-text="
      isLoadingCollaboratorsInfo == true
        ? 'Carregando...'
        : 'Não há nenhum colaborador'
    "
    @update:menu="(value) => (isSelectMenuOpen = value)"
    @update:model-value="(value) => $emit('update:value', value)"
  >
    <template v-slot:no-data>
      <div class="text-center">
        {{
          isLoadingCollaboratorsInfo
            ? "Carregando..."
            : "Não há nenhum colaborador"
        }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import { useUser } from "@/composables/useUser";

export default defineComponent({
  name: "AutoCompleteCollaborators",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  setup() {
    const authStore = useAuthStore();

    const { users, getAllCollaborators } = useUser();

    const user: Ref = ref(authStore.getUser);

    const isSelectMenuOpen = ref(false);
    const isLoadingCollaboratorsInfo = ref(false);

    const fetchCollaboratorsData = async () => {
      try {
        isLoadingCollaboratorsInfo.value = true;

        getAllCollaborators(1, 1000).then(() => {
          isLoadingCollaboratorsInfo.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de colaboradores:", error);
      }
    };

    watch(
      () => isSelectMenuOpen.value,
      () => {
        fetchCollaboratorsData();
      },
      {
        once: true,
      }
    );

    return {
      isSelectMenuOpen,
      isLoadingCollaboratorsInfo,
      users,
    };
  },
});
</script>

<style>
.v-select .v-select__selection-text {
  overflow: hidden !important;
}
</style>
