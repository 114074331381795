<template>
  <v-row>
    <v-col cols="12" lg="12" md="12">
      <v-card v-if="isLoading" class="pingo-card">
        <div v-for="n in 1" :key="n">
          <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>
      </v-card>

      <v-card v-if="!isLoading" class="pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Informações de Entrega</h5>
        </div>
        <div class="box">
          <h5 class="fw-semibold mb-15">{{ user.name }}</h5>
          <ul class="list ps-0 mb-0 mt-0">
            <li
              class="gray-color position-relative"
              v-for="(address, index) in defaultAddresses"
              :key="index"
            >
              <span class="fw-medium d-inline-block">Endereço :</span>
              {{ address.street }}, {{ address.number }},
              {{ address.neighborhood }}, {{ address.city }},
              {{ address.state }}, {{ address.zipCode }},
              {{ address.country }}
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>

    <!-- Uncomment and adjust the following sections as needed
    <v-col cols="12" lg="4" md="12">
      <v-card class="mb-25 pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Billing Information</h5>
        </div>
        <div class="box">
          <h5 class="fw-semibold mb-15">{{ user.name }}</h5>
          <ul class="list ps-0 mb-0 mt-0">
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Payment Type :</span>
              Credit Card
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Provider :</span>
              Visa ending in 2851
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">CVV :</span>
              xxx
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" lg="4" md="12">
      <v-card class="mb-25 pingo-card">
        <div class="v-card-header border-bottom">
          <h5 class="mb-0">Delivery Info</h5>
        </div>
        <div class="box">
          <h5 class="fw-semibold mb-15">UPS Delivery</h5>
          <ul class="list ps-0 mb-0 mt-0">
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Order ID :</span>
              xxxx235
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Payment Mode :</span>
              COD
            </li>
            <li class="gray-color position-relative">
              <span class="fw-medium d-inline-block">Email :</span>
              info@contact.com
            </li>
          </ul>
        </div>
      </v-card>
    </v-col>
    -->
  </v-row>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";

interface Address {
  addressId: number;
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  neighborhood: string;
  number: string;
  complement: string;
  isDefault: number;
}

interface User {
  name: string;
  addresses: Address[];
  // phone: string;
  // email: string;
}

export default defineComponent({
  name: "OrderInformation",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const defaultAddresses = computed(() =>
      props.user?.addresses?.filter((address) => address.isDefault === 1)
    );

    return {
      defaultAddresses,
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
