import BaseService from "@/shared/base.service";

import { useAlertStore } from "@/stores/alert.store";

import type { PaginationResponse } from "@/types/response/pagination";

import type { InvoiceResponse } from "@/types/response/invoice";
import type { InvoiceDetailsResponse } from "@/types/response/invoiceDetails";
import type { UpdateInvoiceRequest } from "@/types/request/update/updateInvoice";

export class InvoiceService {
  static async GetAllInvoices(
    currentPage: number,
    totalPerPage: number,
    userId?: number
  ): Promise<PaginationResponse<InvoiceResponse> | null> {
    try {
      const params = {
        userId,
        currentPage,
        totalPerPage,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<
        PaginationResponse<InvoiceResponse>
      >("Invoice", queryParams);

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllInvoices:", error);
      return null;
    }
  }

  static async GetInvoiceById(
    id: number
  ): Promise<InvoiceDetailsResponse | null> {
    try {
      const response = await BaseService.get<InvoiceDetailsResponse>(
        `Invoice/${id}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetInvoiceById:", error);
      return null;
    }
  }

  static async UpdateInvoice(
    id: number,
    payload: UpdateInvoiceRequest
  ): Promise<object | null> {
    try {
      const response = await BaseService.patch<object>(`Invoice/${id}`, {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Fatura atualizada com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in UpdateInvoice:", error);
      return null;
    }
  }
}
