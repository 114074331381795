import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box"
}
const _hoisted_2 = { class: "fw-semibold mb-15" }
const _hoisted_3 = { class: "list ps-0 mb-0 mt-0" }
const _hoisted_4 = { class: "gray-color position-relative" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "pingo-form-group without-icon" }
const _hoisted_7 = {
  key: 0,
  class: "pingo-form-group without-icon mb-0"
}
const _hoisted_8 = { class: "v-card-header border-bottom" }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_10 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_11 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_12 = { class: "d-flex align-items-center product-info" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "fw-semibold mb-1" }
const _hoisted_15 = { class: "d-block muted-color fw-medium" }
const _hoisted_16 = { class: "gray-color" }
const _hoisted_17 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_18 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_19 = { class: "d-flex justify-content-center align-items-center ga-6" }
const _hoisted_20 = { class: "table-responsive" }
const _hoisted_21 = { class: "table" }
const _hoisted_22 = { class: "fw-semibold text-end" }
const _hoisted_23 = { class: "fw-semibold text-end" }
const _hoisted_24 = { class: "fw-semibold text-end" }
const _hoisted_25 = { class: "fw-semibold text-end" }
const _hoisted_26 = { class: "d-flex align-items-center justify-space-between text-end ga-10 mt-20" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_AutoCompleteCollaborators = _resolveComponent("AutoCompleteCollaborators")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_AutoCompleteProducts = _resolveComponent("AutoCompleteProducts")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Novo Pedido",
      mainTitle: "Pedido"
    }),
    _createVNode(_component_v_form, {
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              lg: "3",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "mb-10 pingo-card" }, {
                  default: _withCtx(() => [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
                      _createElementVNode("h5", { class: "mb-0" }, "Informações do cliente")
                    ], -1)),
                    (_ctx.newOrderForm.user)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.newOrderForm.user.name), 1),
                          _createElementVNode("ul", _hoisted_3, [
                            _createElementVNode("li", _hoisted_4, [
                              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "fw-medium d-inline-block" }, "Email:", -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.newOrderForm.user.email), 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.newOrderForm.user == null)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _cache[12] || (_cache[12] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Agendado para ", -1)),
                            _createVNode(_component_TextInput, {
                              modelValue: _ctx.newOrderForm.dateReference,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newOrderForm.dateReference) = $event)),
                              type: 'date',
                              label: 'Digite aqui'
                            }, null, 8, ["modelValue"])
                          ]),
                          (
                _ctx.user.type == _ctx.UserType.ADMIN || _ctx.user.type == _ctx.UserType.BUSINESS
              )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Selecione um colaborador ", -1)),
                                _createVNode(_component_AutoCompleteCollaborators, {
                                  modelValue: _ctx.newOrderForm.user,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newOrderForm.user) = $event)),
                                  required: true,
                                  "onUpdate:value": _cache[2] || (_cache[2] = (value) => _ctx.newOrderForm.user = value),
                                  "error-messages": 
                  _ctx.v$.userId.$errors ? _ctx.v$.userId.$errors[0]?.$message : undefined
                
                                }, null, 8, ["modelValue", "error-messages"])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.newOrderForm.user)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      class: "text-capitalize w-100",
                      color: "blue-darken-3",
                      variant: "tonal",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.newOrderForm.user = undefined))
                    }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" Alterar Cliente ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              lg: "9",
              md: "9"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "create-project-card pingo-card" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[22] || (_cache[22] = _createElementVNode("h5", { class: "mb-0" }, "Informações do Pedido", -1)),
                      _createVNode(_component_v_dialog, {
                        modelValue: _ctx.isNewItemDialogOpen,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isNewItemDialogOpen) = $event)),
                        width: "480",
                        scrollable: ""
                      }, {
                        activator: _withCtx(({ props: activatorProps }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            class: "text-capitalize",
                            color: "blue-darken-3",
                            variant: "tonal"
                          }, activatorProps), {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode(" Adicionar produto ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(({ isActive }) => [
                          _createVNode(_component_v_form, {
                            onSubmit: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.addItemToItemsList()), ["prevent"]))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, {
                                class: "py-0",
                                title: "Adicionar novo item"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_divider),
                                  _createVNode(_component_v_card_text, { class: "px-4" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_9, [
                                        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                          _createTextVNode(" Produto "),
                                          _createElementVNode("span", null, "*")
                                        ], -1)),
                                        _createVNode(_component_AutoCompleteProducts, {
                                          required: true,
                                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.mapProductToItem($event))),
                                          "error-messages": 
                            _ctx.v$item.itemId.$errors
                              ? _ctx.v$item.itemId.$errors[0]?.$message
                              : undefined
                          
                                        }, null, 8, ["error-messages"])
                                      ]),
                                      _createElementVNode("div", _hoisted_10, [
                                        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                          _createTextVNode(" Quantidade "),
                                          _createElementVNode("span", null, "*")
                                        ], -1)),
                                        _createVNode(_component_TextInput, {
                                          label: 'Digite a Quantidade aqui',
                                          type: 'number',
                                          modelValue: _ctx.newOrderItemForm.quantity,
                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newOrderItemForm.quantity) = $event)),
                                          "onUpdate:value": _cache[6] || (_cache[6] = (value) => _ctx.newOrderItemForm.quantity = value),
                                          "error-messages": 
                            _ctx.v$item.quantity.$errors
                              ? _ctx.v$item.quantity.$errors[0]?.$message
                              : undefined
                          
                                        }, null, 8, ["modelValue", "error-messages"])
                                      ]),
                                      _createElementVNode("div", _hoisted_11, [
                                        _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Observação ", -1)),
                                        _createVNode(_component_TextInput, {
                                          label: 'Digite o Observação aqui',
                                          modelValue: _ctx.newOrderItemForm.observation,
                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newOrderItemForm.observation) = $event))
                                        }, null, 8, ["modelValue"])
                                      ])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_actions, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        class: "text-capitalize",
                                        onClick: ($event: any) => (isActive.value = false)
                                      }, {
                                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                                          _createTextVNode(" Fechar ")
                                        ])),
                                        _: 2
                                      }, 1032, ["onClick"]),
                                      _createVNode(_component_v_spacer),
                                      _createVNode(_component_v_btn, {
                                        class: "text-capitalize",
                                        color: "green-darken-3",
                                        variant: "tonal",
                                        type: "submit"
                                      }, {
                                        default: _withCtx(() => _cache[21] || (_cache[21] = [
                                          _createTextVNode(" Confirmar ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createVNode(_component_v_data_table, {
                      class: "table",
                      headers: _ctx.newOrderColumns,
                      items: _ctx.newOrderForm.items,
                      "hide-default-footer": ""
                    }, {
                      [`item.name`]: _withCtx(({ item }) => [
                        _createElementVNode("a", _hoisted_12, [
                          _createVNode(_component_v_img, {
                            src: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/1022px-Placeholder_view_vector.svg.png?20220519031949",
                            class: "border-radius me-12",
                            width: "60",
                            alt: "product"
                          }),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("h5", _hoisted_14, _toDisplayString(item.name), 1),
                            _createElementVNode("span", _hoisted_15, [
                              _cache[23] || (_cache[23] = _createTextVNode(" Descrição: ")),
                              _createElementVNode("span", _hoisted_16, _toDisplayString(item.description), 1)
                            ])
                          ])
                        ])
                      ]),
                      [`item.price`]: _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.price ?? 0)), 1)
                      ]),
                      [`item.observation`]: _withCtx(({ item }) => [
                        (item.observation)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(item.observation), 1))
                          : _createCommentVNode("", true),
                        (!item.observation)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, " N/A "))
                          : _createCommentVNode("", true)
                      ]),
                      [`item.total`]: _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format((item.price ?? 0) * item.quantity)), 1)
                      ]),
                      [`item.actions`]: _withCtx(({ item }) => [
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_v_btn, {
                            class: "text-capitalize w-100",
                            color: "red-darken-3",
                            variant: "tonal",
                            onClick: ($event: any) => (_ctx.removeItemFromItemList(item.itemId))
                          }, {
                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode(" Remover ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      "no-data": _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode(" Não há nenhum produto ")
                      ])),
                      loading: _withCtx(() => [
                        _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
                      ]),
                      _: 2
                    }, 1032, ["headers", "items"]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("table", _hoisted_21, [
                        _createElementVNode("tbody", null, [
                          _createElementVNode("tr", null, [
                            _cache[26] || (_cache[26] = _createElementVNode("td", null, null, -1)),
                            _cache[27] || (_cache[27] = _createElementVNode("td", null, null, -1)),
                            _cache[28] || (_cache[28] = _createElementVNode("td", null, null, -1)),
                            _cache[29] || (_cache[29] = _createElementVNode("td", null, null, -1)),
                            _cache[30] || (_cache[30] = _createElementVNode("td", { class: "text-end fw-semibold gray-color" }, "Sub Total:", -1)),
                            _createElementVNode("td", _hoisted_22, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(_ctx.subtotal)), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[31] || (_cache[31] = _createElementVNode("td", null, null, -1)),
                            _cache[32] || (_cache[32] = _createElementVNode("td", null, null, -1)),
                            _cache[33] || (_cache[33] = _createElementVNode("td", null, null, -1)),
                            _cache[34] || (_cache[34] = _createElementVNode("td", null, null, -1)),
                            _cache[35] || (_cache[35] = _createElementVNode("td", { class: "text-end fw-semibold gray-color" }, "Desconto:", -1)),
                            _createElementVNode("td", _hoisted_23, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(_ctx.discount)), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[36] || (_cache[36] = _createElementVNode("td", null, null, -1)),
                            _cache[37] || (_cache[37] = _createElementVNode("td", null, null, -1)),
                            _cache[38] || (_cache[38] = _createElementVNode("td", null, null, -1)),
                            _cache[39] || (_cache[39] = _createElementVNode("td", null, null, -1)),
                            _cache[40] || (_cache[40] = _createElementVNode("td", { class: "text-end fw-semibold gray-color" }, " Taxa de Entrega: ", -1)),
                            _createElementVNode("td", _hoisted_24, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(_ctx.deliveryFee)), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[41] || (_cache[41] = _createElementVNode("td", null, null, -1)),
                            _cache[42] || (_cache[42] = _createElementVNode("td", null, null, -1)),
                            _cache[43] || (_cache[43] = _createElementVNode("td", null, null, -1)),
                            _cache[44] || (_cache[44] = _createElementVNode("td", null, null, -1)),
                            _cache[45] || (_cache[45] = _createElementVNode("td", { class: "text-end fw-semibold" }, "Total (BRL):", -1)),
                            _createElementVNode("td", _hoisted_25, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(_ctx.total)), 1)
                          ])
                        ])
                      ])
                    ]),
                    _createVNode(_component_v_row),
                    _createElementVNode("div", _hoisted_26, [
                      (_ctx.newOrderForm.items.length == 0)
                        ? (_openBlock(), _createBlock(_component_v_alert, {
                            key: 0,
                            color: "red-lighten-1",
                            type: "error",
                            class: "text-start"
                          }, {
                            default: _withCtx(() => [
                              _cache[46] || (_cache[46] = _createTextVNode(" Selecione ")),
                              (
                  _ctx.user.type == _ctx.UserType.ADMIN || _ctx.user.type == _ctx.UserType.BUSINESS
                )
                                ? (_openBlock(), _createElementBlock("span", _hoisted_27, " um colaborador e "))
                                : _createCommentVNode("", true),
                              _cache[47] || (_cache[47] = _createElementVNode("strong", null, " ao menos um ", -1)),
                              _cache[48] || (_cache[48] = _createTextVNode(" produto para criar um pedido. "))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        type: "submit",
                        class: "default-btn ms-auto",
                        disabled: _ctx.isLoadingOrderRequest
                      }, [
                        (_ctx.isLoadingOrderRequest)
                          ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                              key: 0,
                              indeterminate: "",
                              size: "18",
                              width: "2"
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.isLoadingOrderRequest)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_29, " Finalizando Pedido... "))
                          : _createCommentVNode("", true),
                        (!_ctx.isLoadingOrderRequest)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_30, " Finalizar Pedido "))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_28)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}