<!-- FinancialPage.vue -->
<template>
  <PageTitle pageTitle="Financeiro" mainTitle="Financeiro" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Financeiro</h5>

      <p>Atualizado por último em {{ new Date().toLocaleString() }}</p>
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="filteredColumns"
          :items="invoices"
          :loading="isLoadingFinancials"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <!-- Templates para renderização de colunas -->
          <template v-slot:[`item.invoiceId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.totalAmount`]="{ value }">
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span
              :class="{
                'text-capitalize badge rounded-pill text-soft-danger':
                  item.status === 'cancelled',
                'text-capitalize badge rounded-pill text-soft-warning':
                  item.status === 'pending',
                'text-capitalize badge rounded-pill text-soft-info':
                  item.status === 'partially_paid',
                'text-capitalize badge rounded-pill text-soft-pingo':
                  item.status === 'refunded',
                'text-capitalize badge rounded-pill text-soft-success':
                  item.status === 'paid',
                'text-capitalize badge rounded-pill text-soft-primary':
                  item.status === 'overdue',
              }"
            >
              {{ mapStatusTitle(item.status as InvoiceStatus) }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <v-dialog
                width="380"
                scrollable
                v-model="isInvoiceStatusDialogOpen"
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <div
                    v-if="user.type === 'ADMIN' || user.type === 'ATTENDANT'"
                    class="d-flex ga-2"
                  >
                    <v-btn
                      v-if="item.status != InvoiceStatus.Paid"
                      class="text-capitalize"
                      color="blue-darken-3"
                      variant="tonal"
                      v-bind="activatorProps"
                      type="button"
                    >
                      Atualizar Status
                    </v-btn>
                  </div>
                </template>

                <template v-slot:default="">
                  <v-card class="py-0" title="Atualizar Status">
                    <v-divider></v-divider>

                    <v-card-text class="px-4">
                      <p class="mb-10">
                        Selecione o novo status para a fatura abaixo.
                        Certifique-se de que a alteração seja apropriada para o
                        estado atual da fatura.
                      </p>

                      <SelectInvoiceStatus
                        v-model="updateInvoiceForm.status"
                        :currentStatus="updateInvoiceForm.status as InvoiceStatus"
                        :allStatuses="allStatuses"
                        :error-messages="
                          v$.status.$errors
                            ? v$.status.$errors[0]?.$message
                            : undefined
                        "
                      />
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        class="text-capitalize"
                        @click="
                          isInvoiceStatusDialogOpen = false;
                          updateInvoiceForm.status =
                            item.status as InvoiceStatus;
                        "
                      >
                        Fechar
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="text-capitalize"
                        color="blue-darken-3"
                        variant="tonal"
                        @click="updateInvoiceStatus"
                      >
                        Atualizar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <router-link :to="`/financeiro/${item.invoiceId}`">
                <v-btn
                  v-if="user.type === 'ADMIN' || user.type === 'BUSINESS'"
                  class="text-capitalize"
                  variant="tonal"
                  type="button"
                >
                  Ver detalhes
                </v-btn>
              </router-link>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhuma fatura </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="handleUpdatePageProps"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, Ref } from "vue";

import useVuelidate from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";

import { useInvoice } from "@/composables/useInvoice";

import PageTitle from "@/components/Common/PageTitle.vue";
import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";
import SelectInvoiceStatus from "@/components/Forms/AdvancedElements/Select/SelectInvoiceStatus.vue";

import { UserType } from "@/types/enum/userType.enum";
import { InvoiceStatus } from "@/types/enum/invoiceStatus.enum";

import type { UpdateInvoiceRequest } from "@/types/request/update/updateInvoice";
import type { InvoiceResponse } from "@/types/response/invoice";

export default defineComponent({
  name: "FinancialPage",
  components: {
    PageTitle,
    ItemsPerPage,
    SelectInvoiceStatus,
  },

  setup() {
    const authStore = useAuthStore();

    const {
      columns,
      pagination,
      invoice,
      invoices,
      getAllInvoices,
      updateInvoiceForm,
      updateInvoiceFormRules,
      updateInvoice,
    } = useInvoice();

    const user: Ref = ref(authStore.getUser);

    const isInvoiceStatusDialogOpen = ref(false);
    const isLoadingFinancials = ref(false);

    const allStatuses: InvoiceStatus[] = [
      InvoiceStatus.Pending,
      InvoiceStatus.Paid,
      InvoiceStatus.Overdue,
      InvoiceStatus.PartiallyPaid,
      InvoiceStatus.Cancelled,
      InvoiceStatus.Refunded,
    ];

    const filteredColumns = computed(() => {
      switch (user.value.type) {
        case UserType.BUSINESS:
        case UserType.COLLABORATOR:
          return columns.value.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (column: any) => column.key !== "user.name"
          );

        default:
          return columns.value;
      }
    });

    const fetchFinancialsData = async () => {
      try {
        isLoadingFinancials.value = true;
        await getAllInvoices(
          pagination.value.currentPage,
          pagination.value.totalPerPage
        );
        isLoadingFinancials.value = false;
      } catch (error) {
        console.error("Erro ao buscar dados de faturas:", error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      await fetchFinancialsData();
    };

    const mapInvoiceStatus = (
      item: InvoiceResponse,
      newStatus: InvoiceStatus
    ) => {
      invoice.value.invoiceId = item.invoiceId;
      invoice.value.status = item.status = newStatus;
    };

    const updateInvoiceStatus = async () => {
      if (!invoice.value) return;

      const result = await v$.value.$validate();

      if (!result) return;

      const updatedInvoicePayload: UpdateInvoiceRequest = {
        status: updateInvoiceForm.status as InvoiceStatus,
      };

      const response = await updateInvoice(
        invoice.value.invoiceId,
        updatedInvoicePayload
      );

      if (response) {
        isInvoiceStatusDialogOpen.value = false;
        await fetchFinancialsData();
      }
    };

    const mapStatusTitle = (status: InvoiceStatus) => {
      switch (status) {
        case InvoiceStatus.Pending:
          return "Pendente";
        case InvoiceStatus.Paid:
          return "Pago";
        case InvoiceStatus.Overdue:
          return "Vencido";
        case InvoiceStatus.PartiallyPaid:
          return "Parcialmente Pago";
        case InvoiceStatus.Cancelled:
          return "Cancelado";
        case InvoiceStatus.Refunded:
          return "Reembolsado";
        default:
          return status;
      }
    };

    onMounted(fetchFinancialsData);

    const v$ = useVuelidate(updateInvoiceFormRules, updateInvoiceForm);

    return {
      v$,
      user,
      invoices,
      pagination,
      isLoadingFinancials,
      isInvoiceStatusDialogOpen,
      allStatuses,
      filteredColumns,
      fetchFinancialsData,
      handleUpdatePageProps,
      mapStatusTitle,
      mapInvoiceStatus,
      updateInvoiceStatus,
      updateInvoiceForm,
      updateInvoiceFormRules,
      InvoiceStatus,
    };
  },
});
</script>
