import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    label: "Selecione o status",
    items: _ctx.statusOptions,
    modelValue: _ctx.selectedStatus,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatus) = $event)),
      _ctx.handleStatusChange
    ]
  }, null, 8, ["items", "modelValue", "onUpdate:modelValue"]))
}