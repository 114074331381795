<template>
  <PageTitle pageTitle="Atualizar Produto" mainTitle="Produto" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom d-flex justify-space-between">
            <h5 class="mb-0">Informações Básicas</h5>

            <v-switch
              :label="updateProductForm.isActive == 1 ? 'Ativo' : 'Inativo'"
              v-model="updateProductForm.isActive"
              false-value="0"
              true-value="1"
              :hide-details="true"
            ></v-switch>
          </div>

          <v-row>
            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nome
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o Nome aqui'"
                  v-model="updateProductForm.name"
                  :error-messages="
                    v$.name.$errors ? v$.name.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Valor <span>*</span>
                </label>

                <MonetaryInput
                  :label="'Digite o preço aqui'"
                  v-model="updateProductForm.price"
                  @update:value="(value: string) => (updateProductForm.price = value)"
                  :error-messages="
                    v$.price.$errors ? v$.price.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="12" md="12">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Descrição
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a Descrição aqui'"
                  v-model="updateProductForm.description"
                  :error-messages="
                    v$.description.$errors
                      ? v$.description.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>

          <div class="text-end">
            <button type="submit" class="default-btn">
              <v-progress-circular
                v-if="isLoadingProductRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>

              <span v-if="isLoadingProductRequest">
                Atualizando Produto...
              </span>
              <span v-if="!isLoadingProductRequest"> Atualizar Produto </span>
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>

  <!-- <CreateProduct /> -->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useProduct } from "@/composables/useProduct";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import MonetaryInput from "@/components/Forms/AdvancedElements/Input/MonetaryInput.vue";

// import CreateProduct from "@/components/eCommerce/CreateProduct/CreateProduct.vue";

export default defineComponent({
  name: "EditProductPage",
  components: {
    PageTitle,
    TextInput,
    MonetaryInput,
    // CreateProduct,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      product,
      updateProductForm,
      updateProductFormRules,
      getProductById,
      updateProduct,
    } = useProduct();

    const isLoadingProductData = ref(false);
    const isLoadingProductRequest = ref(false);

    const fetchProductData = async (id: number) => {
      try {
        isLoadingProductData.value = true;

        getProductById(id).then(() => {
          isLoadingProductData.value = false;

          updateProductForm.name = product.value?.name ?? "";
          updateProductForm.price = product.value?.price.toString() ?? "";
          updateProductForm.description = product.value?.description ?? "";
          updateProductForm.isActive = product.value?.isActive == true ? 1 : 0;
        });
      } catch (error) {
        console.error("Erro ao buscar detalhes de produto:", error);
      }
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      const productId = parseInt(route.params.id.toString());

      isLoadingProductRequest.value = !isLoadingProductRequest.value;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...updateProductFormPayload } = updateProductForm;

      updateProductFormPayload.price = updateProductFormPayload.price
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".")
        .trim();

      const response = await updateProduct(productId, updateProductFormPayload);

      isLoadingProductRequest.value = !isLoadingProductRequest.value;

      if (response) router.go(-1);
    };

    onMounted(() => {
      const productId = parseInt(route.params.id.toString());
      fetchProductData(productId);
    });

    const v$ = useVuelidate(updateProductFormRules, updateProductForm);

    return {
      v$,
      route,
      isLoadingProductRequest,
      updateProductForm,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
