import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header border-bottom" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "gray-color" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "d-flex ga-2"
}
const _hoisted_6 = { class: "d-flex align-items-center product-info py-2" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "fw-semibold mb-1" }
const _hoisted_9 = { class: "d-block muted-color fw-medium" }
const _hoisted_10 = { class: "gray-color" }
const _hoisted_11 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_12 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_13 = { class: "table-responsive" }
const _hoisted_14 = { class: "table" }
const _hoisted_15 = { class: "fw-semibold text-end" }
const _hoisted_16 = { class: "fw-semibold text-end" }
const _hoisted_17 = { class: "fw-semibold text-end" }
const _hoisted_18 = { class: "fw-semibold text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_TrackOrder = _resolveComponent("TrackOrder")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_OrderInformation = _resolveComponent("OrderInformation")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_PrinterComponent = _resolveComponent("PrinterComponent")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_SelectOrderStatus = _resolveComponent("SelectOrderStatus")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Detalhes de Pedido",
      mainTitle: "Pedidos"
    }),
    _createVNode(_component_v_row, { class: "justify-content-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "4",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TrackOrder, {
              "is-loading": _ctx.isLoadingOrderInfo,
              order: _ctx.order
            }, null, 8, ["is-loading", "order"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "8",
          md: "12"
        }, {
          default: _withCtx(() => [
            (_ctx.isDifferentDay)
              ? (_openBlock(), _createBlock(_component_v_alert, {
                  key: 0,
                  color: "red-lighten-1",
                  type: "warning",
                  class: "text-start mb-10"
                }, {
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createTextVNode(" Pedido agendado para ")),
                    _createElementVNode("strong", null, _toDisplayString(new Date(_ctx.order.dateReference).toLocaleDateString("pt-BR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })), 1),
                    _cache[5] || (_cache[5] = _createTextVNode(". "))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_OrderInformation, {
              "is-loading": _ctx.isLoadingOrderInfo,
              user: _ctx.order.user
            }, null, 8, ["is-loading", "user"]),
            _createVNode(_component_v_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "12",
                      md: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { class: "create-project-card pingo-card" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createElementVNode("div", _hoisted_2, [
                                _cache[6] || (_cache[6] = _createElementVNode("h5", { class: "mb-0" }, "Informações do Pedido", -1)),
                                _createElementVNode("span", _hoisted_3, " Pedido criado em " + _toDisplayString(new Intl.DateTimeFormat("pt-BR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(_ctx.order.createdAt))), 1)
                              ]),
                              (
                    _ctx.order.status !== _ctx.OrderStatus.Delivered &&
                    _ctx.order.status !== _ctx.OrderStatus.Cancelled
                  )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_v_dialog, {
                                      width: "380",
                                      scrollable: "",
                                      modelValue: _ctx.isDialogOpen,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDialogOpen) = $event))
                                    }, {
                                      activator: _withCtx(({ props: activatorProps }) => [
                                        (_ctx.user.type == 'ADMIN' || _ctx.user.type == 'ATTENDANT')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                              _createVNode(_component_v_btn, _mergeProps({
                                                class: "text-capitalize",
                                                color: "blue-darken-3",
                                                variant: "tonal"
                                              }, activatorProps), {
                                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                  _createTextVNode(" Atualizar status ")
                                                ])),
                                                _: 2
                                              }, 1040),
                                              _createVNode(_component_PrinterComponent, { order: _ctx.order }, null, 8, ["order"])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_form, {
                                          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.updateOrderStatus()), ["prevent"]))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card, {
                                              class: "py-0",
                                              title: "Atualizar Status"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_divider),
                                                _createVNode(_component_v_card_text, { class: "px-4" }, {
                                                  default: _withCtx(() => [
                                                    _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mb-10" }, " Selecione o novo status para o pedido abaixo. Certifique-se de que a alteração seja apropriada para o estado atual da pedido. ", -1)),
                                                    _createVNode(_component_SelectOrderStatus, {
                                                      currentStatus: _ctx.order.status as OrderStatus,
                                                      allStatuses: _ctx.filteredStatuses as OrderStatus[],
                                                      "onUpdate:value": _cache[0] || (_cache[0] = 
                                (value) => (_ctx.updateOrderForm.status = value)
                              ),
                                                      required: true,
                                                      "error-messages": 
                                _ctx.v$.status.$errors
                                  ? _ctx.v$.status.$errors[0]?.$message
                                  : undefined
                              
                                                    }, null, 8, ["currentStatus", "allStatuses", "error-messages"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_divider),
                                                _createVNode(_component_v_card_actions, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_btn, {
                                                      class: "text-capitalize",
                                                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDialogOpen = false))
                                                    }, {
                                                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                        _createTextVNode(" Fechar ")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_spacer),
                                                    _createVNode(_component_v_btn, {
                                                      class: "text-capitalize",
                                                      color: "blue-darken-3",
                                                      variant: "tonal",
                                                      type: "submit"
                                                    }, {
                                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                        _createTextVNode(" Atualizar ")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createVNode(_component_v_data_table, {
                              class: "table",
                              headers: _ctx.newOrderColumns,
                              items: _ctx.order.items,
                              "hide-default-footer": ""
                            }, {
                              [`item.name`]: _withCtx(({ item }) => [
                                _createElementVNode("a", _hoisted_6, [
                                  _createVNode(_component_v_img, {
                                    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/1022px-Placeholder_view_vector.svg.png?20220519031949",
                                    class: "border-radius me-12",
                                    width: "60",
                                    alt: "product",
                                    style: {"min-width":"60px"}
                                  }),
                                  _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("h5", _hoisted_8, _toDisplayString(item.item?.name ?? "Desconhecido"), 1),
                                    _createElementVNode("span", _hoisted_9, [
                                      _cache[11] || (_cache[11] = _createTextVNode(" Descrição: ")),
                                      _createElementVNode("span", _hoisted_10, _toDisplayString(item.item?.description ?? "Não informado"), 1)
                                    ])
                                  ])
                                ])
                              ]),
                              [`item.price`]: _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(parseFloat(item.unitPrice) ?? 0)), 1)
                              ]),
                              [`item.observation`]: _withCtx(({ item }) => [
                                (item.observations)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.observations), 1))
                                  : _createCommentVNode("", true),
                                (!item.observations)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, " N/A "))
                                  : _createCommentVNode("", true)
                              ]),
                              [`item.total`]: _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format((parseFloat(item.unitPrice) ?? 0) * item.quantity)), 1)
                              ]),
                              "no-data": _withCtx(() => _cache[12] || (_cache[12] = [
                                _createTextVNode(" Não há nenhum produto ")
                              ])),
                              loading: _withCtx(() => [
                                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
                              ]),
                              _: 2
                            }, 1032, ["headers", "items"]),
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("table", _hoisted_14, [
                                _createElementVNode("tbody", null, [
                                  _createElementVNode("tr", null, [
                                    _cache[13] || (_cache[13] = _createElementVNode("td", null, null, -1)),
                                    _cache[14] || (_cache[14] = _createElementVNode("td", null, null, -1)),
                                    _cache[15] || (_cache[15] = _createElementVNode("td", null, null, -1)),
                                    _cache[16] || (_cache[16] = _createElementVNode("td", null, null, -1)),
                                    _cache[17] || (_cache[17] = _createElementVNode("td", { class: "text-end fw-semibold gray-color" }, " Sub Total: ", -1)),
                                    _createElementVNode("td", _hoisted_15, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(_ctx.subtotal)), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[18] || (_cache[18] = _createElementVNode("td", null, null, -1)),
                                    _cache[19] || (_cache[19] = _createElementVNode("td", null, null, -1)),
                                    _cache[20] || (_cache[20] = _createElementVNode("td", null, null, -1)),
                                    _cache[21] || (_cache[21] = _createElementVNode("td", null, null, -1)),
                                    _cache[22] || (_cache[22] = _createElementVNode("td", { class: "text-end fw-semibold gray-color" }, "Desconto:", -1)),
                                    _createElementVNode("td", _hoisted_16, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(_ctx.discount)), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[23] || (_cache[23] = _createElementVNode("td", null, null, -1)),
                                    _cache[24] || (_cache[24] = _createElementVNode("td", null, null, -1)),
                                    _cache[25] || (_cache[25] = _createElementVNode("td", null, null, -1)),
                                    _cache[26] || (_cache[26] = _createElementVNode("td", null, null, -1)),
                                    _cache[27] || (_cache[27] = _createElementVNode("td", { class: "text-end fw-semibold gray-color" }, " Taxa de Entrega: ", -1)),
                                    _createElementVNode("td", _hoisted_17, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(_ctx.deliveryFee)), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[28] || (_cache[28] = _createElementVNode("td", null, null, -1)),
                                    _cache[29] || (_cache[29] = _createElementVNode("td", null, null, -1)),
                                    _cache[30] || (_cache[30] = _createElementVNode("td", null, null, -1)),
                                    _cache[31] || (_cache[31] = _createElementVNode("td", null, null, -1)),
                                    _cache[32] || (_cache[32] = _createElementVNode("td", { class: "text-end fw-semibold" }, "Total (BRL):", -1)),
                                    _createElementVNode("td", _hoisted_18, _toDisplayString(new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(_ctx.total)), 1)
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}