/* eslint-disable @typescript-eslint/no-explicit-any */

import { computed } from "vue";

import { useI18n } from "vue-i18n";

import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email,
  sameAs,
  minValue,
  maxValue,
} from "@vuelidate/validators";

import { withI18nMessage } from "@/i18n";

export function useBaseComposable() {
  const { t } = useI18n();

  // Função para aplicar validação com i18n
  const createValidationRules = (rules: any) => {
    return computed(() => {
      return Object.keys(rules).reduce((acc: any, key: string) => {
        acc[key] = {
          ...Object.keys(rules[key]).reduce(
            (fieldAcc: any, ruleKey: string) => {
              fieldAcc[ruleKey] = withI18nMessage(
                rules[key][ruleKey].validator,
                rules[key][ruleKey].messageKey,
                rules[key][ruleKey].params || {}
              );

              return fieldAcc;
            },
            {}
          ),
        };
        return acc;
      }, {});
    });
  };

  return {
    t,
    createValidationRules,
    withI18nMessage,
    required,
    requiredIf,
    minValue,
    maxValue,
    minLength,
    maxLength,
    email,
    sameAs,
  };
}
