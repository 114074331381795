<template>
  <PageTitle pageTitle="Novo Produto" mainTitle="Produto" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col lg="12" md="12">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações Básicas</h5>
          </div>

          <v-row>
            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nome
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o Nome aqui'"
                  v-model="newProductForm.name"
                  :error-messages="
                    v$.name.$errors ? v$.name.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Valor <span>*</span>
                </label>

                <MonetaryInput
                  :label="'Digite o preço aqui'"
                  @update:value="(value: string) => (newProductForm.price = value)"
                  :error-messages="
                    v$.price.$errors ? v$.price.$errors[0]?.$message : undefined
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="12" md="12">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Descrição
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a Descrição aqui'"
                  v-model="newProductForm.description"
                  :error-messages="
                    v$.description.$errors
                      ? v$.description.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>

          <div class="text-end">
            <button type="submit" class="default-btn">
              <v-progress-circular
                v-if="isLoadingProductRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>

              <span v-if="isLoadingProductRequest"> Criando Produto... </span>
              <span v-if="!isLoadingProductRequest"> Criar Produto </span>
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>

  <!-- <CreateProduct /> -->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useProduct } from "@/composables/useProduct";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";
import MonetaryInput from "@/components/Forms/AdvancedElements/Input/MonetaryInput.vue";

// import CreateProduct from "@/components/eCommerce/CreateProduct/CreateProduct.vue";

export default defineComponent({
  name: "CreateProductPage",
  components: {
    PageTitle,
    TextInput,
    MonetaryInput,
    // CreateProduct,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { newProductForm, newProductFormRules, createProduct } = useProduct();

    const isLoadingProductRequest = ref(false);

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      isLoadingProductRequest.value = !isLoadingProductRequest.value;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...newProductFormPayload } = newProductForm;

      newProductFormPayload.price = newProductFormPayload.price
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".")
        .trim();

      const response = await createProduct(newProductFormPayload);

      isLoadingProductRequest.value = !isLoadingProductRequest.value;

      if (response) router.go(-1);
    };

    const v$ = useVuelidate(newProductFormRules, newProductForm);

    return {
      v$,
      route,
      isLoadingProductRequest,
      newProductForm,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
