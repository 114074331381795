import BaseService from "@/shared/base.service";

import { useAlertStore } from "@/stores/alert.store";

import type { SignInResponse } from "@/types/response/signIn";
import type { CreateUserRequest } from "@/types/request/create/createUser";

export class AuthService {
  static async SignIn(
    email: string,
    password: string
  ): Promise<SignInResponse | null> {
    try {
      const response = await BaseService.post<SignInResponse>("auth", {
        email: email,
        password: password,
      });

      return response ?? null;
    } catch (error) {
      console.error("Error in SignIn:", error);
      return null;
    }
  }

  static async SignUp(payload: CreateUserRequest): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("users", {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Usuário criado com sucesso.");
      }
      return response ?? null;
    } catch (error) {
      console.error("Error in SignUp:", error);
      return null;
    }
  }

  static async ForgotPassword(payload: object): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("auth/forgot_password", {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Email de recuperação enviado.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in ForgotPassword:", error);
      return null;
    }
  }

  static async ConfirmToken(payload: object): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("auth/confirm-token", {
        ...payload,
      });

      return response ?? null;
    } catch (error) {
      console.error("Error in ForgotPassword:", error);
      return null;
    }
  }

  static async ResetPassword(payload: object): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("auth/reset-password", {
        payload,
      });

      if (response) {
        useAlertStore().show("success", "Senha alterada com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in ResetPassword:", error);
      return null;
    }
  }

  static async RefreshToken(): Promise<SignInResponse | null> {
    try {
      const response = await BaseService.get<SignInResponse>("refresh_token");
      return response ?? null;
    } catch (error) {
      console.error("Error in RefreshToken:", error);
      return null;
    }
  }

  static async Logout() {
    try {
      const response = await BaseService.get("logout");
      return response;
    } catch (error) {
      console.error("Error in Logout:", error);
      return false;
    }
  }
}
