<template>
  <v-card class="mb-25 pingo-card">
    <div class="v-card-header border-bottom">
      <h5 class="mb-0">Configurações</h5>
    </div>

    <!-- Navigate -->
    <ul class="ps-0 mt-0">
      <!-- <li>
        <router-link to="/configuracoes/conta"> Conta </router-link>
      </li> -->
      <li v-if="user.type == 'BUSINESS'">
        <router-link to="/configuracoes/enderecos"> Endereços </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/politica-de-privacidade">
          Política de Privacidade
        </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/termos-e-condicoes">
          Termos e Condições
        </router-link>
      </li>
    </ul>

    <!-- Form -->
    <form>
      <div class="mb-20">
        <h5 class="fw-semibold mb-8">
          TERMO DE USO DO SISTEMA MÃOS QUE NUTREM
        </h5>
        <p>Última atualização: [Data]</p>
      </div>

      <h2 class="fw-semibold mb-10">Bem-vindo ao Sistema Mãos que Nutrem!</h2>

      <p>
        Este Termo de Uso estabelece as condições para a utilização do sistema
        desenvolvido pela MÃOS QUE NUTREM LTDA, CNPJ nº 55.263.890/0001-16, que
        oferece serviços integrados de gestão de pedidos de refeições e
        fornecimento de alimentos produzidos diretamente por nossa empresa.
      </p>

      <p>
        A aceitação deste Termo de Uso é indispensável para acessar e utilizar o
        sistema e os serviços associados.
      </p>

      <h3 class="fw-semibold mt-20 mb-10">1. OBJETO DO SISTEMA</h3>

      <p>
        O sistema desenvolvido pela Mãos Que Nutrem LTDA tem como principal
        objetivo otimizar a gestão e o fornecimento de refeições para empresas
        contratantes e seus funcionários. Por meio de uma plataforma integrada e
        eficiente, o sistema oferece funcionalidades que abrangem desde o pedido
        individual de refeições até o acompanhamento gerencial detalhado,
        proporcionando uma experiência completa para todos os usuários
        envolvidos.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        1.2 Para os funcionários das Empresas Contratantes, o sistema permite:
      </h4>

      <p>
        Acesso a cardápios planejados e atualizados regularmente, com opções
        balanceadas e informações claras sobre os ingredientes;
      </p>
      <p>
        Realização de pedidos de refeições, com controle sobre o histórico de
        consumo e possibilidade de ajustes dentro do prazo estipulado;
      </p>
      <p>
        Consulta a relatórios mensais que detalham a quantidade de pedidos
        realizados, oferecendo maior transparência e controle ao usuário.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        1.3 Para as Empresas Contratantes, o sistema disponibiliza:
      </h4>

      <p>
        Gerenciamento completo do cadastro e acesso dos funcionários, garantindo
        que somente usuários autorizados tenham acesso ao serviço;
      </p>
      <p>
        Relatórios consolidados e segmentados por funcionário, contendo dados
        como frequência de pedidos, tipos de refeições solicitadas e outros
        indicadores de consumo;
      </p>
      <p>
        Ferramentas para monitoramento financeiro e logístico, otimizando a
        gestão do benefício e facilitando o controle de custos.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        1.4 Os alimentos fornecidos por meio do sistema são produzidos
        diretamente pela Mãos Que Nutrem, seguindo padrões rigorosos de
        qualidade e segurança alimentar, garantindo a entrega de refeições
        frescas, balanceadas e compatíveis com as necessidades das Empresas
        Contratantes e seus funcionários.
      </h4>

      <h4 class="fw-semibold mt-20 mb-10">
        1.5 Este sistema também busca promover uma experiência personalizada e
        eficiente para os usuários, permitindo que as empresas ofereçam um
        benefício diferenciado, enquanto os funcionários têm a conveniência de
        solicitar refeições de maneira prática e rápida, diretamente por meio da
        plataforma.
      </h4>

      <h4 class="fw-semibold mt-20 mb-10">
        1.6 Todas as funcionalidades, condições de uso e obrigações de ambas as
        partes são reguladas por este Termo de Uso, complementadas pelo contrato
        firmado entre a Mãos que Nutrem e a Empresa Contratante.
      </h4>

      <h3 class="fw-semibold mt-20 mb-10">2. CADASTRO E RESPONSABILIDADES</h3>

      <h4 class="fw-semibold mt-20 mb-10">
        2.1 Cadastro e Acesso dos Funcionários:
      </h4>
      <p>
        O acesso dos funcionários ao sistema será realizado mediante cadastro
        prévio efetuado pela Empresa Contratante, que criará login e senha para
        cada usuário autorizado.
      </p>
      <p>
        Cada funcionário será responsável por manter suas credenciais seguras,
        sendo expressamente proibido compartilhá-las com terceiros.
      </p>
      <p>
        É responsabilidade do funcionário revisar e manter atualizados seus
        dados pessoais no sistema, informando imediatamente quaisquer
        inconsistências.
      </p>
      <p>
        O uso do sistema é estritamente pessoal e intransferível, sendo vedado
        realizar pedidos ou acessar informações em nome de terceiros.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        2.2 Cadastro e Acesso das Empresas Contratantes:
      </h4>
      <p>
        As Empresas Contratantes têm a responsabilidade de cadastrar
        corretamente os funcionários, fornecendo informações precisas e
        atualizadas.
      </p>
      <p>
        Cabe à Empresa Contratante manter os cadastros de seus funcionários em
        conformidade, incluindo a exclusão de acessos de funcionários desligados
        ou não autorizados.
      </p>
      <p>
        O sistema permite que a Empresa Contratante monitore o consumo de
        refeições, realize ajustes cadastrais e obtenha relatórios detalhados
        para fins de controle interno e planejamento estratégico.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        2.3 Rescisão Contratual e Exclusão de Dados:
      </h4>
      <p>
        Em caso de rescisão do contrato entre a Mãos que Nutrem e a Empresa
        Contratante, o acesso de todos os funcionários vinculados à empresa será
        automaticamente encerrado.
      </p>
      <p>
        Após a rescisão, todos os dados pessoais dos funcionários cadastrados
        serão excluídos de forma segura e definitiva, em conformidade com a Lei
        Geral de Proteção de Dados (LGPD).
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        2.4 Obrigações Gerais dos Usuários (Funcionários e Empresas
        Contratantes):
      </h4>
      <p>
        Utilizar o sistema exclusivamente para as finalidades estabelecidas
        neste Termo de Uso, de forma ética, responsável e em conformidade com a
        legislação aplicável, devendo o funcionário realizar os pedidos
        exclusivamente nos dias de trabalho, e respeitados os limites
        quantitativos apresentados no sistema.
      </p>
      <p>
        Informar à Mãos que Nutrem sobre qualquer problema técnico,
        irregularidade ou acesso não autorizado ao sistema.
      </p>
      <p>
        Respeitar os prazos e condições definidos para a realização e
        cancelamento de pedidos, conforme as diretrizes estabelecidas no
        contrato ou no sistema.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        2.5 Responsabilidade pelo Uso Indevido:
      </h4>
      <p>
        A Mãos que Nutrem não se responsabiliza por danos ou prejuízos
        decorrentes do uso inadequado ou não autorizado do sistema por
        funcionários ou empresas, incluindo, mas não se limitando a, pedidos
        realizados por terceiros devido ao compartilhamento de credenciais.
      </p>
      <p>
        Caso seja identificada qualquer tentativa de fraude, uso irregular ou
        violação deste Termo de Uso, o acesso ao sistema poderá ser suspenso ou
        encerrado, a critério exclusivo da Mãos que Nutrem, sem prejuízo de
        outras medidas legais cabíveis.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">2.6 Atualizações no Cadastro:</h4>
      <p>
        Qualquer alteração no vínculo empregatício dos funcionários, como
        desligamento ou transferência, deve ser comunicada pela Empresa
        Contratante de forma imediata, para que as informações no sistema sejam
        devidamente atualizadas.
      </p>
      <p>
        A omissão de informações ou a manutenção de dados desatualizados poderá
        comprometer a experiência do usuário no sistema e eximir a Mãos que
        Nutrem de responsabilidades associadas.
      </p>

      <h3 class="fw-semibold mt-20 mb-10">
        3. FUNCIONAMENTO DO SISTEMA E SOLICITAÇÃO DE REFEIÇÕES
      </h3>

      <h4 class="fw-semibold mt-20 mb-10">3.1 Disponibilidade do Sistema:</h4>
      <p>
        O sistema estará disponível 24 horas por dia, 7 dias por semana, salvo
        interrupções programadas para manutenção ou por eventos de força maior,
        sendo disponibilizado aos funcionários da Empresa Contratante dentro dos
        limites do contrato existente com a Mãos que Nutrem.
      </p>
      <p>
        A Mãos que Nutrem se compromete a notificar as Empresas Contratantes e
        funcionários, sempre que possível, sobre quaisquer indisponibilidades
        planejadas ou emergenciais.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        3.2 Cardápio e Disponibilidade de Refeições:
      </h4>
      <p>
        O cardápio disponibilizado no sistema é previamente estipulado pela Mãos
        que Nutrem, podendo ser atualizado periodicamente para incluir novas
        opções e atender a diferentes preferências alimentares.
      </p>
      <p>
        Informações detalhadas sobre cada refeição, como ingredientes e valores
        nutricionais, estarão disponíveis no sistema para consulta antes da
        solicitação.
      </p>
      <p>
        Todas as refeições são preparadas seguindo rigorosos padrões de
        qualidade e segurança alimentar, com ingredientes frescos e
        selecionados.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        3.3 Processo de Solicitação de Refeições:
      </h4>
      <p>
        Os funcionários poderão realizar seus pedidos diretamente pelo sistema,
        dentro dos horários especificados no sistema, selecionando as opções
        desejadas no cardápio e confirmando os detalhes antes da finalização,
        devendo observar os limites quantitativos expressos no sistema.
      </p>
      <p>
        Cada pedido estará sujeito às regras de prazo estabelecidas no contrato
        ou na política operacional da Mãos que Nutrem, que serão devidamente
        informadas no sistema.
      </p>
      <p>
        O sistema não permitirá alterações ou cancelamentos de pedidos fora dos
        prazos estipulados.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        3.4 Relatórios e Controle de Consumo:
      </h4>
      <p>
        Os funcionários terão acesso a relatórios mensais, que detalham a
        quantidade de refeições solicitadas e outros indicadores relevantes.
      </p>
      <p>
        As Empresas Contratantes poderão acessar relatórios gerenciais
        completos, com dados consolidados e individuais, permitindo o
        acompanhamento do consumo por funcionário e a análise de métricas
        operacionais.
      </p>
      <p>
        A transparência nos relatórios visa auxiliar tanto os funcionários
        quanto as empresas no monitoramento e controle dos serviços contratados.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">3.5 Entrega e Logística:</h4>
      <p>
        As refeições solicitadas serão entregues conforme as condições
        estabelecidas entre a Mãos que Nutrem e a Empresa Contratante, que podem
        variar de acordo com a localidade e os termos contratuais.
      </p>
      <p>
        A responsabilidade pela correta entrega das refeições será compartilhada
        entre a Mãos que Nutrem, que gerencia a logística, e a Empresa
        Contratante, que organiza a distribuição interna aos funcionários,
        conforme as condições acordadas.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        3.6 Limitações e Avisos Importantes:
      </h4>
      <p>
        A disponibilidade de refeições pode variar conforme a demanda e o
        estoque de ingredientes, sendo o usuário notificado pelo sistema caso
        alguma opção selecionada não esteja disponível.
      </p>
      <p>
        A Mãos que Nutrem reserva-se o direito de recusar ou ajustar pedidos em
        situações excepcionais, sempre informando os usuários e oferecendo
        alternativas compatíveis.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">3.7 Alterações nos Serviços:</h4>
      <p>
        A Mãos que Nutrem poderá realizar ajustes no cardápio, prazos de
        solicitação e outras funcionalidades do sistema para melhorar a
        experiência do usuário, comprometendo-se a notificar as Empresas
        Contratantes e funcionários com antecedência, sempre que possível.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">3.8 Finalidade Exclusiva:</h4>
      <p>
        O sistema é destinado exclusivamente à solicitação de refeições e ao
        acompanhamento de relatórios relacionados. Qualquer tentativa de
        utilização indevida será considerada uma violação deste Termo de Uso,
        podendo resultar na suspensão ou exclusão do acesso ao sistema, além de
        outras medidas cabíveis.
      </p>

      <h3 class="fw-semibold mt-20 mb-10">
        4. OBRIGAÇÕES E RESPONSABILIDADES DA MÃOS QUE NUTREM
      </h3>

      <h4 class="fw-semibold mt-20 mb-10">4.1 Fornecimento de Refeições:</h4>
      <p>
        A Mãos que Nutrem compromete-se a produzir e fornecer as refeições
        solicitadas pelos funcionários, assegurando o cumprimento de rigorosos
        padrões de qualidade e segurança alimentar, em conformidade com a
        legislação vigente.
      </p>
      <p>
        As refeições serão preparadas com ingredientes frescos, e o cardápio
        será elaborado por profissionais qualificados para atender às
        necessidades nutricionais dos usuários.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">4.2 Operação do Sistema:</h4>
      <p>
        A Mãos que Nutrem é responsável por garantir o bom funcionamento do
        sistema, adotando medidas técnicas e operacionais para evitar
        interrupções, falhas e vulnerabilidades de segurança.
      </p>
      <p>
        A empresa compromete-se a realizar manutenções preventivas e corretivas,
        comunicando as Empresas Contratantes e os funcionários com antecedência,
        sempre que possível.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        4.3 Privacidade e Segurança de Dados:
      </h4>
      <p>
        A Mãos que Nutrem assegura a proteção dos dados pessoais de todos os
        usuários do sistema, cumprindo integralmente a Lei Geral de Proteção de
        Dados (LGPD).
      </p>
      <p>
        Os dados coletados serão utilizados exclusivamente para as finalidades
        previstas neste Termo de Uso e no contrato firmado com a Empresa
        Contratante.
      </p>
      <p>
        Maiores informações quanto ao tratamento de dados podem ser obtidas
        através de nossa política de privacidade, clicando no seguinte link
      </p>

      <h4 class="fw-semibold mt-20 mb-10">4.4 Suporte Técnico:</h4>
      <p>
        A empresa disponibilizará suporte técnico para resolver dúvidas e
        problemas relacionados ao uso do sistema, com canais de atendimento
        acessíveis e prazos razoáveis para resposta.
      </p>
      <p>
        O suporte estará disponível durante os horários informados no sistema, e
        os contatos serão amplamente divulgados.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">
        4.5 Limitações de Responsabilidade:
      </h4>
      <p>
        A Mãos que Nutrem não se responsabiliza por problemas decorrentes de:
      </p>
      <ul>
        <li>Uso indevido do sistema pelos usuários;</li>
        <li>
          Dados incorretos ou desatualizados fornecidos pela Empresa Contratante
          ou pelos funcionários;
        </li>
        <li>
          Interrupções causadas por eventos de força maior, como desastres
          naturais ou falhas em serviços de terceiros.
        </li>
      </ul>

      <h3 class="fw-semibold mt-20 mb-10">5. PROPRIEDADE INTELECTUAL</h3>

      <h4 class="fw-semibold mt-20 mb-10">5.1 Direitos sobre o Sistema:</h4>
      <p>
        Todos os direitos sobre o sistema, incluindo software, layout, design,
        funcionalidades, códigos, textos, imagens, e demais conteúdos, são de
        propriedade exclusiva da Mãos que Nutrem.
      </p>
      <p>
        É vedada a reprodução, modificação, distribuição ou qualquer outra forma
        de utilização do sistema ou de seus elementos sem autorização prévia e
        expressa da empresa.
      </p>

      <h3 class="fw-semibold mt-20 mb-10">8. DISPOSIÇÕES FINAIS</h3>

      <h4 class="fw-semibold mt-20 mb-10">
        8.1 Modificações nos Termos de Uso:
      </h4>
      <p>
        A Mãos que Nutrem reserva-se o direito de alterar este Termo de Uso a
        qualquer momento, sendo que as modificações entrarão em vigor
        imediatamente após sua publicação no sistema.
      </p>
      <p>
        As Empresas Contratantes e funcionários serão informados sobre as
        alterações, e o uso contínuo do sistema implica na aceitação das novas
        condições.
      </p>
      <p>
        Inexiste entre a Mãos que Nutrem e os funcionários da Empresa
        Contratante qualquer vínculo empregatício.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">8.2 Do Foro:</h4>
      <p>
        Fica eleito o foro da comarca de Maringá-PR, com exclusão de qualquer
        outro, por mais privilegiado que seja, para dirimir quaisquer questões
        relativas ao presente Termo.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">8.3 Validade do Contrato:</h4>
      <p>
        Caso qualquer cláusula deste Termo de Uso seja considerada inválida ou
        inexequível por decisão judicial, as demais disposições permanecerão em
        pleno vigor e efeito.
      </p>

      <h4 class="fw-semibold mt-20 mb-10">8.4 Comunicação:</h4>
      <p>
        Qualquer dúvida, solicitação ou questão referente a este Termo de Uso
        deverá ser encaminhada ao suporte técnico da Mãos que Nutrem, disponível
        pelos canais de atendimento informados no sistema.
      </p>

      <div class="btn-box mt-20">
        <button class="bg-red">Fechar & excluir esta conta</button>
        <button class="bg-gray ms-15">Cancelar</button>
      </div>
    </form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  name: "SettingsTermsConditionsPage",
  setup() {
    const authStore = useAuthStore();

    const user: Ref = ref(authStore.getUser);

    return {
      user,
    };
  },
});
</script>

<style lang="scss" scoped>
button,
a {
  padding: 14px 35px !important;
  border-radius: 5px !important;

  &.gray {
    color: var(--blackColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}

ul {
  list-style-type: none;
  margin-bottom: 15px;

  li {
    display: inline-block;
    margin: {
      right: 15px;
      bottom: 15px;
    }
    a {
      color: var(--paragraphColor);
      background-color: #f4f4f4;
      display: inline-block;
      border-radius: 5px;
      padding: 10px 25px !important;
      font: {
        size: var(--fontSize);
        weight: 600;
      }
      &:hover,
      &.active {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  ul {
    li {
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
