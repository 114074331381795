import BaseService from "@/shared/base.service";

import { useAlertStore } from "@/stores/alert.store";

import type { PaginationResponse } from "@/types/response/pagination";

import type { OrderResponse } from "@/types/response/order";
import type { OrderDetailsResponse } from "@/types/response/orderDetails";
import type { CreateOrderRequest } from "@/types/request/create/createOrder";
import type { CreateOrderBatchRequest } from "@/types/request/create/createOrderBatch";
import type { UpdateOrderRequest } from "@/types/request/update/updateOrder";

export class OrderService {
  static async GetAllOrders(
    currentPage: number,
    totalPerPage: number
  ): Promise<PaginationResponse<OrderResponse> | null> {
    try {
      const params = {
        currentPage,
        totalPerPage,
      };

      const queryParams = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(params).filter(([_, v]) => v != null)
      );

      const response = await BaseService.get<PaginationResponse<OrderResponse>>(
        "order",
        queryParams
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetAllOrders:", error);
      return null;
    }
  }

  static async GetOrderById(id: number): Promise<OrderDetailsResponse | null> {
    try {
      const response = await BaseService.get<OrderDetailsResponse>(
        `order/${id}`
      );

      return response ?? null;
    } catch (error) {
      console.error("Error in GetOrderById:", error);
      return null;
    }
  }

  static async CreateOrder(
    payload: CreateOrderRequest
  ): Promise<object | null> {
    try {
      const response = await BaseService.post<object>("order", {
        ...payload,
      });

      if (response) {
        useAlertStore().show("success", "Pedido criado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in CreateOrder:", error);
      return null;
    }
  }

  static async CreateOrderBatch(
    payload: CreateOrderBatchRequest[]
  ): Promise<object | null> {
    try {
      const response = await BaseService.post("order/batch", payload);

      if (response) {
        useAlertStore().show("success", "Pedido em massa criado com sucesso.");
      }

      return response ?? null;
    } catch (error) {
      console.error("Error in CreateOrderBatch:", error);
      return null;
    }
  }

  static async UpdateOrder(
    id: number,
    payload: UpdateOrderRequest
  ): Promise<boolean> {
    try {
      const response = await BaseService.patch<object>(`order/${id}`, payload);

      if (response) {
        useAlertStore().show("success", "Pedido atualizado com sucesso.");
        return true;
      }

      return false;
    } catch (error) {
      console.error("Error in UpdateOrder:", error);
      return false;
    }
  }

  static async DeleteOrderById(id: number): Promise<boolean | null> {
    try {
      const response = await BaseService.delete<boolean>(`order/${id}`);

      if (response) {
        useAlertStore().show("warning", "Pedido removido com sucesso.");
      }

      return response ?? false;
    } catch (error) {
      console.error("Error in DeleteOrderById:", error);
      return false;
    }
  }
}
