<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex mb-10 align-items-center">
              <h2 class="mb-0 me-30">Esqueci minha senha</h2>
              <v-img src="@/assets/img/logo.svg" alt="logo" width="100" />
            </div>
            <p>
              Insira seu email e nós enviaremos as instruções para você
              recuperar sua senha
            </p>
          </div>

          <form @submit.prevent="handleSubmit()">
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nova senha
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a sua nova senha'"
                  :type="'password'"
                  v-model="passwordResetForm.password"
                  :error-messages="
                    v$.password.$errors
                      ? v$.password.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>

              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Confirme a sua nova senha
                  <span>*</span>
                </label>

                <TextInput
                  :label="'Digite a sua nova senha novamente'"
                  :type="'password'"
                  v-model="passwordResetForm.confirmationPassword"
                  :error-messages="
                    v$.confirmationPassword.$errors
                      ? v$.confirmationPassword.$errors[0]?.$message
                      : undefined
                  "
                />
              </div>
            </div>

            <PrimaryButton :is-loading="isLoading" label="Resetar senha" />

            <div class="text-center back-to-login">
              <router-link
                to="/autenticar/acessar"
                class="d-inline-block main-color fw-medium position-relative"
              >
                <i class="flaticon-chevron-1"></i>
                Voltar para login
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuth } from "@/composables/useAuth";

import PrimaryButton from "@/components/Forms/BasicElements/Button/PrimaryButton.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";

export default defineComponent({
  name: "ResetPasswordPage",
  components: {
    TextInput,
    PrimaryButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      confirmTokenFormRules,
      confirmTokenForm,
      passwordResetFormRules,
      passwordResetForm,
      resetPassword,
      confirmToken,
      isLoading,
    } = useAuth();

    const handleSubmit = async () => {
      try {
        const result = await v$.value.$validate();

        if (!result) return;

        const response = await resetPassword(passwordResetForm);

        if (response) {
          router.push({ name: "SignInPage" }).then(() => {
            router.go(0);
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const verifiyRecoveryPasswordToken = async () => {
      const result = await v$confirmPassword.value.$validate();

      if (!result) return;

      confirmTokenForm.token = route.params.token.toString();
      confirmTokenForm.email = route.params.email.toString();

      const response = await confirmToken(confirmTokenForm);
      if (!response) router.push({ name: "SignInPage" });
    };

    const v$ = useVuelidate(passwordResetFormRules, passwordResetForm);
    const v$confirmPassword = useVuelidate(
      confirmTokenFormRules,
      confirmTokenForm
    );

    onMounted(() => {
      const token = route.params.token;
      if (!token) router.push({ name: "SignInPage" });

      verifiyRecoveryPasswordToken();
    });

    return {
      passwordResetForm,
      isLoading,
      handleSubmit,
      v$,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-selection-control {
  .v-label {
    white-space: nowrap !important;
  }
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }

  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}

.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;

      a {
        font-size: 18px !important;
      }
    }
  }

  .login-with-socials {
    margin-bottom: 25px;

    button {
      margin-top: 0;
      font-size: 15px !important;
      padding: 17px 25px 17px 60px !important;
      position: relative;
      border-radius: 5px !important;
      width: 100%;

      .v-img {
        top: 52%;
        position: absolute;
        transform: translateY(-52%);

        margin: {
          left: -25px;
        }
      }

      &.gray {
        border: 1px solid #edeff5 !important;
        background-color: var(--whiteColor) !important;
      }

      &.success {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
  }

  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;

      padding: {
        left: 15px;
        right: 15px;
      }
    }

    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: "";
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }

  button {
    margin-top: 15px;
    padding: 17px 35px !important;
    width: 100%;

    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }

  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }

  .back-to-login {
    margin-top: 25px;

    a {
      padding-left: 20px;

      i {
        top: 50%;
        left: -5px;
        line-height: 1;
        font-size: 18px;
        margin-top: 2px;
        position: absolute;
        transform: translateY(-50%);
      }
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;

    padding: {
      left: 15px;
      right: 15px;
    }

    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
