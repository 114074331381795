<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex mb-10 align-items-center">
              <h2 class="mb-0 me-30">Esqueci minha senha</h2>
              <v-img src="@/assets/img/logo.svg" alt="logo" width="100" />
            </div>
            <p>
              Insira seu email e nós enviaremos as instruções para você
              recuperar sua senha
            </p>
          </div>

          <form @submit.prevent="handleSubmit()">
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Email <span>*</span>
                </label>

                <TextInput
                  :label="'Digite o seu email'"
                  :type="'email'"
                  v-model="confirmTokenForm.email"
                  :error-messages="
                    v$.email.$errors ? v$.email.$errors[0]?.$message : undefined
                  "
                />
              </div>

              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Código de verificação <span>*</span>
                </label>

                <v-otp-input
                  :loading="isLoading"
                  length="5"
                  v-model="confirmTokenForm.token"
                ></v-otp-input>
              </div>
            </div>

            <PrimaryButton :is-loading="isLoading" label="Validar código" />

            <div class="text-center back-to-login">
              <router-link
                to="/autenticar/acessar"
                class="d-inline-block main-color fw-medium position-relative"
              >
                <i class="flaticon-chevron-1"></i>
                Voltar para login
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuth } from "@/composables/useAuth";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";

import PrimaryButton from "@/components/Forms/BasicElements/Button/PrimaryButton.vue";

export default defineComponent({
  name: "PasswordCodePage",
  components: {
    TextInput,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();

    const { confirmTokenFormRules, confirmTokenForm, confirmToken, isLoading } =
      useAuth();

    const handleSubmit = async () => {
      try {
        const result = await v$.value.$validate();

        if (!result) return;

        const response = await confirmToken(confirmTokenForm);

        if (response) {
          router.push({ name: "SignInPage" });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const v$ = useVuelidate(confirmTokenFormRules, confirmTokenForm);

    return {
      isLoading,
      confirmTokenForm,
      handleSubmit,
      v$,
    };
  },
});
</script>

<style lang="scss">
.v-selection-control {
  .v-label {
    white-space: nowrap !important;
  }
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }

  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}

.v-otp-input .v-field {
  border: 1px solid #ccc !important;
}

.v-field__field {
  height: 100%;
}

.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;

      a {
        font-size: 18px !important;
      }
    }
  }

  .login-with-socials {
    margin-bottom: 25px;

    button {
      margin-top: 0;
      font-size: 15px !important;
      padding: 17px 25px 17px 60px !important;
      position: relative;
      border-radius: 5px !important;
      width: 100%;

      .v-img {
        top: 52%;
        position: absolute;
        transform: translateY(-52%);

        margin: {
          left: -25px;
        }
      }

      &.gray {
        border: 1px solid #edeff5 !important;
        background-color: var(--whiteColor) !important;
      }

      &.success {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
  }

  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;

      padding: {
        left: 15px;
        right: 15px;
      }
    }

    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: "";
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }

  button {
    margin-top: 15px;
    padding: 17px 35px !important;
    width: 100%;

    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }

  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }

  .back-to-login {
    margin-top: 25px;

    a {
      padding-left: 20px;

      i {
        top: 50%;
        left: -5px;
        line-height: 1;
        font-size: 18px;
        margin-top: 2px;
        position: absolute;
        transform: translateY(-50%);
      }
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;

    padding: {
      left: 15px;
      right: 15px;
    }

    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
