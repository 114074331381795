<template>
  <v-text-field
    :label="label"
    type="text"
    v-maskito="postalCodeMask"
    @update:model-value="(value) => $emit('update:value', value)"
    :hide-details="!required"
  >
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { postalCodeMask } from "@/shared/mask/mask";

export default defineComponent({
  name: "PostalCodeInput",
  props: {
    label: {
      type: String,
      default: "Escreva",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  setup() {
    return {
      postalCodeMask,
    };
  },
});
</script>
