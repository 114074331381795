<template>
  <PageTitle pageTitle="Lista de Pedidos" mainTitle="Pedidos" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Pedidos</h5>

      <div
        v-if="user.type == 'BUSINESS' || user.type == 'ATTENDANT'"
        class="d-flex ga-2"
      >
        <router-link
          class="default-btn pingo small"
          :to="{ name: 'CreateOrderPage' }"
        >
          <i class="ri-add-fill"></i>
          Criar novo pedido
        </router-link>

        <router-link
          v-if="user.type == 'ADMIN' || user.type == 'BUSINESS'"
          class="default-btn pingo small"
          :to="{ name: 'CreateOrderBatchPage' }"
        >
          <i class="ri-add-fill"></i>
          Criar pedidos em massa
        </router-link>
      </div>
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="orders"
          :loading="isLoadingOrders"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.orderId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.totalAmount`]="{ value }">
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span
              v-if="item.status === 'cancelled'"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Cancelado
            </span>
            <span
              v-else-if="item.status === 'pending'"
              class="text-capitalize badge rounded-pill text-soft-warning"
            >
              Pendente
            </span>
            <span
              v-else-if="item.status === 'confirmed'"
              class="text-capitalize badge rounded-pill text-soft-info"
            >
              Confirmado
            </span>
            <span
              v-else-if="item.status === 'shipped'"
              class="text-capitalize badge rounded-pill text-soft-primary"
            >
              Enviado
            </span>
            <span
              v-else-if="item.status === 'delivered'"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Entregue
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <router-link :to="`/pedidos/${item.orderId}`">
                <v-btn
                  class="text-capitalize"
                  color="blue-darken-3"
                  variant="tonal"
                  type="button"
                >
                  Ver detalhes
                </v-btn>
              </router-link>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum produto </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue";

import EventBus from "@/utils/eventBus";

import useVuelidate from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";

import { useOrder } from "@/composables/useOrder";

import PageTitle from "@/components/Common/PageTitle.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";

export default defineComponent({
  name: "OrdersPage",
  components: {
    PageTitle,
    ItemsPerPage,
  },
  setup() {
    const authStore = useAuthStore();

    const {
      columns,
      pagination,
      orders,
      getAllOrders,
      updateOrderForm,
      updateOrderFormRules,
      updateOrder,
    } = useOrder();

    const user: Ref = ref(authStore.getUser);

    const isLoadingOrders = ref(false);
    const isLoadingOrderRequest = ref(false);

    const isUpdateOrderModalOpen = ref(false);

    const fetchOrdersData = async () => {
      try {
        isLoadingOrders.value = true;

        getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage
        ).then(() => {
          isLoadingOrders.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      await fetchOrdersData();
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();

      if (!result) return;

      isLoadingOrderRequest.value = !isLoadingOrderRequest.value;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { orderId, ...updateOrderFormPayload } = updateOrderForm;

      if (!orderId) throw new Error("OrderId inválido.");

      const response = await updateOrder(orderId, updateOrderFormPayload);

      isLoadingOrderRequest.value = !isLoadingOrderRequest.value;

      if (response)
        isUpdateOrderModalOpen.value = !isUpdateOrderModalOpen.value;
    };

    onMounted(async () => {
      await fetchOrdersData();

      // Listen for the order-update event
      EventBus.on("order-update", async () => {
        await fetchOrdersData();
      });
    });

    const v$ = useVuelidate(updateOrderFormRules, updateOrderForm);

    return {
      v$,
      columns,
      pagination,
      isLoadingOrders,
      user,
      orders,
      updateOrderForm,
      isUpdateOrderModalOpen,
      handleSubmit,
      handleUpdatePageProps,
    };
  },
});
</script>
