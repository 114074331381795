import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ps-0 mt-0" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex align-items-center justify-space-between" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "column mt-20" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex flex-column justify-space-center align-items-center text-center w-100 py-15"
}
const _hoisted_8 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_9 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_10 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_11 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_12 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_13 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_14 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_15 = { class: "pingo-form-group without-icon mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_PostalCodeInput = _resolveComponent("PostalCodeInput")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-25 pingo-card" }, {
    default: _withCtx(() => [
      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
        _createElementVNode("h5", { class: "mb-0" }, "Configurações")
      ], -1)),
      _createElementVNode("ul", _hoisted_1, [
        (_ctx.user.type == 'BUSINESS')
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_router_link, { to: "/configuracoes/enderecos" }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Endereços ")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/politica-de-privacidade" }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Política de Privacidade ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/configuracoes/termos-e-condicoes" }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Termos e Condições ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _cache[11] || (_cache[11] = _createElementVNode("h5", { class: "fw-semibold mb-8" }, "Endereços", -1)),
            _createElementVNode("p", null, [
              (_ctx.user.type == 'ADMIN')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Gerencie os endereços da empresa aqui. "))
                : _createCommentVNode("", true),
              (_ctx.user.type == 'BUSINESS')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Visualize o endereço da sua empresa aqui. "))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.user.type == 'ADMIN')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/configuracoes/endereco/novo"
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("button", {
                    type: "button",
                    class: "default-btn px-8"
                  }, "Novo endereço", -1)
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.isLoadingAdresses)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_v_progress_circular, {
                  class: "mb-8",
                  color: "primary",
                  indeterminate: ""
                }),
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "gray-color fw-medium d-inline-block" }, "Carregando...", -1))
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isLoadingAdresses)
            ? (_openBlock(), _createBlock(_component_v_form, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        lg: "12",
                        md: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_8, [
                                    _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" CEP "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_PostalCodeInput, {
                                      label: 'Digite o seu CEP aqui',
                                      modelValue: _ctx.newAddressForm.zipCode,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAddressForm.zipCode) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_9, [
                                    _cache[15] || (_cache[15] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" Rua "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_TextInput, {
                                      label: 'Nome da rua',
                                      modelValue: _ctx.newAddressForm.street,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newAddressForm.street) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_10, [
                                    _cache[16] || (_cache[16] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" Bairro "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_TextInput, {
                                      label: 'Digite o bairro aqui',
                                      modelValue: _ctx.newAddressForm.neighborhood,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAddressForm.neighborhood) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _cache[17] || (_cache[17] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" Cidade "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_TextInput, {
                                      label: 'Digite a cidade aqui',
                                      modelValue: _ctx.newAddressForm.city,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAddressForm.city) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_12, [
                                    _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" Estado "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_TextInput, {
                                      label: 'Digite o estado aqui',
                                      modelValue: _ctx.newAddressForm.state,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newAddressForm.state) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_13, [
                                    _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" Número "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_TextInput, {
                                      label: 'Digite o número aqui',
                                      modelValue: _ctx.newAddressForm.number,
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAddressForm.number) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_14, [
                                    _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Complemento ", -1)),
                                    _createVNode(_component_TextInput, {
                                      label: 'Digite o complemento aqui (opcional)',
                                      modelValue: _ctx.newAddressForm.complement,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newAddressForm.complement) = $event)),
                                      disabled: "",
                                      required: false
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                lg: "6",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_15, [
                                    _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                                      _createTextVNode(" Endereço Principal? "),
                                      _createElementVNode("span", null, "*")
                                    ], -1)),
                                    _createVNode(_component_v_select, {
                                      modelValue: _ctx.newAddressForm.isDefault,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newAddressForm.isDefault) = $event)),
                                      required: false,
                                      disabled: "",
                                      items: [
                        { title: 'Sim', value: true },
                        { title: 'Não', value: false },
                      ]
                                    }, null, 8, ["modelValue"])
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}