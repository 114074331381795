<template>
  <v-dialog v-model="isDialogOpen" width="auto" scrollable>
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        variant="flat"
        icon="mdi-delete-outline"
        v-bind="activatorProps"
      ></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card class="py-0" title="Remover endereço">
        <v-divider></v-divider>

        <v-card-text class="px-4">
          Tem certeza que deseja remover esse endereço?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="text-capitalize" @click="isActive.value = false">
            Fechar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            class="text-capitalize"
            color="red-darken-3"
            variant="tonal"
            @click="handleRemoveAddress(props.addressId)"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useLoadingDialog } from "@/stores/loading.store";

import { useAddress } from "@/composables/useAddress";

export default defineComponent({
  name: "DeleteAddressModal",
  props: {
    addressId: {
      type: Number,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, { emit }) {
    const loadingStore = useLoadingDialog();

    const { deleteAddressById } = useAddress();

    const isDialogOpen = ref(props.isOpen);

    const handleRemoveAddress = async (addressId: number) => {
      loadingStore.setLoading("Removendo endereço...");

      const response = await deleteAddressById(addressId);
      if (response) {
        isDialogOpen.value = false;

        emit("update:isOpen", false);
      }

      loadingStore.setLoadingOff();
    };

    watch(
      () => props.isOpen,
      (newValue) => {
        isDialogOpen.value = newValue;
      }
    );

    watch(isDialogOpen, (newValue) => {
      emit("update:isOpen", newValue);
    });

    return {
      props,
      isDialogOpen,
      handleRemoveAddress,
    };
  },
});
</script>

<style scoped>
.v-window {
  overflow-y: auto;
}
</style>
