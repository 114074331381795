<template>
  <v-card class="mb-25 pingo-card">
    <div class="v-card-header border-bottom">
      <h5 class="mb-0">Configurações</h5>
    </div>

    <!-- Navigate -->
    <ul class="ps-0 mt-0">
      <!-- <li>
        <router-link to="/configuracoes/conta"> Conta </router-link>
      </li> -->
      <li>
        <router-link to="/configuracoes/enderecos"> Endereços </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/politica-de-privacidade">
          Política de Privacidade
        </router-link>
      </li>
      <li>
        <router-link to="/configuracoes/termos-e-condicoes">
          Termos e Condições
        </router-link>
      </li>
    </ul>

    <!-- Form -->
    <form>
      <div class="mb-20">
        <h5 class="fw-semibold mb-8">Conta</h5>
        <p>Atualize sua foto e dados pessoais aqui.</p>
      </div>

      <div class="row mt-20">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">
              First Name
            </label>
            <v-text-field value="Andrew">
              <i class="ri-user-3-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">
              Last Name
            </label>
            <v-text-field value="Bruns">
              <i class="ri-user-3-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">
              Email Address
            </label>
            <v-text-field type="email" value="hello@pingo.com">
              <i class="ri-mail-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
            <v-text-field type="phone" value="+(555) 555-1234">
              <i class="ri-phone-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Live in</label>
            <v-text-field type="text" value="Zuichi, Switzerland">
              <i class="ri-home-7-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">
              Street Address
            </label>
            <v-text-field type="text" value="2445 Crosswind Drive">
              <i class="ri-home-7-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">
              Date Of Birth
            </label>
            <v-text-field value="20 March 1999">
              <i class="ri-cake-2-line"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Gender</label>
            <v-text-field value="Male">
              <i class="ri-men-line"></i>
            </v-text-field>
          </div>
        </div>
      </div>

      <h5 class="fw-semibold mb-8 mt-5">Your Photo</h5>
      <p>This will be displayed on your profile.</p>

      <div class="d-flex align-items-center">
        <v-img
          src="../../assets/img/admin.jpg"
          class="rounded-circle"
          width="110"
          alt="user"
        />
        <div class="pingo-form-group ms-20">
          <label class="d-block mb-12 fw-semibold gray-color">
            Upload Image
          </label>
          <input type="file" />
        </div>
      </div>

      <h5 class="fw-semibold mb-20 mt-25">Socials Profile</h5>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Facebook</label>
            <v-text-field value="https://www.facebook.com/">
              <i class="ri-facebook-fill"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Twitter</label>
            <v-text-field value="https://twitter.com/">
              <i class="ri-twitter-fill"></i>
            </v-text-field>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="pingo-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Linkedin</label>
            <v-text-field value="https://linkedin.com/">
              <i class="ri-linkedin-fill"></i>
            </v-text-field>
          </div>
        </div>
      </div>

      <button class="success fw-semibold">Update</button>
    </form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsAccountPage",
  components: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.v-input__details {
  display: none !important;
}
button {
  border-radius: 5px !important;
  padding: 17px 35px !important;
  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}
ul {
  list-style-type: none;
  margin-bottom: 15px;

  li {
    display: inline-block;
    margin: {
      right: 15px;
      bottom: 15px;
    }
    a {
      color: var(--paragraphColor);
      background-color: #f4f4f4;
      display: inline-block;
      border-radius: 5px;
      padding: 10px 25px !important;
      font: {
        size: var(--fontSize);
        weight: 600;
      }
      &:hover,
      &.active {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  ul {
    li {
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
