import { ref } from "vue";

import { ViaCepService } from "@/services";

import { isLoading } from "@/shared/base.service";

import { ViaCepAddressResponse } from "@/types/response/viaCepAddress";

export function useViaCEP() {
  const address = ref<ViaCepAddressResponse>();

  async function getAddress(postal_code: string) {
    try {
      const response = await ViaCepService.GetAddress(postal_code);

      if (response) address.value = response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    address,
    getAddress,
  };
}
