<template>
  <PageTitle pageTitle="Dashboard" mainTitle="Dashboard" />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Dashboard</h5>

      <p>Atualizado por último em {{ new Date().toLocaleString() }}</p>
    </div>

    Detalhes
  </v-card>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue";

import { useAuthStore } from "@/stores/auth.store";

import PageTitle from "@/components/Common/PageTitle.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    PageTitle,
  },
  setup() {
    const authStore = useAuthStore();

    const user: Ref = ref(authStore.getUser);

    return {
      user,
    };
  },
});
</script>
